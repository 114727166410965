import * as React from "react";

import { ProgressBar } from '../../../../aws-ui-components';
import SimpleTable from '../../../../common-components/SimpleTable.jsx';

import { LEADERSHIPPRINCIPLES } from '../../../../helpers/constants'

import LargeTextWrapper from '../../../../common-components/LargeTextWrapper.jsx';

const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'description', label: 'Description', editable: true },
        { id: 'leadershipPrinciples',label: 'LPs', editable: true},
        { id: 'amount', label: 'Amount', editable: true},
        { id: 'endDate', label: 'End Date', editable: true},
        { id: 'group', label: 'Group', editable: true},
        { id: 'goalType', label: 'Goal Type', editable: true},
        { id: 'progress', label: 'progress', editable: true },
        { id: 'mandatory', label: 'Mandatory', editable: true },
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 goals' },
    { value: 30, label: '30 goals' },
    { value: 50, label: '50 goals' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'leadershipPrinciples', 'amount', 'endDate', 'goalType', 'group', 'mandatory', 'progress'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'description',
        header: 'Description',
        cell: item => <LargeTextWrapper full_text={item.description} length={200}/>,
        minWidth: '200px',
        sortingField: 'description'
    },
    {
        id: 'leadershipPrinciples',
        header: 'LPs',
        cell: item => item.leadershipPrinciples?.map(lp => (<p key={lp}>{LEADERSHIPPRINCIPLES[lp]}</p>)),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'leadershipPrinciples'
    },
    {
        id: 'amount',
        header: 'Amount',
        cell: item => item.amount,
        minWidth: '50px',
        sortingField: 'amount'
    },
    {
        id: 'endDate',
        header: 'End Date',
        cell: item => new Date(item.endDate).toLocaleDateString('en-UK'),
        minWidth: '100px',
        sortingField: 'endDate'
    },    
    {
        id: 'goalType',
        header: 'Type',
        cell: item => item.goalType,
        minWidth: '100px',
        sortingField: 'goalType'
    },
    {
        id: 'group',
        header: 'Group',
        cell: item => item.group?.name,
        minWidth: '100px',
        sortingField: 'group'
    },
    {
        id: 'mandatory',
        header: 'Mandatory',
        cell: item => String(item.mandatory),
        minWidth: '20px',
        sortingField: 'mandatory'
    },
    {
        id: 'progress',
        header: 'Progress',
        cell: item => progress_content(item),
        minWidth: '50px',
        sortingField: 'progress'
    }
];

const progress_content = (item) => {
    const status = item.amount > item.progress ? 'in-progress' : 'success'
    return (
        <ProgressBar
            status={status}
            value={item.progress / item.raw_amount * 100}
            additionalInfo={`Completed ${item.progress} out of ${item.raw_amount} (goal amount)`}
        />
    )   
}

class ViewGroupGoalTable extends React.Component {
    constructor(props) {
        super(props);
        const goalsList = this.props.group_goals || []
        const activeGoals = goalsList.filter(item => item.deactivated !== true)
        this.state = {
            group: this.props.group || null,
            goals: activeGoals,
        };
        // this.initial_state = this.state
    }

    actions = (selectedDistributions) => {
        return (
            <div></div>
        );
    }

    render() {
        const table_params = {
            dataItems: this.state.goals,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerActions: this.actions,
            headerTitle: "Group goals",
            enableSearch: true,
            filterLabel: "Find goal",
            filterPlaceholder: "Find goal",
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            loading: this.props.loading,
            empty: "Group has no goals yet"
        }
        return (
            <SimpleTable key={this.state.updatedKey} params={table_params}/> // Adding key is a hacky solution to re-render the table when a goal has been edited (this caused problems with selectedDistributions)
        );
    }
}

export default ViewGroupGoalTable