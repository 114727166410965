import { API, graphqlOperation, Auth } from "aws-amplify";

import { listSubGroupsByGroupId } from '../graphql/customQueries';

import * as queries from '../graphql/queries'

import { listLoIByEmployeeId, listAchievementsByEmployeeIdReduced } from '../graphql/customQueries'

export const getUserDBObject = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).catch(err => console.log(err));
    if (cognitoUser) {
        const username = cognitoUser.attributes.email.split('@')[0];
        const response = await API.graphql(graphqlOperation(
            queries.getEmployee, {
            id: username
        }
        ));
        const db_user = response.data.getEmployee
        return db_user
    }
    return
}

export const getInheritedGoalsForCurrentUser = async (mandatory_only = false) => {
    const user = await getUserDBObject()
    if (!user) { return }
    const groupMemberships = user.groupMemberships.items
    var groupObjects = []

    // For each group membership (i.e. you're added to the group, get the group Object)
    for (const memberShip of groupMemberships) {
        const group = await API.graphql(graphqlOperation(
            queries.getGroup, {
            id: memberShip.groupId
        }
        ));
        groupObjects.push(group.data.getGroup)
    }

    // For these group objects, go recursively up the chain to find the parent groups
    var groups = [] // Empty the groups variable
    await recursiveGetGroupsInTree(groupObjects, groups)

    // Now that I have the groupId that I need to check, get all the goals for these groups.
    // TODO: Optimise this, so that I don't do N queries (where N is the number of groups)
    var goals = []
    for (const group of groups) {
        if (group.goals.items) {
            var goals_to_add = group.goals.items
            if (mandatory_only) {
                goals_to_add = goals_to_add.filter(goal => goal.mandatory === true)
            }
            // remove deactivated goals
            goals_to_add = goals_to_add.filter(goal => goal.deactivated !== true)
            goals = goals.concat(goals_to_add)
        }
    }
    return goals
}

export const getRecursiveGroupsSubGroups = async (group) => {
    var subgroups = []
    const firstSubgroups = group.subGroups.items
    if (firstSubgroups.length > 0) {
        await recursiveSubgroupsInTree(firstSubgroups, subgroups)
    }
    return subgroups
}

const recursiveSubgroupsInTree = async (groups_to_check, subgroups) => {
    for (const group of groups_to_check) {
        const groupDataAPI = await API.graphql(graphqlOperation(queries.getGroup, { id: group.groupId }))
        const groupData = groupDataAPI.data.getGroup
        subgroups.push(groupData)
        // Get all the subgroup of this item
        const newSubgroups = groupData.subGroups.items
        if (newSubgroups.length > 0) {
            await recursiveSubgroupsInTree(newSubgroups, subgroups)
        }
    }
}

export const getRecursiveGroups = async (groups_to_check) => {
    // Go up the recursion tree and find parent goals
    var groups = []
    await recursiveGetGroupsInTree(groups_to_check, groups)
    return groups
}

// TODO: REFACTOR THIS UGLY SHIT
export const recursiveGetGroupsInTree = async (groups_to_check, groups_array) => {
    for (const group of groups_to_check) {
        if (groups_array.includes(group.id)) { return }
        groups_array.push(group)
        // Get all the subgroup items that this group is part of
        const subGroups = await API.graphql(graphqlOperation(listSubGroupsByGroupId, { groupId: group.id }));
        const subGroupsItems = subGroups.data.listSubGroupsByGroupId.items
        // For the subgroup items, get the parent groups, and recursively call this function again
        var parentGroups = []
        for (const groupObject of subGroupsItems) {
            parentGroups.push(groupObject.parentGroup)
        }
        await recursiveGetGroupsInTree(parentGroups, groups_array)
    }
}

export const getAllGoals = async (userId) => {
    const lettersOfIntentData = await API.graphql(graphqlOperation(listLoIByEmployeeId, { employeeId: userId }));
    const lettersOfIntent = lettersOfIntentData.data.listLoIByEmployeeId.items
    var goals = []
    const today = new Date();
    for (const loi of lettersOfIntent) {
        const loiActive = new Date(loi.startDate) <= today && new Date(loi.endDate) >= today
        for (const goal of loi.goals.items) {
            var goalItem = goal
            goal.active = loiActive
            goals = goals.concat(goalItem)
        }
    }
    return goals
}

export const getAllActiveAchievements = async (userId) => {
    // Active means that start_date is before today. I.e. no future achievements
    const achievementsData = await API.graphql(graphqlOperation(listAchievementsByEmployeeIdReduced, { employeeId: userId, limit: 1000 }));
    const achievements = achievementsData.data.listAchievementsByEmployeeId.items
    const today = new Date();
    const activeAchievements = achievements.filter(achievement => new Date(achievement.startDate) <= today)
    return activeAchievements
}

export const achievedActiveGoals = async (userId) => {
    const activeAchievements = await getAllActiveAchievements(userId)
    const activeGoals = await currentActiveGoals(userId)
    const achievedGoals = activeGoals.filter(
        goal => goal.amount <= activeAchievements.filter(a => a.loiGoalId === goal.id).reduce(function (prev, cur) { return prev + cur.amount; }, 0)
    )
    return achievedGoals
}

export const currentActiveGoals = async (userId) => {
    const lettersOfIntentData = await API.graphql(graphqlOperation(listLoIByEmployeeId, { employeeId: userId }));
    const lettersOfIntent = lettersOfIntentData.data.listLoIByEmployeeId.items
    const today = new Date();
    const activeLois = lettersOfIntent.filter(loi => new Date(loi.startDate) <= today && new Date(loi.endDate) >= today)
    var activeGoals = []
    for (const loi of activeLois) {
        activeGoals = activeGoals.concat(loi.goals.items)
    }
    return activeGoals
}

export const getGoalsWithProgress = async (userId) => {
    const all_goals = await getAllGoals(userId)
    const all_achievements = await getAllActiveAchievements(userId)
    var goals = all_goals
    for (const goal of goals) {
        const goal_achievement_amount = all_achievements.filter(a => a.loiGoalId === goal.id).reduce(function (prev, cur) { return prev + cur.amount; }, 0)
        goal.progress = goal_achievement_amount
    }
    return goals
}

export const getGoalsWithProgressFromLoI = async (loi, achievements) => {
    const goals = loi.goals.items
    for (const goal of goals) {
        const goal_achievement_amount = achievements.filter(a => a.loiGoalId === goal.id).reduce(function (prev, cur) { return prev + cur.amount; }, 0)
        goal.progress = goal_achievement_amount
    }
    return goals
}

export const formatGroupGoalsNicely = async (groupGoals, requester_id) => {
    var formattedGroupGoals = []
    const paramObject = groupGoals.map(item => ({ goalId: item.id, goalAmount: item.amount, goalType: item.goalType }))
    const groupGoalProgressData = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
        input: {
            requesterId: requester_id,
            dataSubjectId: "",
            operation: 'getGroupGoalProgress',
            params: JSON.stringify({ goals: paramObject })
        }
    }))
    const progressData = groupGoalProgressData.data.getDataWithAuthorisationFunction
    const query_data = JSON.parse(progressData.queryReturn)
    for (const groupGoal of groupGoals) {
        var progress = null
        const goalProgress = query_data.find(item => item.goalId === groupGoal.id) || null
        if (progressData?.status === "SUCCESS" && goalProgress !== null) {
            var raw_amount = null
            var provisioned_amount = 0
            var nr_of_employees = 0
            raw_amount = goalProgress.total_amount,
                progress = goalProgress.achievements_amount,
                provisioned_amount = goalProgress.provisioned_amount,
                nr_of_employees = goalProgress.unique_employees
        }
        formattedGroupGoals.push(
            {
                id: groupGoal.id,
                name: groupGoal.name,
                description: groupGoal.description,
                leadershipPrinciples: groupGoal.leadershipPrinciples,
                roleGuidelines: groupGoal.roleGuidelines,
                startDate: groupGoal.startDate,
                endDate: groupGoal.endDate,
                goalType: groupGoal.goalType,
                group: groupGoal.group,
                amount: groupGoal.amount,
                raw_amount: raw_amount,
                nr_of_employees: nr_of_employees,
                provisioned_amount: provisioned_amount,
                progress: progress === null ? "ERROR" : progress,
                mandatory: groupGoal.mandatory,
                deactivated: groupGoal.deactivated,
                draft: groupGoal.draft
            }
        )
    }
    return formattedGroupGoals
}

export const formatLoiGoalsNicely = (loiGoals) => {
    var formattedLoiGoals = []
    for (const loiGoal of loiGoals) {
        const loiAmount = loiGoal.amount
        const goalAmount = loiGoal.goal.amount
        var amount = loiGoal.amount
        if (loiAmount !== goalAmount) {
            amount = `${loiAmount} (Total assigned to group: ${goalAmount})`
        }
        var goalObject = {
            id: loiGoal.id,
            name: loiGoal.goal.name,
            description: loiGoal.goal.description,
            leadershipPrinciples: loiGoal.goal.leadershipPrinciples,
            roleGuidelines: loiGoal.goal.roleGuidelines,
            startDate: loiGoal.goal.startDate,
            endDate: loiGoal.goal.endDate,
            goalType: loiGoal.goal.goalType,
            group: loiGoal.goal.group,
            amount: amount,
            raw_amount: loiGoal.amount
        }

        const optionalFields = ["active", "progress"]
        for (const field of optionalFields) {
            if (loiGoal[field] !== undefined) {
                goalObject[field] = loiGoal[field]
            }
        }
        formattedLoiGoals.push(goalObject)
    }
    return formattedLoiGoals
}

export const getRecursiveMembers = async (group) => {
    var members = []
    for (const directMember of group.members.items) {
        var memberObject = directMember
        memberObject.membership = 'direct'
        members.push(memberObject)
    }
    const recursiveGroups = await getRecursiveGroupsSubGroups(group)
    for (const group of recursiveGroups) {
        for (const member of group.members.items) {
            var memberObject = member
            memberObject.membership = 'subgroup'
            members.push(memberObject)
        }
    }
    // Add the invited users to the list as well.
    const allowlisted = await API.graphql(graphqlOperation(queries.listGroupInvitees,
        { filter: { groupId: { eq: group.id } } }));

    for (const item of allowlisted.data.listGroupInvitees.items) {
        let invitee = {
            "employee": {
                firstName: "-",
                lastName: "",
                id: item.alias,
                managerId: "-",
            },
            "membership": "invited",
            "employeeId": item.alias
        }
        members.push(invitee)
    }
    return members
};