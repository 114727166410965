/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

 https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import { API, graphqlOperation } from 'aws-amplify';
import React from 'react';
import {
  // import the components that you use in the file here
  AppLayout,
  BreadcrumbGroup,
  SpaceBetween,
} from '../../aws-ui-components';
import ServiceNavigation from '../../common-components/ServiceNavigation';

import { GetOrCreateUser } from '../../helpers/authHelpers'

import * as queries from '../../graphql/queries'

import { customListAdditionalManagersByEmployeeId } from '../../graphql/customQueries'

import { getRecursiveGroups } from '../../helpers/goalHelpers'


import ManagingGroupsTable from './ManagingGroups'
import ParticipatingGroupsTable from './ParticipatingGroups'

class GroupOverview extends React.Component {
    constructor(props){
      super(props);
      this.state = {
          user: null,
          employee: null,
          managingGroups: [],
          participatingGroups: []
      }
      this.updateState.bind(this)
    }

    async componentDidMount() {
        await this.updateState()
    }

    createSingleObject = (item) => {
      var group = item.group
      group.actionsAllowed = item.actionsAllowed
      return group
    }

    updateState = async() => {
      const employee = await GetOrCreateUser()
      const managingGroupsData = await API.graphql(graphqlOperation(queries.listByGroupManagerId, {groupManagerId: employee.id}))
      const managingGroups = managingGroupsData.data.listByGroupManagerId.items
      const additionalManagingGroupsData = await API.graphql(graphqlOperation(customListAdditionalManagersByEmployeeId, {employeeId: employee.id}))
      const additionalManagingGroups = additionalManagingGroupsData.data.listAdditionalManagersByEmployeeId.items
      const additionalGroups = additionalManagingGroups.map(item => this.createSingleObject(item))
      const participatingGroups = employee.groupMemberships.items
      const groups = participatingGroups.map(a => a.group)
      const groupsToDisplay = await getRecursiveGroups(groups)
      const totalGroups = managingGroups.concat(additionalGroups)
      this.setState({managingGroups: totalGroups, employee: employee, participatingGroups: groupsToDisplay})
    }

    render() {
        const Breadcrumbs = () => (
            <BreadcrumbGroup
              items={[
                {
                  text: 'FAST',
                  href: '#/'
                },
                {
                  text: 'Manage Groups',
                  href: '#/groups'
                }
              ]}
            />
          );
          
        // Main content area (fill it in with components!)
        const Content = () => (
            <SpaceBetween direction="vertical" size="xl">
                <ManagingGroupsTable groups={this.state.managingGroups} employee={this.state.employee} updateState={this.updateState}/>
                <ParticipatingGroupsTable groups={this.state.participatingGroups} employee={this.state.employee} updateState={this.updateState}/>
            </SpaceBetween>
        )

        return (
            <AppLayout
              navigation={<ServiceNavigation />}
              breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
              content={<Content />} // Main content on the page, defined below
              contentType="default" // Sets default app layout settings for widths
              navigationOpen={true}
              toolsHide={true}
            />
        );
    }
}

// Breadcrumb content


export default GroupOverview