export const DATA = 
{
    "metadata":{
       "count":10,
       "totalHits":50
    },
    "fieldTypes":{
       "featuredImageUrl":"URL",
       "postExcerpt":"Text",
       "createdDate":"DateTime",
       "displayDate":"Text",
       "modifiedDate":"DateTime",
       "link":"Text",
       "contributors":"Text",
       "title":"Text",
       "contentType":"Text",
       "slug":"Text"
    },
    "items":[
       {
          "tags":[
             {
                "tagNamespaceId":"blog-posts#category-networking-content-delivery",
                "createdBy":"awsblog",
                "name":"amazon-cloudfront",
                "dateUpdated":"2020-05-11T19:02:49+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:27:59+0000",
                "description":"{\"name\":\"Amazon CloudFront\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-networking-content-delivery#amazon-cloudfront"
             },
             {
                "tagNamespaceId":"blog-posts#category-database",
                "createdBy":"awsblog",
                "name":"amazon-rds",
                "dateUpdated":"2020-05-08T17:28:43+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:41:38+0000",
                "description":"{\"name\":\"Amazon RDS\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-database#amazon-rds"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"compute",
                "dateUpdated":"2020-05-12T12:57:31+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-16T02:43:27+0000",
                "description":"{\"name\":\"Compute\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#compute"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-certificate-manager",
                "dateUpdated":"2020-04-28T11:07:09+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T04:09:14+0000",
                "description":"{\"name\":\"AWS Certificate Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-certificate-manager"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"security-identity-compliance",
                "dateUpdated":"2020-05-11T13:08:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:35:56+0000",
                "description":"{\"name\":\"Security, Identity, \u0026amp; Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#security-identity-compliance"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"kubernetes",
                "dateUpdated":"2021-03-18T16:37:49+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:28:18+0000",
                "description":"{\"name\":\"kubernetes\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#kubernetes"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"sitecore",
                "dateUpdated":"2021-03-17T15:31:08+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2021-03-17T15:31:08+0000",
                "description":"{\"name\":\"Sitecore\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#sitecore"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"database",
                "dateUpdated":"2020-05-12T00:32:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:27+0000",
                "description":"{\"name\":\"Database\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#database"
             },
             {
                "tagNamespaceId":"blog-posts#category-compute",
                "createdBy":"awsblog",
                "name":"amazon-kubernetes-service",
                "dateUpdated":"2020-05-12T12:57:31+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-07-14T16:41:37+0000",
                "description":"{\"name\":\"Amazon Elastic Kubernetes Service\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-compute#amazon-kubernetes-service"
             },
             {
                "tagNamespaceId":"GLOBAL#content-type",
                "createdBy":"jenbar",
                "name":"blog-post",
                "dateUpdated":"2020-04-29T05:19:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"jenbar",
                "dateCreated":"2020-04-29T05:19:18+0000",
                "description":"Blog Post",
                "id":"GLOBAL#content-type#blog-post"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"networking-content-delivery",
                "dateUpdated":"2020-05-12T11:35:53+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:40:25+0000",
                "description":"{\"name\":\"Networking \u0026amp; Content Delivery\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#networking-content-delivery"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"architecture",
                "dateUpdated":"2021-03-16T17:30:09+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2018-03-20T03:59:14+0000",
                "description":"{\"name\":\"Architecture\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#architecture"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"architecture",
                "dateUpdated":"2020-05-05T15:52:58+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:57:21+0000",
                "description":"{\"name\":\"Architecture\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#architecture"
             }
          ],
          "item":{
             "createdBy":"[\"Borja Prado Miguelez\",\"Vitalii Tylyk\"]",
             "locale":"en_US",
             "author":"[\"Borja Prado Miguelez\",\"Vitalii Tylyk\"]",
             "dateUpdated":"2021-03-17T15:32:11+0000",
             "score":0.0,
             "name":"blog-posts#36-4920",
             "numImpressions":0,
             "dateCreated":"2021-03-17T15:31:08+0000",
             "additionalFields":{
                "featuredImageUrl":"https://d2908q01vomqb2.cloudfront.net/fc074d501302eb2b93e2554793fcaf50b3bf7291/2021/03/15/leaseplan_architecture-300x300.png",
                "postExcerpt":"LeasePlan is one of the world\u0026#8217;s leading car leasing companies, with 1.9 million vehicles in more than 30 countries. They manage the entire lifecycle of their fleet, from purchase and maintenance to the sale of returned lease cars. Sitecore is a leading content management system in the enterprise market. It allows organizations to combine content [...]\n",
                "createdDate":"2021-03-17T15:30:23Z",
                "displayDate":"17 Mar 2021",
                "link":"https://aws.amazon.com/blogs/architecture/leaseplan-sitecore-implementation-with-amazon-eks/",
                "modifiedDate":"2021-03-17T15:30:23Z",
                "contributors":"Borja Prado Miguelez, Vitalii Tylyk",
                "title":"LeasePlan Sitecore Implementation with Amazon EKS",
                "contentType":"Blog Post",
                "slug":"leaseplan-sitecore-implementation-with-amazon-eks"
             },
             "id":"blog-posts#36-4920",
             "directoryId":"blog-posts",
             "lastUpdatedBy":"[\"Borja Prado Miguelez\",\"Vitalii Tylyk\"]"
          }
       },
       {
          "tags":[
             {
                "tagNamespaceId":"blog-posts#category-compute",
                "createdBy":"awsblog",
                "name":"amazon-ec2",
                "dateUpdated":"2020-05-12T09:43:06+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:37:17+0000",
                "description":"{\"name\":\"Amazon EC2\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-compute#amazon-ec2"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-certificate-manager",
                "dateUpdated":"2020-04-28T11:07:09+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T04:09:14+0000",
                "description":"{\"name\":\"AWS Certificate Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-certificate-manager"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"security-identity-compliance",
                "dateUpdated":"2020-05-11T13:08:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:35:56+0000",
                "description":"{\"name\":\"Security, Identity, \u0026amp; Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#security-identity-compliance"
             },
             {
                "tagNamespaceId":"blog-posts#category-public-sector",
                "createdBy":"awsblog",
                "name":"education",
                "dateUpdated":"2020-05-11T16:56:27+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-10-24T20:25:47+0000",
                "description":"{\"name\":\"Education\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-public-sector#education"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"public-sector",
                "dateUpdated":"2020-05-11T16:56:28+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:45:20+0000",
                "description":"{\"name\":\"Public Sector\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#public-sector"
             },
             {
                "tagNamespaceId":"blog-posts#category-public-sector-government",
                "createdBy":"awsblog",
                "name":"federal",
                "dateUpdated":"2020-05-07T13:56:24+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-09-19T02:26:11+0000",
                "description":"{\"name\":\"Federal\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-public-sector-government#federal"
             },
             {
                "tagNamespaceId":"blog-posts#category-public-sector",
                "createdBy":"awsblog",
                "name":"government",
                "dateUpdated":"2020-05-11T16:56:27+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-09-27T17:32:24+0000",
                "description":"{\"name\":\"Government\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-public-sector#government"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"compliance",
                "dateUpdated":"2020-05-01T20:29:36+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-10-24T21:45:36+0000",
                "description":"{\"name\":\"Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#compliance"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"compute",
                "dateUpdated":"2020-05-12T12:57:31+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-16T02:43:27+0000",
                "description":"{\"name\":\"Compute\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#compute"
             },
             {
                "tagNamespaceId":"blog-posts#category-industries",
                "createdBy":"awsblog",
                "name":"financial-services",
                "dateUpdated":"2020-05-12T11:35:54+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-09-16T20:26:30+0000",
                "description":"{\"name\":\"Financial Services\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-industries#financial-services"
             },
             {
                "tagNamespaceId":"blog-posts#category-public-sector",
                "createdBy":"awsblog",
                "name":"nonprofit",
                "dateUpdated":"2020-05-11T16:56:28+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-10-24T20:25:47+0000",
                "description":"{\"name\":\"Nonprofit\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-public-sector#nonprofit"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"industries",
                "dateUpdated":"2020-05-12T11:35:54+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-08-29T21:09:58+0000",
                "description":"{\"name\":\"Industries\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#industries"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"encryption",
                "dateUpdated":"2021-03-13T00:23:25+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:36:33+0000",
                "description":"{\"name\":\"Encryption\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#encryption"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"aws-nitro-enclaves",
                "dateUpdated":"2021-03-01T20:54:53+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2021-03-01T20:54:53+0000",
                "description":"{\"name\":\"AWS Nitro Enclaves\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#aws-nitro-enclaves"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"public-sector",
                "dateUpdated":"2021-02-12T10:34:19+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:22:15+0000",
                "description":"{\"name\":\"public sector\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#public-sector"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"compliance",
                "dateUpdated":"2021-03-15T17:03:27+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:11:53+0000",
                "description":"{\"name\":\"compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#compliance"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"government",
                "dateUpdated":"2021-03-15T19:45:46+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:48:00+0000",
                "description":"{\"name\":\"government\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#government"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"federal-government",
                "dateUpdated":"2020-05-07T13:56:24+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:01:07+0000",
                "description":"{\"name\":\"federal government\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#federal-government"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"fedfin",
                "dateUpdated":"2021-03-01T20:54:53+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2021-03-01T20:54:53+0000",
                "description":"{\"name\":\"FedFin\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#fedfin"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"aws-certificate-manager",
                "dateUpdated":"2020-04-28T09:05:11+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:34:17+0000",
                "description":"{\"name\":\"AWS Certificate Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#aws-certificate-manager"
             },
             {
                "tagNamespaceId":"blog-posts#category-post-types",
                "createdBy":"awsblog",
                "name":"technical-how-to",
                "dateUpdated":"2020-05-12T11:35:54+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-03-03T13:59:41+0000",
                "description":"{\"name\":\"Technical How-to\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-post-types#technical-how-to"
             },
             {
                "tagNamespaceId":"blog-posts#category-public-sector",
                "createdBy":"awsblog",
                "name":"healthcare-public-sector",
                "dateUpdated":"2020-05-11T16:56:28+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-04-01T01:43:04+0000",
                "description":"{\"name\":\"Healthcare\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-public-sector#healthcare-public-sector"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"post-types",
                "dateUpdated":"2020-05-12T11:35:54+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-03-02T14:05:47+0000",
                "description":"{\"name\":\"*Post Types\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#post-types"
             },
             {
                "tagNamespaceId":"GLOBAL#content-type",
                "createdBy":"jenbar",
                "name":"blog-post",
                "dateUpdated":"2020-04-29T05:19:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"jenbar",
                "dateCreated":"2020-04-29T05:19:18+0000",
                "description":"Blog Post",
                "id":"GLOBAL#content-type#blog-post"
             }
          ],
          "item":{
             "createdBy":"[\"J.D. Bean\"]",
             "locale":"en_US",
             "author":"[\"J.D. Bean\"]",
             "dateUpdated":"2021-03-01T22:15:10+0000",
             "score":0.0,
             "name":"blog-posts#18-10450",
             "numImpressions":0,
             "dateCreated":"2021-03-01T20:54:53+0000",
             "additionalFields":{
                "featuredImageUrl":"https://d2908q01vomqb2.cloudfront.net/9e6a55b6b4563e652a23be9d623ca5055c356940/2021/03/01/Encryption-in-transit-for-public-sector-300x149.png",
                "postExcerpt":"Government, education, nonprofit, healthcare, and other public sector organizations process and store sensitive data including health records, tax data, PII, student data, criminal justice information, and financial data. These workloads carry stringent security and compliance requirements to protect the confidentiality, integrity, and availability of this data both in transit and at rest. Best practices for protection of data in transit include enforcing appropriately defined encryption requirements, authenticating network communications, and implementing secure key and certificate management systems. In this post, I demonstrate a solution for deploying a highly available and fault tolerant web service with managed certificates and TLS termination performed on customer-managed EC2 Nitro instances using ACM for Nitro Enclaves.\n",
                "createdDate":"2021-03-01T20:54:38Z",
                "displayDate":"01 Mar 2021",
                "link":"https://aws.amazon.com/blogs/publicsector/encryption-in-transit-public-sector-workloads-aws-nitro-enclaves-aws-certificate-manager/",
                "modifiedDate":"2021-03-01T22:14:40Z",
                "contributors":"J.D. Bean",
                "title":"Encryption-in-transit for public sector workloads with AWS Nitro Enclaves and AWS Certificate Manager",
                "contentType":"Blog Post",
                "slug":"encryption-in-transit-public-sector-workloads-aws-nitro-enclaves-aws-certificate-manager"
             },
             "id":"blog-posts#18-10450",
             "directoryId":"blog-posts",
             "lastUpdatedBy":"[\"J.D. Bean\"]"
          }
       },
       {
          "tags":[
             {
                "tagNamespaceId":"blog-posts#category-desktop-app-streaming",
                "createdBy":"awsblog",
                "name":"amazon-appstream-2-0",
                "dateUpdated":"2020-05-08T17:30:21+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:28:07+0000",
                "description":"{\"name\":\"Amazon AppStream 2.0\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-desktop-app-streaming#amazon-appstream-2-0"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"desktop-app-streaming",
                "dateUpdated":"2020-05-08T17:30:22+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:28:07+0000",
                "description":"{\"name\":\"Desktop \u0026amp; Application Streaming\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#desktop-app-streaming"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"amazon-guardduty",
                "dateUpdated":"2020-05-11T13:07:59+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-11-29T05:34:08+0000",
                "description":"{\"name\":\"Amazon GuardDuty\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#amazon-guardduty"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"security-identity-compliance",
                "dateUpdated":"2020-05-11T13:08:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:35:56+0000",
                "description":"{\"name\":\"Security, Identity, \u0026amp; Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#security-identity-compliance"
             },
             {
                "tagNamespaceId":"blog-posts#category-developer-tools",
                "createdBy":"awsblog",
                "name":"aws-codedeploy",
                "dateUpdated":"2020-04-30T03:01:37+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:30:03+0000",
                "description":"{\"name\":\"AWS CodeDeploy\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-developer-tools#aws-codedeploy"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"management-tools",
                "dateUpdated":"2020-05-12T00:32:27+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:20+0000",
                "description":"{\"name\":\"Management Tools\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#management-tools"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-certificate-manager",
                "dateUpdated":"2020-04-28T11:07:09+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T04:09:14+0000",
                "description":"{\"name\":\"AWS Certificate Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-certificate-manager"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"developer-tools",
                "dateUpdated":"2020-05-08T14:18:01+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:38:05+0000",
                "description":"{\"name\":\"Developer Tools\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#developer-tools"
             },
             {
                "tagNamespaceId":"blog-posts#category-developer-tools",
                "createdBy":"awsblog",
                "name":"aws-codepipeline",
                "dateUpdated":"2020-04-28T11:24:48+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:54:39+0000",
                "description":"{\"name\":\"AWS CodePipeline\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-developer-tools#aws-codepipeline"
             },
             {
                "tagNamespaceId":"blog-posts#category-developer-tools",
                "createdBy":"awsblog",
                "name":"aws-codebuild",
                "dateUpdated":"2020-05-06T15:28:22+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:38:05+0000",
                "description":"{\"name\":\"AWS CodeBuild\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-developer-tools#aws-codebuild"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-key-management-service",
                "dateUpdated":"2020-04-28T09:21:39+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-10-12T19:34:09+0000",
                "description":"{\"name\":\"AWS Key Management Service\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-key-management-service"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-identity-and-access-management-iam",
                "dateUpdated":"2020-05-03T22:11:36+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-10-02T16:41:36+0000",
                "description":"{\"name\":\"AWS Identity and Access Management (IAM)\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-identity-and-access-management-iam"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-directory-service",
                "dateUpdated":"2020-04-28T11:21:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T04:12:36+0000",
                "description":"{\"name\":\"AWS Directory Service\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-directory-service"
             },
             {
                "tagNamespaceId":"blog-posts#category-management-tools",
                "createdBy":"awsblog",
                "name":"aws-cloudformation",
                "dateUpdated":"2020-05-12T00:32:27+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:27:47+0000",
                "description":"{\"name\":\"AWS CloudFormation\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-management-tools#aws-cloudformation"
             },
             {
                "tagNamespaceId":"blog-posts#category-management-tools",
                "createdBy":"awsblog",
                "name":"aws-trusted-advisor",
                "dateUpdated":"2020-05-04T18:41:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T04:11:54+0000",
                "description":"{\"name\":\"AWS Trusted Advisor\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-management-tools#aws-trusted-advisor"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-secrets-manager",
                "dateUpdated":"2020-04-28T11:11:56+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-02-14T00:09:16+0000",
                "description":"{\"name\":\"AWS Secrets Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-secrets-manager"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"amazon-appstream-2-0",
                "dateUpdated":"2021-03-05T11:26:53+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2018-03-08T21:35:16+0000",
                "description":"{\"name\":\"Amazon AppStream 2.0\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#amazon-appstream-2-0"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-shield",
                "dateUpdated":"2020-04-28T11:02:24+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:48:32+0000",
                "description":"{\"name\":\"AWS Shield\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-shield"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"amazon-ec2",
                "dateUpdated":"2021-03-11T18:41:08+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:29:11+0000",
                "description":"{\"name\":\"Amazon EC2\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#amazon-ec2"
             },
             {
                "tagNamespaceId":"blog-posts#category-management-tools",
                "createdBy":"awsblog",
                "name":"aws-cloudtrail",
                "dateUpdated":"2020-05-11T14:19:43+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:28:05+0000",
                "description":"{\"name\":\"AWS CloudTrail\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-management-tools#aws-cloudtrail"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"amazon-rds",
                "dateUpdated":"2020-05-08T17:28:44+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:56:31+0000",
                "description":"{\"name\":\"Amazon RDS\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#amazon-rds"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"aws-well-architected",
                "dateUpdated":"2020-08-14T22:34:20+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-08-14T22:34:20+0000",
                "description":"{\"name\":\"AWS Well-Architected\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#aws-well-architected"
             },
             {
                "tagNamespaceId":"blog-posts#category-management-tools",
                "createdBy":"awsblog",
                "name":"aws-systems-manager",
                "dateUpdated":"2020-05-11T20:24:33+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-07-10T20:21:27+0000",
                "description":"{\"name\":\"AWS Systems Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-management-tools#aws-systems-manager"
             },
             {
                "tagNamespaceId":"blog-posts#category-post-types",
                "createdBy":"awsblog",
                "name":"technical-how-to",
                "dateUpdated":"2020-05-12T11:35:54+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-03-03T13:59:41+0000",
                "description":"{\"name\":\"Technical How-to\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-post-types#technical-how-to"
             },
             {
                "tagNamespaceId":"blog-posts#category-developer-tools",
                "createdBy":"awsblog",
                "name":"aws-codecommit",
                "dateUpdated":"2020-05-08T14:18:01+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:30:02+0000",
                "description":"{\"name\":\"AWS CodeCommit\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-developer-tools#aws-codecommit"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"post-types",
                "dateUpdated":"2020-05-12T11:35:54+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-03-02T14:05:47+0000",
                "description":"{\"name\":\"*Post Types\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#post-types"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"amazon-s3",
                "dateUpdated":"2021-02-12T00:16:47+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:33:32+0000",
                "description":"{\"name\":\"Amazon S3\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#amazon-s3"
             },
             {
                "tagNamespaceId":"blog-posts#category-aws-well-architected",
                "createdBy":"awsblog",
                "name":"aws-well-architected-tool",
                "dateUpdated":"2020-08-14T22:34:20+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-08-14T22:34:20+0000",
                "description":"{\"name\":\"AWS Well-Architected Tool\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-aws-well-architected#aws-well-architected-tool"
             },
             {
                "tagNamespaceId":"GLOBAL#content-type",
                "createdBy":"jenbar",
                "name":"blog-post",
                "dateUpdated":"2020-04-29T05:19:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"jenbar",
                "dateCreated":"2020-04-29T05:19:18+0000",
                "description":"Blog Post",
                "id":"GLOBAL#content-type#blog-post"
             }
          ],
          "item":{
             "createdBy":"[\"Bernard Bergeron\"]",
             "locale":"en_US",
             "author":"[\"Bernard Bergeron\"]",
             "dateUpdated":"2021-03-05T11:28:30+0000",
             "score":0.0,
             "name":"blog-posts#47-2074",
             "numImpressions":0,
             "dateCreated":"2021-03-01T19:17:25+0000",
             "additionalFields":{
                "featuredImageUrl":"https://d2908q01vomqb2.cloudfront.net/827bfc458708f0b442009c9c9836f7e4b65557fb/2020/06/03/Blog-Post_thumbnail-300x150.png",
                "postExcerpt":"DXC Technology is a global IT service leader providing end-to-end IT services on digital transformation to businesses and governments.  DXC is active in the areas of mainframe technology transformation to help customer move to Agile and DevSecOps practices using the cloud. DXC created an in-house DevSecOps platform at a global insurer for IBM Z series mainframe development. [...]\n",
                "createdDate":"2021-03-01T19:17:15Z",
                "displayDate":"01 Mar 2021",
                "link":"https://aws.amazon.com/blogs/desktop-and-application-streaming/dxc-technology-devsecops-cicd-mainframe-amazon-appstream-2-0/",
                "modifiedDate":"2021-03-05T11:28:19Z",
                "contributors":"Bernard Bergeron",
                "title":"DXC Technology creates DevSecOps and CI/CD for mainframe and Java using Amazon AppStream 2.0",
                "contentType":"Blog Post",
                "slug":"dxc-technology-devsecops-cicd-mainframe-amazon-appstream-2-0"
             },
             "id":"blog-posts#47-2074",
             "directoryId":"blog-posts",
             "lastUpdatedBy":"[\"Bernard Bergeron\"]"
          }
       },
       {
          "tags":[
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-certificate-manager",
                "dateUpdated":"2020-04-28T11:07:09+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T04:09:14+0000",
                "description":"{\"name\":\"AWS Certificate Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-certificate-manager"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"security-identity-compliance",
                "dateUpdated":"2020-05-11T13:08:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:35:56+0000",
                "description":"{\"name\":\"Security, Identity, \u0026amp; Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#security-identity-compliance"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-secrets-manager",
                "dateUpdated":"2020-04-28T11:11:56+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-02-14T00:09:16+0000",
                "description":"{\"name\":\"AWS Secrets Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-secrets-manager"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"acm",
                "dateUpdated":"2021-02-12T13:31:48+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:48:39+0000",
                "description":"{\"name\":\"acm\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#acm"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"aws-kms",
                "dateUpdated":"2021-02-12T02:53:31+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:30:47+0000",
                "description":"{\"name\":\"AWS KMS\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#aws-kms"
             },
             {
                "tagNamespaceId":"blog-posts#category-learning-levels",
                "createdBy":"awsblog",
                "name":"intermediate-200",
                "dateUpdated":"2019-03-18T12:38:29+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-18T12:38:29+0000",
                "description":"{\"name\":\"Intermediate (200)\",\"description\":\"Readers have used multiple AWS services from the console, API, or CLI (or all three).\"}",
                "id":"blog-posts#category-learning-levels#intermediate-200"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"aws-secrets-manager",
                "dateUpdated":"2020-04-28T08:58:02+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2018-04-05T13:55:34+0000",
                "description":"{\"name\":\"AWS Secrets Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#aws-secrets-manager"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"systems-manager",
                "dateUpdated":"2021-02-12T04:38:01+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:30:04+0000",
                "description":"{\"name\":\"Systems Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#systems-manager"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"learning-levels",
                "dateUpdated":"2020-05-12T00:37:10+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-18T12:38:28+0000",
                "description":"{\"name\":\"Learning Levels\",\"description\":\"Please use these to classify your technical posts. For descriptions, visit: https://w.amazon.com/bin/view/AWS_Blog/Expertise_Levels/\"}",
                "id":"blog-posts#category#learning-levels"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"acm-private-ca",
                "dateUpdated":"2020-04-28T09:05:11+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-09-25T15:48:08+0000",
                "description":"{\"name\":\"ACM Private CA\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#acm-private-ca"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"security-blog",
                "dateUpdated":"2020-05-08T17:06:46+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-03-10T18:32:58+0000",
                "description":"{\"name\":\"Security Blog\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#security-blog"
             },
             {
                "tagNamespaceId":"GLOBAL#content-type",
                "createdBy":"jenbar",
                "name":"blog-post",
                "dateUpdated":"2020-04-29T05:19:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"jenbar",
                "dateCreated":"2020-04-29T05:19:18+0000",
                "description":"Blog Post",
                "id":"GLOBAL#content-type#blog-post"
             }
          ],
          "item":{
             "createdBy":"[\"Maitreya Ranganath\",\"Blake Franzen\"]",
             "locale":"en_US",
             "author":"[\"Maitreya Ranganath\",\"Blake Franzen\"]",
             "dateUpdated":"2021-02-12T03:12:53+0000",
             "score":0.0,
             "name":"blog-posts#30-18042",
             "numImpressions":0,
             "dateCreated":"2021-01-07T20:01:55+0000",
             "additionalFields":{
                "featuredImageUrl":"https://d2908q01vomqb2.cloudfront.net/22d200f8670dbdb3e253a90eee5098477c95c23d/2020/12/27/AWS-Secrets-Manager-Dec-2020-ForSocial-300x150.jpg",
                "postExcerpt":"AWS Certificate Manager (ACM) lets you easily provision, manage, and deploy public and private Secure Sockets Layer/Transport Layer Security (SSL/TLS) certificates for use with Amazon Web Services (AWS) services and your internal connected resources. For private certificates, AWS Certificate Manager Private Certificate Authority (ACM PCA) can be used to create private CA hierarchies, including root [...]\n",
                "createdDate":"2021-01-07T20:00:36Z",
                "displayDate":"07 Jan 2021",
                "link":"https://aws.amazon.com/blogs/security/use-aws-secrets-manager-to-simplify-the-management-of-private-certificates/",
                "modifiedDate":"2021-01-07T20:00:36Z",
                "contributors":"Maitreya Ranganath, Blake Franzen",
                "title":"Use AWS Secrets Manager to simplify the management of private certificates",
                "contentType":"Blog Post",
                "slug":"use-aws-secrets-manager-to-simplify-the-management-of-private-certificates"
             },
             "id":"blog-posts#30-18042",
             "directoryId":"blog-posts",
             "lastUpdatedBy":"[\"Maitreya Ranganath\",\"Blake Franzen\"]"
          }
       },
       {
          "tags":[
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"learning-levels",
                "dateUpdated":"2020-05-12T00:37:10+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-18T12:38:28+0000",
                "description":"{\"name\":\"Learning Levels\",\"description\":\"Please use these to classify your technical posts. For descriptions, visit: https://w.amazon.com/bin/view/AWS_Blog/Expertise_Levels/\"}",
                "id":"blog-posts#category#learning-levels"
             },
             {
                "tagNamespaceId":"blog-posts#category-learning-levels",
                "createdBy":"awsblog",
                "name":"advanced-300",
                "dateUpdated":"2019-03-26T14:55:04+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-26T14:55:04+0000",
                "description":"{\"name\":\"Advanced (300)\",\"description\":\"Readers are IT decision makers or developers who lean more toward the API or CLI.\"}",
                "id":"blog-posts#category-learning-levels#advanced-300"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"security-identity-compliance",
                "dateUpdated":"2020-05-11T13:08:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:35:56+0000",
                "description":"{\"name\":\"Security, Identity, \u0026amp; Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#security-identity-compliance"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"amazon-macie",
                "dateUpdated":"2020-04-28T10:51:03+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:29:25+0000",
                "description":"{\"name\":\"Amazon Macie\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#amazon-macie"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"chatops",
                "dateUpdated":"2021-02-12T04:40:48+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-11-26T00:08:38+0000",
                "description":"{\"name\":\"ChatOps\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#chatops"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"amazon-macie",
                "dateUpdated":"2021-02-12T15:14:16+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-10-12T11:25:57+0000",
                "description":"{\"name\":\"Amazon Macie\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#amazon-macie"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"compliance",
                "dateUpdated":"2021-03-15T17:03:27+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:11:53+0000",
                "description":"{\"name\":\"compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#compliance"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"automated-incident-response",
                "dateUpdated":"2020-06-01T17:59:02+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-06-01T17:59:02+0000",
                "description":"{\"name\":\"automated incident response\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#automated-incident-response"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"slack",
                "dateUpdated":"2021-02-12T15:15:46+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:38:04+0000",
                "description":"{\"name\":\"Slack\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#slack"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"security-blog",
                "dateUpdated":"2020-05-08T17:06:46+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-03-10T18:32:58+0000",
                "description":"{\"name\":\"Security Blog\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#security-blog"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"incident-response",
                "dateUpdated":"2021-03-01T21:06:13+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:28:45+0000",
                "description":"{\"name\":\"Incident response\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#incident-response"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"eventbridge",
                "dateUpdated":"2021-02-12T02:57:28+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-11-20T16:12:10+0000",
                "description":"{\"name\":\"EventBridge\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#eventbridge"
             },
             {
                "tagNamespaceId":"GLOBAL#content-type",
                "createdBy":"jenbar",
                "name":"blog-post",
                "dateUpdated":"2020-04-29T05:19:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"jenbar",
                "dateCreated":"2020-04-29T05:19:18+0000",
                "description":"Blog Post",
                "id":"GLOBAL#content-type#blog-post"
             }
          ],
          "item":{
             "createdBy":"[\"Nick Cuneo\"]",
             "locale":"en_US",
             "author":"[\"Nick Cuneo\"]",
             "dateUpdated":"2021-02-12T02:57:47+0000",
             "score":0.0,
             "name":"blog-posts#30-17902",
             "numImpressions":0,
             "dateCreated":"2021-01-05T18:17:26+0000",
             "additionalFields":{
                "featuredImageUrl":"https://d2908q01vomqb2.cloudfront.net/22d200f8670dbdb3e253a90eee5098477c95c23d/2020/12/17/ChatOps-Amazon-Macie-ForSocial-300x150.jpg",
                "postExcerpt":"The amount of data being collected, stored, and processed by Amazon Web Services (AWS) customers is growing at an exponential rate. In order to keep pace with this growth, customers are turning to scalable cloud storage services like Amazon Simple Storage Service (Amazon S3) to build data lakes at the petabyte scale. Customers are looking [...]\n",
                "createdDate":"2021-01-05T18:17:22Z",
                "displayDate":"05 Jan 2021",
                "link":"https://aws.amazon.com/blogs/security/deploy-an-automated-chatops-solution-for-remediating-amazon-macie-findings/",
                "modifiedDate":"2021-01-05T18:17:22Z",
                "contributors":"Nick Cuneo",
                "title":"Deploy an automated ChatOps solution for remediating Amazon Macie findings",
                "contentType":"Blog Post",
                "slug":"deploy-an-automated-chatops-solution-for-remediating-amazon-macie-findings"
             },
             "id":"blog-posts#30-17902",
             "directoryId":"blog-posts",
             "lastUpdatedBy":"[\"Nick Cuneo\"]"
          }
       },
       {
          "tags":[
             {
                "tagNamespaceId":"blog-posts#category-application-services",
                "createdBy":"awsblog",
                "name":"amazon-api-gateway-application-services",
                "dateUpdated":"2020-05-08T02:03:34+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:34+0000",
                "description":"{\"name\":\"Amazon API Gateway\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-application-services#amazon-api-gateway-application-services"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"application-services",
                "dateUpdated":"2020-05-12T00:32:19+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:34+0000",
                "description":"{\"name\":\"Application Services\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#application-services"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-certificate-manager",
                "dateUpdated":"2020-04-28T11:07:09+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T04:09:14+0000",
                "description":"{\"name\":\"AWS Certificate Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-certificate-manager"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"security-identity-compliance",
                "dateUpdated":"2020-05-11T13:08:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:35:56+0000",
                "description":"{\"name\":\"Security, Identity, \u0026amp; Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#security-identity-compliance"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"compute",
                "dateUpdated":"2020-05-12T12:57:31+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-16T02:43:27+0000",
                "description":"{\"name\":\"Compute\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#compute"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"serverless",
                "dateUpdated":"2020-05-08T20:38:09+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:35+0000",
                "description":"{\"name\":\"Serverless\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#serverless"
             },
             {
                "tagNamespaceId":"blog-posts#category-compute",
                "createdBy":"awsblog",
                "name":"aws-serverless-application-model",
                "dateUpdated":"2020-05-11T14:19:43+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-27T17:56:01+0000",
                "description":"{\"name\":\"AWS Serverless Application Model\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-compute#aws-serverless-application-model"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"serverless",
                "dateUpdated":"2021-03-15T14:36:31+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:28:26+0000",
                "description":"{\"name\":\"serverless\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#serverless"
             },
             {
                "tagNamespaceId":"GLOBAL#content-type",
                "createdBy":"jenbar",
                "name":"blog-post",
                "dateUpdated":"2020-04-29T05:19:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"jenbar",
                "dateCreated":"2020-04-29T05:19:18+0000",
                "description":"Blog Post",
                "id":"GLOBAL#content-type#blog-post"
             },
             {
                "tagNamespaceId":"blog-posts#category-compute",
                "createdBy":"awsblog",
                "name":"aws-lambda",
                "dateUpdated":"2020-05-11T14:19:43+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:34+0000",
                "description":"{\"name\":\"AWS Lambda\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-compute#aws-lambda"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"contributed",
                "dateUpdated":"2020-12-23T20:16:11+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-12-23T20:16:11+0000",
                "description":"{\"name\":\"contributed\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#contributed"
             }
          ],
          "item":{
             "createdBy":"[\"James Beswick\"]",
             "locale":"en_US",
             "author":"[\"James Beswick\"]",
             "dateUpdated":"2021-02-12T15:04:44+0000",
             "score":0.0,
             "name":"blog-posts#4-12710",
             "numImpressions":0,
             "dateCreated":"2020-12-22T16:05:33+0000",
             "additionalFields":{
                "featuredImageUrl":"https://d2908q01vomqb2.cloudfront.net/1b6453892473a467d07372d45eb05abc2031647a/2020/12/17/mtls1-300x155.jpg",
                "postExcerpt":"Mutual TLS (mTLS) for API Gateway is now generally available at no additional cost. This post shows how to automate mutual TLS for Amazon API Gateway HTTP APIs using the AWS Certificate Manager Private Certificate Authority as a private CA. Using infrastructure as code (IaC) enables you to develop, deploy, and scale cloud applications, often with greater speed, less risk, and reduced cost.\n",
                "createdDate":"2020-12-22T16:05:29Z",
                "displayDate":"22 Dec 2020",
                "link":"https://aws.amazon.com/blogs/compute/automating-mutual-tls-setup-for-amazon-api-gateway/",
                "modifiedDate":"2020-12-23T20:18:07Z",
                "contributors":"James Beswick",
                "title":"Automating mutual TLS setup for Amazon API Gateway",
                "contentType":"Blog Post",
                "slug":"automating-mutual-tls-setup-for-amazon-api-gateway"
             },
             "id":"blog-posts#4-12710",
             "directoryId":"blog-posts",
             "lastUpdatedBy":"[\"James Beswick\"]"
          }
       },
       {
          "tags":[
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"security-identity-compliance",
                "dateUpdated":"2020-05-11T13:08:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:35:56+0000",
                "description":"{\"name\":\"Security, Identity, \u0026amp; Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#security-identity-compliance"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"aws-certificate-manager",
                "dateUpdated":"2020-04-28T11:07:09+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T04:09:14+0000",
                "description":"{\"name\":\"AWS Certificate Manager\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#aws-certificate-manager"
             },
             {
                "tagNamespaceId":"blog-posts#category-management-tools",
                "createdBy":"awsblog",
                "name":"aws-cloudformation",
                "dateUpdated":"2020-05-12T00:32:27+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:27:47+0000",
                "description":"{\"name\":\"AWS CloudFormation\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-management-tools#aws-cloudformation"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"management-tools",
                "dateUpdated":"2020-05-12T00:32:27+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:20+0000",
                "description":"{\"name\":\"Management Tools\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#management-tools"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"compute",
                "dateUpdated":"2020-05-12T12:57:31+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-16T02:43:27+0000",
                "description":"{\"name\":\"Compute\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#compute"
             },
             {
                "tagNamespaceId":"blog-posts#category-compute",
                "createdBy":"awsblog",
                "name":"aws-lambda",
                "dateUpdated":"2020-05-11T14:19:43+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:34+0000",
                "description":"{\"name\":\"AWS Lambda\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-compute#aws-lambda"
             },
             {
                "tagNamespaceId":"blog-posts#category-learning-levels",
                "createdBy":"awsblog",
                "name":"intermediate-200",
                "dateUpdated":"2019-03-18T12:38:29+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-18T12:38:29+0000",
                "description":"{\"name\":\"Intermediate (200)\",\"description\":\"Readers have used multiple AWS services from the console, API, or CLI (or all three).\"}",
                "id":"blog-posts#category-learning-levels#intermediate-200"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"learning-levels",
                "dateUpdated":"2020-05-12T00:37:10+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-18T12:38:28+0000",
                "description":"{\"name\":\"Learning Levels\",\"description\":\"Please use these to classify your technical posts. For descriptions, visit: https://w.amazon.com/bin/view/AWS_Blog/Expertise_Levels/\"}",
                "id":"blog-posts#category#learning-levels"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"acm",
                "dateUpdated":"2021-02-12T13:31:48+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:48:39+0000",
                "description":"{\"name\":\"acm\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#acm"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"aws-iam",
                "dateUpdated":"2021-02-12T00:10:50+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:30:07+0000",
                "description":"{\"name\":\"AWS IAM\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#aws-iam"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"aws-cloudformation",
                "dateUpdated":"2021-02-12T14:45:11+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:27:49+0000",
                "description":"{\"name\":\"AWS CloudFormation\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#aws-cloudformation"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"aws-lambda",
                "dateUpdated":"2021-03-12T20:03:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:27:49+0000",
                "description":"{\"name\":\"AWS Lambda\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#aws-lambda"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"certificate",
                "dateUpdated":"2021-02-12T15:22:32+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2018-05-10T04:37:46+0000",
                "description":"{\"name\":\"certificate\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#certificate"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"cross-account",
                "dateUpdated":"2021-02-12T05:43:50+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2018-06-27T13:51:50+0000",
                "description":"{\"name\":\"cross-account\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#cross-account"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"security-blog",
                "dateUpdated":"2020-05-08T17:06:46+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-03-10T18:32:58+0000",
                "description":"{\"name\":\"Security Blog\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#security-blog"
             },
             {
                "tagNamespaceId":"GLOBAL#content-type",
                "createdBy":"jenbar",
                "name":"blog-post",
                "dateUpdated":"2020-04-29T05:19:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"jenbar",
                "dateCreated":"2020-04-29T05:19:18+0000",
                "description":"Blog Post",
                "id":"GLOBAL#content-type#blog-post"
             }
          ],
          "item":{
             "createdBy":"[\"Prakhar Malik\"]",
             "locale":"en_US",
             "author":"[\"Prakhar Malik\"]",
             "dateUpdated":"2021-02-12T03:25:26+0000",
             "score":0.0,
             "name":"blog-posts#30-17626",
             "numImpressions":0,
             "dateCreated":"2020-12-21T17:26:08+0000",
             "additionalFields":{
                "featuredImageUrl":"https://d2908q01vomqb2.cloudfront.net/22d200f8670dbdb3e253a90eee5098477c95c23d/2020/12/03/Deploy-public-ACM-certificates-ForSocial-300x150.jpg",
                "postExcerpt":"In this post, I take you through the steps to deploy a public AWS Certificate Manager (ACM) certificate across multiple accounts and AWS Regions by using the functionality of AWS CloudFormation StackSets and AWS Lambda. ACM is a service offered by Amazon Web Services (AWS) that you can use to obtain x509 v3 SSL/TLS certificates. [...]\n",
                "createdDate":"2020-12-21T17:26:01Z",
                "displayDate":"21 Dec 2020",
                "link":"https://aws.amazon.com/blogs/security/how-to-deploy-public-acm-certificates-across-multiple-aws-accounts-and-regions-using-aws-cloudformation-stacksets/",
                "modifiedDate":"2020-12-21T17:26:01Z",
                "contributors":"Prakhar Malik",
                "title":"How to deploy public ACM certificates across multiple AWS accounts and Regions using AWS CloudFormation StackSets",
                "contentType":"Blog Post",
                "slug":"how-to-deploy-public-acm-certificates-across-multiple-aws-accounts-and-regions-using-aws-cloudformation-stacksets"
             },
             "id":"blog-posts#30-17626",
             "directoryId":"blog-posts",
             "lastUpdatedBy":"[\"Prakhar Malik\"]"
          }
       },
       {
          "tags":[
             {
                "tagNamespaceId":"blog-posts#category-learning-levels",
                "createdBy":"awsblog",
                "name":"advanced-300",
                "dateUpdated":"2019-03-26T14:55:04+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-26T14:55:04+0000",
                "description":"{\"name\":\"Advanced (300)\",\"description\":\"Readers are IT decision makers or developers who lean more toward the API or CLI.\"}",
                "id":"blog-posts#category-learning-levels#advanced-300"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"learning-levels",
                "dateUpdated":"2020-05-12T00:37:10+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-18T12:38:28+0000",
                "description":"{\"name\":\"Learning Levels\",\"description\":\"Please use these to classify your technical posts. For descriptions, visit: https://w.amazon.com/bin/view/AWS_Blog/Expertise_Levels/\"}",
                "id":"blog-posts#category#learning-levels"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"amazon-macie",
                "dateUpdated":"2021-02-12T15:14:16+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-10-12T11:25:57+0000",
                "description":"{\"name\":\"Amazon Macie\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#amazon-macie"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"security-identity-compliance",
                "dateUpdated":"2020-05-11T13:08:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:35:56+0000",
                "description":"{\"name\":\"Security, Identity, \u0026amp; Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#security-identity-compliance"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"security-blog",
                "dateUpdated":"2020-05-08T17:06:46+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-03-10T18:32:58+0000",
                "description":"{\"name\":\"Security Blog\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#security-blog"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"amazon-dynamodb",
                "dateUpdated":"2021-02-12T00:21:17+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:30:57+0000",
                "description":"{\"name\":\"Amazon DynamoDB\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#amazon-dynamodb"
             },
             {
                "tagNamespaceId":"GLOBAL#content-type",
                "createdBy":"jenbar",
                "name":"blog-post",
                "dateUpdated":"2020-04-29T05:19:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"jenbar",
                "dateCreated":"2020-04-29T05:19:18+0000",
                "description":"Blog Post",
                "id":"GLOBAL#content-type#blog-post"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"database",
                "dateUpdated":"2020-05-12T00:32:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:27+0000",
                "description":"{\"name\":\"Database\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#database"
             },
             {
                "tagNamespaceId":"blog-posts#category-database",
                "createdBy":"awsblog",
                "name":"amazon-dynamodb",
                "dateUpdated":"2020-05-06T21:59:38+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:27:58+0000",
                "description":"{\"name\":\"Amazon DynamoDB\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-database#amazon-dynamodb"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"amazon-macie",
                "dateUpdated":"2020-04-28T10:51:03+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:29:25+0000",
                "description":"{\"name\":\"Amazon Macie\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#amazon-macie"
             }
          ],
          "item":{
             "createdBy":"[\"Sheldon Sides\"]",
             "locale":"en_US",
             "author":"[\"Sheldon Sides\"]",
             "dateUpdated":"2021-02-12T03:23:08+0000",
             "score":0.0,
             "name":"blog-posts#30-17686",
             "numImpressions":0,
             "dateCreated":"2020-12-11T16:39:15+0000",
             "additionalFields":{
                "featuredImageUrl":"https://d2908q01vomqb2.cloudfront.net/22d200f8670dbdb3e253a90eee5098477c95c23d/2020/12/06/Detecting-sensitive-data-DynamoDB-ForSocial-300x150.jpg",
                "postExcerpt":"Amazon Macie is a fully managed data security and data privacy service that uses machine learning and pattern matching to discover and protect your sensitive data in Amazon Web Services (AWS). It gives you the ability to automatically scan for sensitive data and get an inventory of your Amazon Simple Storage Service (Amazon S3) buckets. [...]\n",
                "createdDate":"2020-12-11T16:39:05Z",
                "displayDate":"11 Dec 2020",
                "link":"https://aws.amazon.com/blogs/security/detecting-sensitive-data-in-dynamodb-with-macie/",
                "modifiedDate":"2020-12-11T16:39:05Z",
                "contributors":"Sheldon Sides",
                "title":"Detecting sensitive data in DynamoDB with Macie",
                "contentType":"Blog Post",
                "slug":"detecting-sensitive-data-in-dynamodb-with-macie"
             },
             "id":"blog-posts#30-17686",
             "directoryId":"blog-posts",
             "lastUpdatedBy":"[\"Sheldon Sides\"]"
          }
       },
       {
          "tags":[
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"learning-levels",
                "dateUpdated":"2020-05-12T00:37:10+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-18T12:38:28+0000",
                "description":"{\"name\":\"Learning Levels\",\"description\":\"Please use these to classify your technical posts. For descriptions, visit: https://w.amazon.com/bin/view/AWS_Blog/Expertise_Levels/\"}",
                "id":"blog-posts#category#learning-levels"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"amazon-macie",
                "dateUpdated":"2020-04-28T10:51:03+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:29:25+0000",
                "description":"{\"name\":\"Amazon Macie\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#amazon-macie"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"amazon-s3",
                "dateUpdated":"2021-02-12T00:16:47+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:33:32+0000",
                "description":"{\"name\":\"Amazon S3\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#amazon-s3"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"amazon-macie",
                "dateUpdated":"2021-02-12T15:14:16+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-10-12T11:25:57+0000",
                "description":"{\"name\":\"Amazon Macie\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#amazon-macie"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"aws-lambda",
                "dateUpdated":"2021-03-12T20:03:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:27:49+0000",
                "description":"{\"name\":\"AWS Lambda\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#aws-lambda"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"security-identity-compliance",
                "dateUpdated":"2020-05-11T13:08:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:35:56+0000",
                "description":"{\"name\":\"Security, Identity, \u0026amp; Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#security-identity-compliance"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"cloud-security",
                "dateUpdated":"2021-03-16T23:01:39+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:53:09+0000",
                "description":"{\"name\":\"Cloud Security\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#cloud-security"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"devsecops",
                "dateUpdated":"2021-03-13T00:20:56+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:30:04+0000",
                "description":"{\"name\":\"DevSecOps\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#devsecops"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"data-discovery",
                "dateUpdated":"2020-12-09T21:27:28+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-12-09T21:27:28+0000",
                "description":"{\"name\":\"data discovery\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#data-discovery"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"data-lake",
                "dateUpdated":"2021-03-15T15:17:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:37:15+0000",
                "description":"{\"name\":\"data lake\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#data-lake"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"data-security",
                "dateUpdated":"2020-04-28T03:33:57+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2018-05-10T08:43:37+0000",
                "description":"{\"name\":\"data security\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#data-security"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"amazon-ses",
                "dateUpdated":"2021-03-17T01:31:12+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:38:40+0000",
                "description":"{\"name\":\"amazon ses\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#amazon-ses"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"step-functions",
                "dateUpdated":"2021-03-16T20:29:19+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:28:26+0000",
                "description":"{\"name\":\"step functions\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#step-functions"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"data-protection",
                "dateUpdated":"2021-02-12T10:46:41+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T01:31:23+0000",
                "description":"{\"name\":\"data protection\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#data-protection"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"api-gateway",
                "dateUpdated":"2021-02-17T16:40:30+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:54:01+0000",
                "description":"{\"name\":\"API Gateway\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#api-gateway"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"pii",
                "dateUpdated":"2020-04-28T03:37:34+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-10-12T18:41:40+0000",
                "description":"{\"name\":\"PII\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#pii"
             },
             {
                "tagNamespaceId":"GLOBAL#content-type",
                "createdBy":"jenbar",
                "name":"blog-post",
                "dateUpdated":"2020-04-29T05:19:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"jenbar",
                "dateCreated":"2020-04-29T05:19:18+0000",
                "description":"Blog Post",
                "id":"GLOBAL#content-type#blog-post"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"cybersecurity",
                "dateUpdated":"2020-04-28T02:57:10+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:46:53+0000",
                "description":"{\"name\":\"cybersecurity\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#cybersecurity"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"data-pipeline",
                "dateUpdated":"2021-03-13T00:21:53+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-24T00:41:06+0000",
                "description":"{\"name\":\"Data Pipeline\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#data-pipeline"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"security-blog",
                "dateUpdated":"2020-05-08T17:06:46+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2020-03-10T18:32:58+0000",
                "description":"{\"name\":\"Security Blog\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#security-blog"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"serverless",
                "dateUpdated":"2021-03-15T14:36:31+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:28:26+0000",
                "description":"{\"name\":\"serverless\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#serverless"
             },
             {
                "tagNamespaceId":"blog-posts#category-learning-levels",
                "createdBy":"awsblog",
                "name":"advanced-300",
                "dateUpdated":"2019-03-26T14:55:04+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-26T14:55:04+0000",
                "description":"{\"name\":\"Advanced (300)\",\"description\":\"Readers are IT decision makers or developers who lean more toward the API or CLI.\"}",
                "id":"blog-posts#category-learning-levels#advanced-300"
             }
          ],
          "item":{
             "createdBy":"[\"Brandon Wu\"]",
             "locale":"en_US",
             "author":"[\"Brandon Wu\"]",
             "dateUpdated":"2021-02-12T03:12:16+0000",
             "score":0.0,
             "name":"blog-posts#30-17777",
             "numImpressions":0,
             "dateCreated":"2020-12-09T21:27:28+0000",
             "additionalFields":{
                "featuredImageUrl":"https://d2908q01vomqb2.cloudfront.net/22d200f8670dbdb3e253a90eee5098477c95c23d/2020/12/08/Macie-Discover-Sensitive-Data-ForSocial-300x150.jpg",
                "postExcerpt":"Data is a crucial part of every business and is used for strategic decision making at all levels of an organization. To extract value from their data more quickly, Amazon Web Services (AWS) customers are building automated data pipelines—from data ingestion to transformation and analytics. As part of this process, my customers often ask how [...]\n",
                "createdDate":"2020-12-09T21:26:31Z",
                "displayDate":"09 Dec 2020",
                "link":"https://aws.amazon.com/blogs/security/use-macie-to-discover-sensitive-data-as-part-of-automated-data-pipelines/",
                "modifiedDate":"2020-12-09T21:26:31Z",
                "contributors":"Brandon Wu",
                "title":"Use Macie to discover sensitive data as part of automated data pipelines",
                "contentType":"Blog Post",
                "slug":"use-macie-to-discover-sensitive-data-as-part-of-automated-data-pipelines"
             },
             "id":"blog-posts#30-17777",
             "directoryId":"blog-posts",
             "lastUpdatedBy":"[\"Brandon Wu\"]"
          }
       },
       {
          "tags":[
             {
                "tagNamespaceId":"blog-posts#category-application-integration",
                "createdBy":"awsblog",
                "name":"amazon-eventbridge",
                "dateUpdated":"2020-05-12T00:32:17+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-08-13T18:29:26+0000",
                "description":"{\"name\":\"Amazon EventBridge\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-application-integration#amazon-eventbridge"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"amazon-guardduty",
                "dateUpdated":"2020-05-11T13:07:59+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-11-29T05:34:08+0000",
                "description":"{\"name\":\"Amazon GuardDuty\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#amazon-guardduty"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"security-identity-compliance",
                "dateUpdated":"2020-05-11T13:08:00+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:35:56+0000",
                "description":"{\"name\":\"Security, Identity, \u0026amp; Compliance\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#security-identity-compliance"
             },
             {
                "tagNamespaceId":"blog-posts#category-security-identity-compliance",
                "createdBy":"awsblog",
                "name":"amazon-macie",
                "dateUpdated":"2020-04-28T10:51:03+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:29:25+0000",
                "description":"{\"name\":\"Amazon Macie\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-security-identity-compliance#amazon-macie"
             },
             {
                "tagNamespaceId":"blog-posts#category-management-tools",
                "createdBy":"awsblog",
                "name":"aws-cloudtrail",
                "dateUpdated":"2020-05-11T14:19:43+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-23T23:28:05+0000",
                "description":"{\"name\":\"AWS CloudTrail\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-management-tools#aws-cloudtrail"
             },
             {
                "tagNamespaceId":"blog-posts#category-management-tools",
                "createdBy":"awsblog",
                "name":"aws-config",
                "dateUpdated":"2020-05-11T13:07:59+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:19+0000",
                "description":"{\"name\":\"AWS Config\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-management-tools#aws-config"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"management-tools",
                "dateUpdated":"2020-05-12T00:32:27+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:20+0000",
                "description":"{\"name\":\"Management Tools\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#management-tools"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"compute",
                "dateUpdated":"2020-05-12T12:57:31+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-16T02:43:27+0000",
                "description":"{\"name\":\"Compute\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#compute"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"learning-levels",
                "dateUpdated":"2020-05-12T00:37:10+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-18T12:38:28+0000",
                "description":"{\"name\":\"Learning Levels\",\"description\":\"Please use these to classify your technical posts. For descriptions, visit: https://w.amazon.com/bin/view/AWS_Blog/Expertise_Levels/\"}",
                "id":"blog-posts#category#learning-levels"
             },
             {
                "tagNamespaceId":"blog-posts#post-tags",
                "createdBy":"awsblog",
                "name":"customer-success",
                "dateUpdated":"2020-05-08T15:24:20+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2018-03-20T03:35:41+0000",
                "description":"{\"name\":\"Customer Success\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#post-tags#customer-success"
             },
             {
                "tagNamespaceId":"blog-posts#category-learning-levels",
                "createdBy":"awsblog",
                "name":"intermediate-200",
                "dateUpdated":"2019-03-18T12:38:29+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2019-03-18T12:38:29+0000",
                "description":"{\"name\":\"Intermediate (200)\",\"description\":\"Readers have used multiple AWS services from the console, API, or CLI (or all three).\"}",
                "id":"blog-posts#category-learning-levels#intermediate-200"
             },
             {
                "tagNamespaceId":"blog-posts#category",
                "createdBy":"awsblog",
                "name":"application-integration",
                "dateUpdated":"2020-05-12T00:32:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-12-06T00:45:12+0000",
                "description":"{\"name\":\"Application Integration\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category#application-integration"
             },
             {
                "tagNamespaceId":"blog-posts#category-compute",
                "createdBy":"awsblog",
                "name":"aws-lambda",
                "dateUpdated":"2020-05-11T14:19:43+0000",
                "locale":"en_US",
                "lastUpdatedBy":"awsblog",
                "dateCreated":"2017-08-17T03:36:34+0000",
                "description":"{\"name\":\"AWS Lambda\",\"description\":\"No Description Provided\"}",
                "id":"blog-posts#category-compute#aws-lambda"
             },
             {
                "tagNamespaceId":"GLOBAL#content-type",
                "createdBy":"jenbar",
                "name":"blog-post",
                "dateUpdated":"2020-04-29T05:19:18+0000",
                "locale":"en_US",
                "lastUpdatedBy":"jenbar",
                "dateCreated":"2020-04-29T05:19:18+0000",
                "description":"Blog Post",
                "id":"GLOBAL#content-type#blog-post"
             }
          ],
          "item":{
             "createdBy":"[\"Neel Sendas\"]",
             "locale":"en_US",
             "author":"[\"Neel Sendas\"]",
             "dateUpdated":"2021-02-12T00:14:56+0000",
             "score":0.0,
             "name":"blog-posts#35-13698",
             "numImpressions":0,
             "dateCreated":"2020-11-06T20:47:09+0000",
             "additionalFields":{
                "featuredImageUrl":"https://d2908q01vomqb2.cloudfront.net/972a67c48192728a34979d9a35164c1295401b71/2020/10/31/Picture4-3-300x171.png",
                "postExcerpt":"In this blog post, we describe how BBVA USA, a financial institution that ranks among the top 25 largest commercial banks, used AWS services to implement event management at scale and centralize its event response. Generally speaking, security compliance in a monolithic environment is easier to monitor and enforce when a small number of hands [...]\n",
                "createdDate":"2020-11-06T20:46:44Z",
                "displayDate":"06 Nov 2020",
                "link":"https://aws.amazon.com/blogs/mt/bbva-automated-responses-through-event-management-scale/",
                "modifiedDate":"2020-11-06T20:46:44Z",
                "contributors":"Neel Sendas",
                "title":"How BBVA automated responses through event management at scale",
                "contentType":"Blog Post",
                "slug":"bbva-automated-responses-through-event-management-scale"
             },
             "id":"blog-posts#35-13698",
             "directoryId":"blog-posts",
             "lastUpdatedBy":"[\"Neel Sendas\"]"
          }
       }
    ]
 }