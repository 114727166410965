import * as React from "react";
import { Link } from 'react-router-dom';

import { API, graphqlOperation } from "aws-amplify";

import SimpleTable from '../../common-components/SimpleTable.jsx';

import {
    // import the components that you use in the file here
    Button
  } from '../../aws-ui-components';

import { GetOrCreateUser } from '../../helpers/authHelpers'

import * as queries from '../../graphql/queries'


const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'id', label: 'Alias', editable: true },
        { id: 'level',label: 'Level', editable: true},
        { id: 'role', label: 'Role', editable: true},
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Direct Reports' },
    { value: 30, label: '30 Direct Reports' },
    { value: 50, label: '50 Direct Reports' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['id', 'name', 'level', 'role'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'id',
        header: 'Alias',
        cell: item => <Link to={{pathname: `/direct-report-overview/${item.id}`}}>{item.id}</Link>,
        minWidth: '200px',
        sortingField: 'id'
    },    
    {
        id: 'name',
        header: 'Name',
        cell: item => item.firstName + " " + item.lastName,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'level',
        header: 'Level',
        cell: item => item.level,
        minWidth: '200px',
        sortingField: 'level'
    },
    {
        id: 'role',
        header: 'Role',
        cell: item => item.role,
        minWidth: '200px',
        sortingField: 'role'
    }
];

class DirectReportsTable extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: null,
            directReports: []
        }
    }

    async componentDidMount() {
        const employee = await GetOrCreateUser()
        const directReports = await API.graphql(graphqlOperation(queries.listEmployeesByManagerId, {managerId: employee.id}))
        this.setState({user: employee, directReports: directReports.data.listEmployeesByManagerId.items})
    }

    actions = () => {
        return (
            <div></div>
        );
    }

    render() {
        const table_params = {
            dataItems: this.state.directReports,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerTitle: "My Direct Reports",
            headerActions: this.actions,
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            empty: "No direct reports found"
        }
    
        return (
            <div>
                <SimpleTable params={table_params}/>
            </div>
            
        );
    }
}

export default DirectReportsTable