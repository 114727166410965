import React from 'react';
import { Link } from 'react-router-dom';

import { API, graphqlOperation } from "aws-amplify";

import {
    // import the components that you use in the file here
    Container,
    Header,
    Box,
    Button,
    Input,
    Textarea,
    Multiselect,
    FormField,
    SpaceBetween,
    Form,
    Modal,
  } from '../../../aws-ui-components';

import SimpleTable from '../../../common-components/SimpleTable.jsx';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';

const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'id', label: 'id', editable: false },
        { id: 'description', label: 'description', editable: false },
        { id: 'manager', label: 'Manager', editable: false }
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Groups' },
    { value: 30, label: '30 Groups' },
    { value: 50, label: '50 Groups' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'manager'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => <Link to={{pathname: `/group-details/${item.id}`}}>{item.name}</Link>,
        minWidth: '200px',
        sortingField: 'name'
    },    
    {
        id: 'description',
        header: 'Description',
        cell: item => item.description,
        minWidth: '200px',
        sortingField: 'description'
    },    
    {
        id: 'manager',
        header: 'Group Manager',
        cell: item => item.groupManagerId,
        minWidth: '200px',
        sortingField: 'manager'
    }
];


class GroupSubGroupsSearchModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            group: this.props.group,
            groups: [],
            selectedGroups: [],
            modalVisible: false,
        }
    }
    
    async componentDidMount() {
        const main_group = this.state.group
        if (main_group === null) { return }
        const groupData = await API.graphql(graphqlOperation(queries.listGroups, { limit: 1000, filter: {id: {ne: main_group.id}} }))
        const groups = groupData.data.listGroups.items
        const cleanedGroups = groups.filter(subgroup => !main_group.subGroups.items.some(item => item.groupId === subgroup.id)) //remove any groups already subgroups
        this.setState({groups: cleanedGroups})
    }

    checkIfPartOfSubgroup(group, subgroups){
        return subgroups.some()
    }

    getDistributionsFromTable = (distributions) => {
        if (this.state.selectedGroups.length != distributions.length) {
            this.setState({selectedGroups: distributions})
        }
    }

    actions = (selectedDistributions) => {
        return <div></div>
    }

    updateGroup = async() => {
        const valid = this.validateFields()
        if (!valid) { return }
        const { group, name, description } = this.state
        await API.graphql(graphqlOperation(
            mutations.updateGroup, {
                input: {
                    id: group.id,
                    name: name,
                    description: description,
                }
            }
        ));
        this.setState({success: "Succesfully updated!"})
    }

    reset = async() => {
        this.setState(this.initialState)
    }

    onChange = (field, event) => {
        this.setState({[field]: event.detail.value})
    }

    validateFields = () => {
        const { name, description} = this.state
        if ([name, description].includes("")){
            this.setState({error: "Please fill in all values"})
            return false
        }
        else {
            this.setState({error: ""})
            return true
        }
    }

    onSubmit = async() => {
        const { selectedGroups, group } = this.state
        for (const subGroup of selectedGroups) {
            await API.graphql(graphqlOperation(mutations.createSubGroup, {input: {groupId: subGroup.id, parentGroupId: group.id}}))
        }
        await this.props.setGroupData()
    }

    render(){
        const table_params = {
            dataItems: this.state.groups,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerTitle: "Subgroups",
            headerActions: this.actions,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            enableSearch: true,
            filterLabel: "Find subgroup",
            filterPlaceholder: "Find subgroup",
            selection: "multi",
            callbackDistributions: this.getDistributionsFromTable,
            empty: "No subgroups attached"
        }
    
        return (
            <SpaceBetween direction="horizontal" size="s">
            <Button onClick={() => this.setState({modalVisible: true})} variant="primary" >
                Attach sub-groups
            </Button>
            <Modal
                onDismiss={() => this.setState({modalVisible: false})}
                visible={this.state.modalVisible}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <div>
                        <Box float="left">
                            <div style={{color: 'red'}}>{this.state.error}</div>
                        </Box>
                        <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => this.setState({modalVisible: false})} variant="link">Cancel</Button>
                            <Button variant="primary" onClick={this.onSubmit} disabled={this.state.selectedGroups.length === 0}>Attach selected groups</Button>
                        </SpaceBetween>
                        </Box>
                    </div>
                }
                header="Attach a new sub-group"
            >
                <SimpleTable params={table_params} />
            </Modal>
            </SpaceBetween>
        );
    }
}

export default GroupSubGroupsSearchModal