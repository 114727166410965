import * as React from "react";
import { Link } from 'react-router-dom';

import SimpleTable from '../../../common-components/SimpleTable.jsx';

import { GetOrCreateUser } from '../../../helpers/authHelpers'


import { getListLoiData } from './LoiHelper'
import { LEADERSHIPPRINCIPLES } from '../../../helpers/constants'


const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'startDate', label: 'Start date', editable: true },
        { id: 'endDate',label: 'End date', editable: true},
        { id: 'superPowers', label: 'Super powers', editable: true},
        { id: 'growthAreas', label: 'Growth areas', editable: true}
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Letter of Intents' },
    { value: 30, label: '30 Letter of Intents' },
    { value: 50, label: '50 Letter of Intents' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'startDate', 'endDate', 'superPowers', 'growthAreas'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => <Link to={{pathname: `/direct-report-letter-of-intent/${item.employeeId}/${item.id}`, state: {loi: item}}}>{item.name}</Link>,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'startDate',
        header: 'Start date',
        cell: item => new Date(item.startDate).toLocaleDateString('en-UK'),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'startDate'
    },
    {
        id: 'endDate',
        header: 'End date',
        cell: item => new Date(item.endDate).toLocaleDateString('en-UK'),
        minWidth: '100px',
        sortingField: 'endDate'
    },
    {
        id: 'superPowers',
        header: 'Super powers',
        cell: item => item.superPowers?.map(item => (<p key={item}>{LEADERSHIPPRINCIPLES[item]}</p>)),
        minWidth: '100px',
        sortingField: 'superPowers'
    },
    {
        id: 'growthAreas',
        header: 'Growth areas',
        cell: item => item.growthAreas?.map(item => (<p key={item}>{LEADERSHIPPRINCIPLES[item]}</p>)),
        minWidth: '100px',
        sortingField: 'growthAreas'
    }
];

class DirectReportLoITable extends React.Component {    
    constructor(props){
        super(props);
        this.state = {
            name: "",
            startDate: "",
            endDate: "",
            error: "",
            modalVisible: false,
            employee: null,
            loadingTable: true,
            lettersOfIntent: [],
            userId: this.props.userId
        };
    }

    componentDidMount = async() => {
        const employee = await GetOrCreateUser()
        const loiData = await getListLoiData(employee.id, this.state.userId)
        this.setState({employee: employee, lettersOfIntent: loiData, loadingTable: false})
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    actions = () => {
        return (
            <div></div>
        );
    }

    render() {
        const table_params = {
            dataItems: this.state.lettersOfIntent,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerActions: this.actions,
            headerTitle: "Direct Report: Letters of Intent",
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            loading: this.state.loadingTable,
            empty: "No letters of intent yet (they might still be in draft mode)"
        }
    
        return (
            <SimpleTable params={table_params}/>
        );
    }
}

export default DirectReportLoITable