import { API, graphqlOperation, Auth } from "aws-amplify";

import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'

// TODO: Add this code service as authentication trigger
export const GetOrCreateUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).catch(err => console.log(err));

    if (cognitoUser) {
        const username = cognitoUser.attributes.email.split('@')[0];
        const response = await API.graphql(graphqlOperation(
            queries.getEmployee, {
                id: username
            }
        ));
        const db_user = response.data.getEmployee
        if (!db_user) {
            const response = await API.graphql(graphqlOperation(
                mutations.createEmployee, {
                    input: {
                        email: cognitoUser.attributes.email,
                        id: username
                    }
                }
            ));
            const groupsJoined = await addAllowListedUser(username);
            return response.data.createEmployee
        } else {
            return db_user
        }
    }
    return null
}


export const getOrCreateUserFromSSO = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).catch(err => console.log(err));
    console.log("SSO data:", cognitoUser);

    if (cognitoUser) {
        const username = cognitoUser.attributes.email.split('@')[0];
        const response = await API.graphql(graphqlOperation(
            queries.getEmployee, {
                id: username
            }
        ));
        const db_user = response.data.getEmployee
        if (!db_user) {
            // User doesn't exist in DB, we need to create him and add him to any whitelisted groups.
            const user = await createOrUpdateDBUser(cognitoUser);
            const groupsJoined = await addAllowListedUser(username);
            return user;
        } else {
            // If user already exists, then just update his details from Cognito.
            const user = await updateDBUser(cognitoUser);
            return user;
        }
    } else {
        throw "No user found in Cognito identity pool."
    }
}


export const addAllowListedUser = async (username) => {
    // const username = cognitoUser.attributes.email.split("@")[0];
    const resp = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
        input: {
            requesterId: username,
            dataSubjectId: username,
            operation: 'addInviteesToGroups'
        }
    }))
    return resp;

};


export const createOrUpdateDBUser = async (cognitoUser) => {
    const username = cognitoUser.attributes.email.split("@")[0];
    const regEx = /\((.*?)\)/;
    const managerId = regEx.exec(cognitoUser.attributes["custom:MANAGER"])[1];

    const response = await API.graphql(graphqlOperation(
        mutations.createEmployee, {
            input: {
                firstName: cognitoUser.attributes.given_name,
                lastName: cognitoUser.attributes.family_name,
                email: cognitoUser.attributes.email,
                id: username,
                managerId: managerId,
                level: parseInt(cognitoUser.attributes["custom:AMZN_JOB_CODE"]),
                role: cognitoUser.attributes["custom:DESCRIPTION"]
            }
        }
    ));
    return response.data.createEmployee
}

export const updateDBUser = async (cognitoUser) => {
    const username = cognitoUser.attributes.email.split("@")[0];
    const regEx = /\((.*?)\)/;
    const managerId = regEx.exec(cognitoUser.attributes["custom:MANAGER"])[1];
    const response = await API.graphql(graphqlOperation(
        mutations.updateEmployee, {
            input: {
                firstName: cognitoUser.attributes.given_name,
                lastName: cognitoUser.attributes.family_name,
                email: cognitoUser.attributes.email,
                id: username,
                managerId: managerId,
                level: parseInt(cognitoUser.attributes["custom:AMZN_JOB_CODE"]),
                role: cognitoUser.attributes["custom:DESCRIPTION"]
            }
        }
    ));
    return response.data.updateEmployee
}

