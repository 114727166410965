export const DATA = 
{
   "items":[
      {
         "customer": "Netflix",
         "createdDate": "2020-05-11T19:02:49+0000",
         "shadowAliases": "stroppad",
         "summary": "Migration of Netflix from microservices to a mainframe. They wanted to go back to legacy",
         "notes": "They are looking to get rid of Lambdas, going back to mainframe. Daniele suggested <this> blogpost"
      },
      {
         "customer": "EasyJet",
         "createdDate": "2020-05-12T19:02:49+0000",
         "shadowAliases": "jeff, ajassy",
         "summary": "From flying through the clouds, to flying in the clouds. EasyJet want to stop commercial flying and become a cloud provider",
         "notes": "Jeff and Andy told EasyJet to not become a cloud provider, but they admired their Bias For Action"
      },
      {
         "customer": "PlantAI",
         "createdDate": "2020-05-13T19:02:49+0000",
         "shadowAliases": "lylrober",
         "summary": "First call with PlantAI to move to the cloud",
         "notes": "They are are heavily invested in IOT devices, so Rob suggested using IOT Core"
      }
   ]
}