/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

 https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import {
  // import the components that you use in the file here
  AppLayout,
  BreadcrumbGroup,
  SpaceBetween,
} from '../../../aws-ui-components';

import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../../graphql/queries'

import ServiceNavigation from '../../../common-components/ServiceNavigation';

import { GetOrCreateUser } from '../../../helpers/authHelpers'
import { getGoalsWithProgressFromLoI } from '../../../helpers/goalHelpers'
import { getListLoiData } from './LoiHelper'

import DirectReportLoIItem from './DirectReportLoiItem';

class DirectReportLoIView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loiId: this.props.match.params.id,
      directReportId: this.props.match.params.employeeId,
      loi: this.props.location.state?.loi || null,
      loading: true,
      employee: null,
      goals: []
    }
  }

  componentDidMount = async () => {
    // If user gets here by just entering link, can't rely on passed on data through state
    const employee = await GetOrCreateUser()

    if (this.state.loi === null || this.state.goals.length === 0) {
      const loiData = await getListLoiData(employee.id, this.state.directReportId)
      const loi = loiData.find(item => item.id === this.state.loiId)
      const achievements = await this.getAchievements(employee.id, this.state.directReportId)
      const allGoals = await getGoalsWithProgressFromLoI(loi, achievements)
      this.setState({ loi: loi, goals: allGoals, loading: false, employee: employee})
    }
  }

  getAchievements = async (employeeId, directReportId) => {
    const response = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
        requesterId: employeeId,
        dataSubjectId: directReportId,
        operation: 'getAchievementDataAsManager'
      }
    }))
    const responseObj = response.data.getDataWithAuthorisationFunction
    var achievementData = []
    if (responseObj.status === "SUCCESS") {
      achievementData = JSON.parse(responseObj.queryReturn)
    }
    if (achievementData.length > 0) {
      achievementData = achievementData.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    return achievementData
  }

  render() {
    const Breadcrumbs = () => (
      <BreadcrumbGroup
        items={[
          {
            text: 'FAST',
            href: '#/'
          },
          {
            text: 'Manager overview',
            href: '#/manager-overview'
          },
          {
            text: 'Direct Report Overview',
            href: `#/direct-report-overview/${this.state.directReportId}`
          },
          {
            text: 'Letter of intent',
            href: `#/direct-report-letter-of-intent/${this.state.loiId}`
          }
        ]}
      />
    );

    const Content = () => (
      <SpaceBetween direction="vertical" size="xl">
        {
          this.state.loi && <DirectReportLoIItem 
            goals={this.state.goals}
            directReportId={this.state.directReportId}
            employee={this.state.employee}
            loi={this.state.loi}
          />
        }
      </SpaceBetween>
    )
    return (
      <AppLayout
        breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
        content={<Content />} // Main content on the page, defined below
        contentType="default" // Sets default app layout settings for widths
        toolsHide={true}
        navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
        navigationOpen={false}
      />
    );
  }
}

// Breadcrumb content


export default DirectReportLoIView
