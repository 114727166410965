import React from 'react';
import { Link } from 'react-router-dom';

import SimpleTable from '../../../../common-components/SimpleTable.jsx';
import { displayPhoneToolLink } from "../../../../helpers/utils";

// import GroupSubGroupsSearchModal from './GroupSubGroupsSearchModal'

const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'id', label: 'id', editable: false },
        { id: 'description', label: 'description', editable: false },
        { id: 'manager', label: 'Manager', editable: false }
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Groups' },
    { value: 30, label: '30 Groups' },
    { value: 50, label: '50 Groups' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'manager', 'actions'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => <Link to={{pathname: `/group-details/${item.group.id}`}}>{item.group.name}</Link>,
        minWidth: '200px',
        sortingField: 'name',
    },    
    {
        id: 'description',
        header: 'Description',
        cell: item => item.group.description,
        minWidth: '200px',
        sortingField: 'description'
    },    
    {
        id: 'manager',
        header: 'Group Manager',
        cell: item => displayPhoneToolLink(item.group.groupManagerId),
        minWidth: '200px',
        sortingField: 'manager'
    },
];


class ViewGroupSubGroups extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            group: this.props.group,
            name: this.props.group?.name,
            subGroups: this.props.group?.subGroups?.items || [],
            // subgroups: this.props.group?.subgroups.items,
            success: ""
        }
        this.initialState = this.state
    }

    actions = () => { return <div></div>}
    render(){
        const table_params = {
            dataItems: this.state.subGroups,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerTitle: "Subgroups",
            headerActions: this.actions,
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            loading: this.props.loading,
            empty: "No Subgroups attached to this group"
        }
    
        return (
            <SimpleTable params={table_params}/>
        );
    }
}

export default ViewGroupSubGroups