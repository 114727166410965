import * as React from "react";
import { Link } from 'react-router-dom';

import { API, graphqlOperation } from "aws-amplify";

import { SpaceBetween, Button, Modal, Box, Form, Input, Multiselect, DatePicker, FormField, RadioGroup, Checkbox, ProgressBar} from '../../../aws-ui-components';
import SimpleTable from '../../../common-components/SimpleTable.jsx';
import LargeTextWrapper from '../../../common-components/LargeTextWrapper.jsx';

import { GetOrCreateUser } from '../../../helpers/authHelpers'

import * as mutations from '../../../graphql/mutations'
import * as queries from '../../../graphql/queries'

import { LEADERSHIPPRINCIPLES, ROLEGUIDELINES, GOALTYPES} from '../../../helpers/constants'

import MDEditor from '@uiw/react-md-editor';


const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'description', label: 'Description', editable: true },
        { id: 'leadershipPrinciples',label: 'LPs', editable: true},
        { id: 'roleGuidelines',label: 'roleGuidelines', editable: true},
        { id: 'amount', label: 'Amount', editable: true},
        { id: 'startDate', label: 'Start Date', editable: true},
        { id: 'endDate', label: 'End Date', editable: true},
        { id: 'loiAmount', label: 'Total amount in LoIs', editable: true},
        { id: 'progress', label: 'Progress', editable: true},
        { id: 'goalType', label: 'Goal Type', editable: true},
        { id: 'mandatory', label: 'Mandatory', editable: true},
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 goals' },
    { value: 30, label: '30 goals' },
    { value: 50, label: '50 goals' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'leadershipPrinciples', 'roleGuidelines', 'startDate', 'endDate', 'amount', 'goalType', 'loiAmount', 'progress', 'mandatory'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => `${item.draft === true ? "(DRAFT) - " + item.name : item.name}`,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'description',
        header: 'Description',
        cell: item => <LargeTextWrapper full_text={item.description} length={200}/>,
        minWidth: '200px',
        sortingField: 'description'
    },
    {
        id: 'leadershipPrinciples',
        header: 'LPs',
        cell: item => item.leadershipPrinciples?.map(lp => (<p key={lp}>{LEADERSHIPPRINCIPLES[lp]}</p>)),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'leadershipPrinciples'
    },
    {
        id: 'roleGuidelines',
        header: 'Role Guidelines',
        cell: item => item.roleGuidelines?.map(rg => (<p key={rg}>{ROLEGUIDELINES[rg]['title']}</p>)),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'roleGuidelines'
    },
    {
        id: 'startDate',
        header: 'Start Date',
        cell: item => item.startDate ? new Date(item.startDate).toLocaleDateString('en-UK') : null,
        minWidth: '100px',
        sortingField: 'startDate'
    },    
    {
        id: 'endDate',
        header: 'End Date',
        cell: item => new Date(item.endDate).toLocaleDateString('en-UK'),
        minWidth: '100px',
        sortingField: 'endDate'
    },    
    {
        id: 'goalType',
        header: 'Type',
        cell: item => item.goalType,
        minWidth: '100px',
        sortingField: 'goalType'
    },
    {
        id: 'amount',
        header: 'Amount',
        cell: item => item.amount,
        minWidth: '50px',
        sortingField: 'amount'
    },
    {
        id: 'loiAmount',
        header: 'Total amount in LoIs',
        cell: item => `${item.provisioned_amount} by ${item.nr_of_employees} people`,
        minWidth: '100px',
        sortingField: 'loiAmount'
    },
    {
        id: 'mandatory',
        header: 'Mandatory',
        cell: item => String(item.mandatory),
        minWidth: '20px',
        sortingField: 'mandatory'
    },
    {
        id: 'progress',
        header: 'Progress',
        cell: item => progress_content(item),
        minWidth: '50px',
        sortingField: 'progress'
    }
];

const progress_content = (item) => {
    const status = item.raw_amount > item.progress ? 'in-progress' : 'success'
    return (
        <ProgressBar
            status={status}
            value={item.progress / item.raw_amount * 100}
            additionalInfo={`Completed ${item.progress} out of ${item.raw_amount} (goal amount)`}
        />
    )   
}

class GroupGoalTable extends React.Component {
    constructor(props) {
        super(props);
        const goalsList = this.props.group_goals || []
        const activeGoals = goalsList.filter(item => item.deactivated !== true)
        this.state = {
            group: this.props.group || null,
            goals: activeGoals,
            modalVisible: false,
            editModalVisible: false,
            editingGoal: null,
            employee: null,
            loading: false,

            // Form values:
            name: "",
            description: "",
            leadershipPrinciples: [],
            roleGuidelines: [],
            amount: null,
            startDate: "",
            endDate: "",
            goalType: "",
            mandatory: false,
        };
    }

    componentDidMount = async() => {
        await this.flushState()
    }

    flushState = async () => {
        this.setState(this.initial_state)
        const employee = await GetOrCreateUser()
        this.setState({employee: employee})
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    addToLoi = async(goalItem) => {
        var confirmed = false
        if (goalItem.mandatory === true) {
            confirmed = confirm("Are you sure you want to publish this goal? It will become visible to anyone in this group, and it will be automatically added to all applicable Letters of Intent of the members in this group. They will also get an email notification.")
        } else {
            confirmed = confirm("Are you sure you want to publish this goal? It will become visible to anyone in this group. People can add it as an optional goal in their Letter of Intent. They will get an email notification about this as well.")
        }
        if (!confirmed) { return }

        this.setState({loading: true})
        
        const updateGoal = async (goalId) => {
            await API.graphql(graphqlOperation(
                mutations.updateGoal, {
                    input: {
                        id: goalId,
                        draft: false
                    }
                }
            ));
        }

        if (goalItem.mandatory === true) {
            const resultData = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
                input: {
                    requesterId: this.state.employee.id,
                    dataSubjectId: "",
                    operation: 'addGroupGoal',
                    params: JSON.stringify({ groupId: goalItem.group.id, goalId: goalItem.id})
                }
            }))
            const result = resultData.data.getDataWithAuthorisationFunction
            if (result.status === "ERROR") {
                alert(`Something went wrong ... ${result.message}`)
            } else {
                const returnValue = JSON.parse(result.queryReturn)
                if (returnValue.length > 0) {
                    alert(`Done ... added goal to the Letters of Intent of ${returnValue.map(item => item.letterOfIntent.employeeId).join(", ")}`)
                } else {
                    alert("Done, but there were no Letters of Intent existing to add it to")
                }
                await updateGoal(goalItem.id)
            }
        } else {
            await updateGoal(goalItem.id)
            alert("Published optional goal, employees should see it if they go to their Letter of Intent")
        }
        this.props.setGroupData()
        this.setState({loading: false})
    }

    deleteGoal = async (goal_id_to_delete, draft) => {
        if (draft) {
            const confirmed = confirm("Are you sure you want to delete this goal?")
            if (!confirmed) { return}
        } else {
            const confirmed = confirm("Are you sure you want to delete this goal? This will also delete the goal from the Letters of Intent of people in this group")
            if (!confirmed) { return }
            const confirmed2 = confirm("Are you really really sure? Again, this will affect the letters of intent of everyone in (sub)groups...")
            if (!confirmed2) { return }
        }

        const goalObject = await API.graphql(graphqlOperation(queries.getGoal, { id: goal_id_to_delete}))
        const name = goalObject.data.getGoal.name

        await API.graphql(graphqlOperation(mutations.updateGoal, {
            input: {
                id: goal_id_to_delete,
                name: "(DELETED FROM GROUP) ".concat(name),
                deactivated: true
            }
        }))
        this.props.setGroupData()
    }

    onChange = (field, event) => {
        this.setState({[field]: event.detail.value})
    }

    validateFields = () => {
        const { name, description, amount, endDate, leadershipPrinciples, goalType } = this.state
        if ([name, description, amount, endDate, leadershipPrinciples, goalType].includes("")){
            this.setState({error: "Please fill in all values"})
            return false
        }
        else {
            this.setState({error: ""})
            return true
        }
    }

    formatRoleGuidelines = (roleGuidelines) => {
        const roleGuidelineDict = Object.entries(ROLEGUIDELINES).map(item => ({
            label: item[1]['title'],
            description: item[1]['description'],
            value: item[0]
        }))
        return roleGuidelineDict.filter(obj => roleGuidelines?.find(sp => sp === obj.value))
    }

    onSubmit = async () => {
        this.setState({loading: true})
        const valid = this.validateFields()
        if (!valid) { this.setState({loading: false}); return }
        const { name, group, description, amount, startDate, endDate, leadershipPrinciples, roleGuidelines, goalType, mandatory } = this.state
        const cleanedStartDate = startDate !== "" ? startDate : null
        const createGoal = await API.graphql(graphqlOperation(
            mutations.createGoal, {
                input: {
                    name: name,
                    description: description,
                    amount: parseInt(amount),
                    startDate: cleanedStartDate,
                    endDate: endDate,
                    goalType: goalType,
                    employeeId: this.state.employee.id,
                    leadershipPrinciples: leadershipPrinciples.map(a => a.value),
                    roleGuidelines: roleGuidelines.map(a => a.value),
                    mandatory: mandatory,
                    groupId: group.id,
                    draft: true
                }
            }
        ));
        if (createGoal.data){
            this.setState({modalVisible: false})
            this.props.setGroupData()
        }
        this.setState({loading: false})
    }

    setStateFromDB = async(goalItem) => {
        // Set any value in state which we can get from the letter object
        var stateUpdate = {}
        if (goalItem) {
            for (const [key, value] of Object.entries(goalItem)) {
                stateUpdate[key] = value
            }
            const leadershipPrinciples = Object.entries(LEADERSHIPPRINCIPLES).map(item => ({label: item[1], value: item[0]}))
            stateUpdate.leadershipPrinciples = leadershipPrinciples.filter(obj => goalItem.leadershipPrinciples?.find(sp => sp === obj.value))
            stateUpdate.roleGuidelines = this.formatRoleGuidelines(goalItem.roleGuidelines)
        }
        this.setState(stateUpdate)
    }

    updateGoal = async() => {
        const { name, group, description, amount, startDate, endDate, leadershipPrinciples, roleGuidelines, goalType, editingGoal, mandatory} = this.state
        if ( editingGoal ){
            const cleanedStartDate = startDate !== "" ? startDate : null
            await API.graphql(graphqlOperation(
                mutations.updateGoal, {
                    input: {
                        id: editingGoal.id,
                        name: name,
                        description: description,
                        amount: parseInt(amount),
                        startDate: cleanedStartDate,
                        endDate: endDate,
                        goalType: goalType,
                        employeeId: this.state.employee.id,
                        leadershipPrinciples: leadershipPrinciples.map(a => a.value),
                        roleGuidelines: roleGuidelines.map(a => a.value),
                        mandatory: mandatory,
                        groupId: group.id
                    }
                }
            ));
            this.flushState()
        }
        else {
            alert("Not editing a goal")
        }
        this.setState({editModalVisible: false})
        this.props.setGroupData()
    }

    editGoal = async(goal_id) => {
        const goal_item = this.state.goals.find(item => item.id === goal_id)
        await this.setStateFromDB(goal_item)
        this.setState({
            editModalVisible: true, 
            editingGoal: goal_item, 
        })
    }

    actions = (selectedDistributions) => {
        const isOnlyOneSelected = selectedDistributions.length === 1;
        const modalForm = (
            <Form>
                <SpaceBetween direction="vertical" size="m">
                    <FormField label="Name">
                        <Input 
                            onChange={(event) => this.onChange('name', event)} 
                            value={this.state.name}
                            disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                    <FormField label="Description">
                        <MDEditor
                            value={this.state.description}
                            onChange={(event) => this.setState({description: event})}
                        />
                    </FormField>
                    <FormField label="Amount">
                        <Input 
                            type="number" 
                            onChange={(event) => this.onChange('amount', event)} 
                            value={this.state.amount} 
                            disabled={(this.state.editingGroupGoal && !this.state.editingCollectiveGroupGoal)} // I can edit amount if personal and if it's a collective group goal
                        />
                    </FormField>
                    <FormField label="Goal type">
                        <RadioGroup value={this.state.goalType} items={GOALTYPES} onChange={(event) => this.onChange('goalType', event)}/>
                    </FormField>
                    <FormField label="Start date" description="(Optional) Indicate when the group should start working on this goal">
                        <DatePicker
                                onChange={(event) => this.onChange('startDate', event)}
                                value={this.state.startDate || ""}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                                disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                    <FormField label="End date" description="Required: indicate when you expect this goal to be completed">
                        <DatePicker
                                onChange={(event) => this.onChange('endDate', event)}
                                value={this.state.endDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                                disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                    <FormField label="Leadership Principles" description="(Optional) Link this goal to one or more LPs">
                        <Multiselect
                            selectedOptions={this.state.leadershipPrinciples}
                            onChange={({ detail }) =>
                                this.setState({leadershipPrinciples: detail.selectedOptions})
                            }
                            deselectAriaLabel={e => "Remove " + e.label}
                            options={Object.entries(LEADERSHIPPRINCIPLES).map(item => ({label: item[1], value: item[0]}))}
                            placeholder="Choose options"
                            selectedAriaLabel="Selected"
                            disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                    <FormField label="Role Guidelines dimensions" description="(Optional) Link this goal to one or more Role Guidelines dimensions"> 
                        <Multiselect 
                            selectedOptions={this.state.roleGuidelines}
                            onChange={({detail}) =>
                                this.setState({roleGuidelines: detail.selectedOptions})
                            }
                            deselectAriaLabel={e => "Remove " + e.label}
                            options={Object.entries(ROLEGUIDELINES).map(item => ({
                                label: item[1]['title'],
                                description: item[1]['description'],
                                value: item[0]
                            }))}
                            placeholder="Choose options"
                            selectedAriaLabel="Selected"
                            disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                    <FormField label="Goal Mandatory" description="Select if group goal is mandatory. Mandatory goals will automatically be added to Letters of Intent of Group Members. Non-mandatory goals can be optionnally added to LoIs by members.">
                        <Checkbox
                            onChange={({ detail }) =>
                                this.setState({mandatory: detail.checked})
                            }
                            checked={this.state.mandatory}
                        >
                            Mandatory
                        </Checkbox>
                    </FormField>
                </SpaceBetween>
            </Form>
        )


        return (
            <SpaceBetween direction="horizontal" size="s">
                <Button disabled={!isOnlyOneSelected} onClick={() => this.editGoal(selectedDistributions[0]?.id)}> Edit</Button>
                <Button 
                    disabled={selectedDistributions.length !== 1 }
                    onClick={() => this.deleteGoal(selectedDistributions[0]?.id, selectedDistributions[0]?.draft)}
                > 
                    Delete
                </Button>
                <Button 
                    disabled={selectedDistributions.length !== 1 }
                    onClick={() => this.addToLoi(selectedDistributions[0])}
                    loading={this.state.loading}
                >
                    Publish (Add goal to members' LoI) 
                </Button>
                <Button onClick={() => {this.flushState(); this.setState({modalVisible: true})}} variant="primary" >
                    Create new goal
                </Button>
                <Modal
                    onDismiss={() => this.setState({modalVisible: false})}
                    visible={this.state.modalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => this.setState({modalVisible: false})} variant="link">Cancel</Button>
                                <Button variant="primary" loading={this.state.loading} onClick={this.onSubmit}>Submit</Button>
                            </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Create a new group goal"
                >
                    {modalForm}
                </Modal>
                <Modal
                    onDismiss={() => this.setState({editModalVisible: false})}
                    visible={this.state.editModalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => this.setState({editModalVisible: false})} variant="link">Cancel</Button>
                                <Button variant="primary" onClick={this.updateGoal}>Submit</Button>
                            </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Edit goal"
                >
                    {modalForm}
                </Modal>
            </SpaceBetween>
        );
    }
    render() {
        const table_params = {
            dataItems: this.state.goals,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerActions: this.actions,
            headerTitle: "Group goals",
            enableSearch: true,
            filterLabel: "Find goal",
            filterPlaceholder: "Find goal",
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            selection: "single",
            loading: this.props.loading,
            empty: "Group has no goals yet"
        }
        return (
            <SimpleTable key={this.state.updatedKey} params={table_params}/> // Adding key is a hacky solution to re-render the table when a goal has been edited (this caused problems with selectedDistributions)
        );
    }
}

export default GroupGoalTable