import React, { useState, useEffect } from 'react';

import { Modal, Box, SpaceBetween, Button} from '../aws-ui-components';

import ReactMarkdown from 'react-markdown'

const LargeTextWrapper = (props) => {
    const full_text = props.full_text
    const length = props.length
    const textTooLong = full_text.length > length
    const [hideText, setHideText] = useState(true)

    const FullTextModal = () => {
        return (
            <Modal
                onDismiss={() => setHideText(true)}
                visible={!hideText}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <div>
                        <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setHideText(true)} variant="link">Cancel</Button>
                        </SpaceBetween>
                        </Box>
                    </div>
                }
                header="Full text"
            >
                <ReactMarkdown>{full_text}</ReactMarkdown>
            </Modal>
        )
    }

    if (!textTooLong) {
        return (
            <div>
                <ReactMarkdown>{full_text}</ReactMarkdown>
            </div>
        )
    }

    return (
        <div>
            <FullTextModal />
            <ReactMarkdown>{String(full_text.slice(0, length)).concat("...")}</ReactMarkdown>
            <a onClick={() => setHideText(false)} style={{color: "text-label"}}> Show full text </a>
        </div>
    )
}

export default LargeTextWrapper