import * as React from "react";

import SimpleTable from '../../common-components/SimpleTable.jsx';

import {ProgressBar} from '../../aws-ui-components';

import { formatLoiGoalsNicely } from '../../helpers/goalHelpers'

import LargeTextWrapper from '../../common-components/LargeTextWrapper.jsx';

import { ROLEGUIDELINES } from '../../helpers/constants';


const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'description', label: 'Description', editable: true },
        { id: 'startDate', label: 'Start Date', editable: true },
        { id: 'endDate', label: 'End Date', editable: true },
        { id: 'group', label: 'Group', editable: true },
        { id: 'amount', label: 'amount', editable: true },
        { id: 'roleGuidelines', label: 'Role Guidelines', editable: true },
        { id: 'progress', label: 'progress', editable: true },
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 goals' },
    { value: 30, label: '30 goals' },
    { value: 50, label: '50 goals' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'startDate', 'endDate', 'roleGuidelines', 'group', 'amount', 'progress'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'description',
        header: 'Description',
        cell: item => <LargeTextWrapper full_text={item.description} length={200}/>,
        minWidth: '200px',
        sortingField: 'description'
    },
    {
        id: 'startDate',
        header: 'Start Date',
        cell: item => item.startDate ? new Date(item.startDate).toLocaleDateString('en-UK') : null,
        minWidth: '100px',
        sortingField: 'startDate'
    },    
    {
        id: 'endDate',
        header: 'End Date',
        cell: item => new Date(item.endDate).toLocaleDateString('en-UK'),
        minWidth: '100px',
        sortingField: 'endDate'
    },    
    {
        id: 'group',
        header: 'Group',
        cell: item => item.group?.name,
        minWidth: '100px',
        sortingField: 'group'
    },
    {
        id: 'amount',
        header: 'Amount',
        cell: item => item.amount,
        minWidth: '50px',
        sortingField: 'amount'
    },
    {
        id: 'roleGuidelines',
        header: 'Role Guidelines',
        cell: item => item.roleGuidelines?.map(rg => (<p key={rg}>{ROLEGUIDELINES[rg]['title']}</p>)),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'roleGuidelines'
    },
    {
        id: 'progress',
        header: 'Progress',
        cell: item => progress_content(item),
        minWidth: '50px',
        sortingField: 'progress'
    },
];

const progress_content = (item) => {
    const status = item.raw_amount > item.progress ? 'in-progress' : 'success'
    return (
        <ProgressBar
            status={status}
            value={item.progress / item.raw_amount * 100}
            additionalInfo={`Completed ${item.progress} out of ${item.raw_amount}`}
        />
    )   
}

class GoalProgressTable extends React.Component {
    actions = () => {
        return (
            <div></div>
        );
    }

    render() {
        const table_params = {
            dataItems: formatLoiGoalsNicely(this.props.goals),
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerActions: this.actions,
            headerTitle: "Goal progress",
            enableSearch: true,
            filterLabel: "Find goal",
            filterPlaceholder: "Find goal",
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            booleanFilterField: "active",
            booleanFilterFieldLabel: "Show all goals - including historic",
            booleanFilterFieldInitialValue: false,
            booleanFilterInvert: true,
            loading: this.props.loading,
            empty: "No goals found"
        }
        return (
            <SimpleTable params={table_params}/> // Adding key is a hacky solution to re-render the table when a goal has been edited (this caused problems with selectedDistributions)
        );
    }
}
export default GoalProgressTable