/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines: 

https://polaris.corp.amazon.com/getting_started/development/integration/
************************************************************************/
import React from 'react';

import Amplify from "aws-amplify";
import aws_exports from '../aws-exports';
Amplify.configure(aws_exports);

import '@amzn/awsui-global-styles/polaris.css';

import AuthStateApp from './AmplifyAuthenticator'

// Class App is the "output" generated on every build,
// it is what you will see on the webpage.
export default class App extends React.Component {
  render() {
    return (
        // When you create a new file or template, add it below
        // as a new 'Route' so you can link to it with a url.
        <AuthStateApp/>
    );
  }
}
