import * as React from "react";
import { Link } from 'react-router-dom';

import { API, graphqlOperation } from "aws-amplify";

import SimpleTable from '../../common-components/SimpleTable.jsx';
import { Box, Button } from '../../aws-ui-components';

import * as mutations from '../../graphql/mutations'


const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'id', label: 'id', editable: false },
        { id: 'description', label: 'description', editable: false },
        { id: 'manager', label: 'Manager', editable: false }
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Groups' },
    { value: 30, label: '30 Groups' },
    { value: 50, label: '50 Groups' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'manager', 'actions'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
        minWidth: '200px',
        sortingField: 'name'
    },    
    {
        id: 'description',
        header: 'Description',
        cell: item => item.description,
        minWidth: '200px',
        sortingField: 'description'
    },    
    {
        id: 'manager',
        header: 'Group Manager',
        cell: item => item.groupManagerId,
        minWidth: '200px',
        sortingField: 'manager'
    },    
    {
        id: 'actions',
        header: 'Actions',
        cell: item => <div><Link to={{pathname: `/group-details/${item.id}`}}><Button>View</Button></Link></div>,
        minWidth: '200px',
        sortingField: 'id'
    }
];

class ParticipatingGroupsTable extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            name: "",
            description: ""
        }
    }

    validateFields = () => {
        const { name, description } = this.state
        if ([name, description].includes("")){
            this.setState({error: "Please fill in all values"})
            return false
        }
        else {
            this.setState({error: ""})
            return true
        }
    }

    onChange = (field, event) => {
        this.setState({[field]: event.detail.value})
    }

    onSubmit = async () => {
        const valid = this.validateFields()
        if (!valid) { return }
        const { name, description } = this.state
        const createGroup = await API.graphql(graphqlOperation(
            mutations.createGroup, {
                input: {
                    name: name,
                    description: description,
                    groupManagerId: this.props.employee.id
                }
            }
        ));
        if (createGroup.data){
            this.setState({modalVisible: false})
            this.props.updateState()
        }
    }

    actions = (selectedDistributions) => {
        return <div></div>
    }
    render() {
        const empty = (
            <Box textAlign="center" color="inherit">
                <b>No groups</b>
                <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
                >
                    No groups to display.
                </Box>
            </Box>
        )

        const table_params = {
            dataItems: this.props.groups,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerTitle: "Groups I am member of",
            headerActions: this.actions,
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            empty: empty,
        }
    
        return (
            <SimpleTable params={table_params}/>
        );
    }
}

export default ParticipatingGroupsTable