/************************************************************************
 DISCLAIMER

 This is just a playground package. It does not comply with best practices
 of using AWS-UI components. For production code, follow the integration
 guidelines:

 https://polaris.a2z.com/develop/integration/react/
 ************************************************************************/
import React from 'react';
import {
    // import the components that you use in the file here
    AppLayout,
    BreadcrumbGroup,
    Tabs
} from '../../../aws-ui-components';

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries'

import ServiceNavigation from '../../../common-components/ServiceNavigation';

import GroupDescription from './GroupDescription'
import GroupSubGroups from './GroupSubGroups'
import GroupMembers from './GroupMembers'
import GroupGoalTable from './GroupGoals'

import ViewGroupDescription from './view-group-details/ViewGroupDescription'
import ViewGroupGoalTable from './view-group-details/ViewGroupGoals'
import ViewGroupMembers from './view-group-details/ViewGroupMembers'
import ViewGroupSubGroups from './view-group-details/ViewGroupSubGroups'
import { formatGroupGoalsNicely } from '../../../helpers/goalHelpers';
import {GetOrCreateUser} from "../../../helpers/authHelpers";


// import LetterOfIntentForm from './LetterOfIntentForm'
// import SimpleGoalTable from './GoalsTable'

class GroupDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            group: null,
            subscription: null,
            group_goals: [],
            all_group_goals: [],
            loading: false,
            employee: null,
            tabId: 1
        }
    }

    async componentDidMount() {
        await this.setGroupData()
    }

    setGroupData = async() => {
      this.setState({loading: true})
      const groupData = await API.graphql(graphqlOperation(queries.getGroup, {id: this.state.id}))
      const employee = await GetOrCreateUser();
      const groupGoals = await formatGroupGoalsNicely(groupData.data.getGroup.goals?.items, employee.id)
      const groupGoalsExcludingDraft = groupGoals.filter(item => item.draft !== true)
      this.setState({group: groupData.data.getGroup, group_goals: groupGoalsExcludingDraft, all_group_goals: groupGoals, loading: false, employee: employee})
    }


    async componentWillReceiveProps(nextProps) { // TODO: THIS IS BEING DEPRECATED
        this.setState({id: nextProps.match.params.id}, function() { this.setGroupData() }.bind(this)) // This is necessary to make sure we update after state has been set
    }

    render() {
        const Breadcrumbs = () => (
            <BreadcrumbGroup
                items={[
                    {
                        text: 'FAST',
                        href: '#/'
                    },
                    {
                        text: 'Groups',
                        href: '#/groups'
                    },
                    {
                        text: 'Group',
                        href: `#/edit-group/${this.state.id}`
                    }
                ]}
            />
        );

        // Main content area (fill it in with components!)
        // TODO: replace ID with object to save queries
        const Content = () => {
            if (!this.props.viewOnly) {
                return (
                    <Tabs
                        tabs={[
                        {
                            label: "Edit Group Details",
                            id: 1,
                            content: <GroupDescription loading={this.state.loading} group={this.state.group} setGroupData={this.setGroupData} employee={this.state.employee}/>,
                        },
                        {
                            label: "Edit Subgroups",
                            id: 2,
                            content: <GroupSubGroups loading={this.state.loading} group={this.state.group} setGroupData={this.setGroupData} />
                        },
                        {
                            label: "Edit Members",
                            id: 3,
                            content: <GroupMembers loading={this.state.loading} group={this.state.group} setGroupData={this.setGroupData} />,
                        },
                        {
                            label: "Edit Group Goals",
                            id: 4,
                            content: <GroupGoalTable loading={this.state.loading} group={this.state.group} setGroupData={this.setGroupData} group_goals={this.state.all_group_goals} />,
                        },
                        ]}
                        activeTabId={this.state.tabId}
                        onChange={({detail}) => {if (!this.state.loading) this.setState({tabId: detail.activeTabId})}}
                    />
                )
            }
            return (
                <Tabs
                    tabs={[
                    {
                        label: "Group Details",
                        id: 1,
                        content: <ViewGroupDescription group={this.state.group} loading={this.state.loading}/>,
                    },
                    {
                        label: "Subgroups",
                        id: 2,
                        content: <ViewGroupSubGroups group={this.state.group} loading={this.state.loading}/>
                    },
                    {
                        label: "Members",
                        id: 3,
                        content: <ViewGroupMembers group={this.state.group} loading={this.state.loading}/>,
                    },
                    {
                        label: "Group Goals",
                        id: 4,
                        content: <ViewGroupGoalTable group={this.state.group} loading={this.state.loading} group_goals={this.state.group_goals}/>,
                    },
                    ]}
                    activeTabId={this.state.tabId}
                    onChange={({detail}) => {if (!this.state.loading) this.setState({tabId: detail.activeTabId})}}
                />
            )
        }
        return (
            <AppLayout
                navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
                breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
                content={<Content />} // Main content on the page, defined below
                contentType="default" // Sets default app layout settings for widths
                navigationHide={false}
                navigationOpen={false}
                toolsHide={true}
            />
        );
    }
}

// Breadcrumb content


export default GroupDetails
