import React from 'react';
import { API, graphqlOperation } from "aws-amplify";

import {
    // import the components that you use in the file here
    Container,
    Header,
    Box,
    Button,
    Input,
    Textarea,
    Multiselect,
    FormField,
    SpaceBetween,
    Form,
  } from '../../../../aws-ui-components';

import * as mutations from '../../../../graphql/mutations';

import { displayPhoneToolLink } from "../../../../helpers/utils";



class ViewGroupDescription extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            group: this.props.group,
            name: this.props.group?.name,
            description: this.props.group?.description,
            additionalGroupManagers: this.props.group?.additionalGroupManagers.items || []
        }
    }
    render(){
        return (
            <Container
                header={
                <Header variant="h1">
                    Group Details
                </Header>
                }
            >
                <p><b>Name: </b> {this.props.loading ? "Loading..." : this.state.name} </p>
                <p><b>Description: </b> {this.props.loading ? "Loading..." : this.state.description} </p>
                <p><b>Additional Managers: </b>{this.props.loading ? "Loading..." : this.state.additionalGroupManagers.map(item => item.employeeId).join(", ")}</p>
            </Container>
        )
    }
}

export default ViewGroupDescription