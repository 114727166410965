import React from 'react';

import { AmplifySignOut } from '@aws-amplify/ui-react';
import { withRouter } from 'react-router-dom';
import { SideNavigation, Toggle, Box} from '../aws-ui-components';

import { applyMode, Mode } from '@amzn/awsui-global-styles';


// Component ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
function ServiceNavigation(props) {
  // If the provided link is empty, do not redirect pages
  function onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      props.history.push(ev.detail.href.substring(1));
    }
  }
  const modePreference = localStorage.getItem('themePreference')
  if (modePreference === 'dark') {
    applyMode(Mode.Dark);
  }
  else (
    applyMode(Mode.Light)
  )
  const darkMode = modePreference && modePreference === 'dark' ? true : false
  const [checked, setChecked] = React.useState(darkMode);

  return (
    <div>
      <SideNavigation
        header={{ text: 'FAST 🇺🇦', href: '#/' }}
        items={items}
        activeHref={`#${props.location.pathname}`}
        onFollow={onFollowHandler}
      />
      <hr />
      <div style={{margin: '10%'}}>
        <Box >
          <h4 >Found a bug, or do you want to give us feedback / feature suggestions?</h4>
          You can do so in <a target="_blank" href="https://form.asana.com?k=6U-hfEHTKi-12Hreo6JDYw&d=8442528107068">this form</a>
        </Box>
      </div>
      <hr />
      <div style={{margin: '10%'}}>
        <Box >
          <h4 >Need help (getting started)?</h4>
          Checkout the <a target="_blank" href="https://quip-amazon.com/SMeHAvWe8igE/FAST-Manual">the manual</a> or message us in #fast-interest on Slack or directly at lbrinker@ or jurasmj@.
        </Box>
      </div>
      <hr />
      <div style={{margin: '10%'}}>
        <Toggle
          onChange={({ detail }) => {
            localStorage.setItem('themePreference', (detail.checked === true ? 'dark' : 'light'));
            setChecked(detail.checked)
          }}
          checked={checked}
        >
          Enable Dark Mode
        </Toggle>
      </div>
      <hr />
      <div style={{margin: '10%'}}>
        <AmplifySignOut />
      </div>
    </div>

  );
}

const items = [
  {
    type: 'section',
    text: 'Available tools',
    items: [
      { type: 'link', text: 'Letter of Intent', href: '#/letter-of-intent-tool' },
      { type: 'link', text: 'Achievement Log', href: '#/achievement-log' },
      { type: 'link', text: 'Manager overview', href: '#/manager-overview' },
      { type: 'link', text: 'Groups', href: '#/groups' },
    ]
  },
  {
    type: 'section',
    text: 'Settings',
    items: [
      { type: 'link', text: 'Profile Settings', href: '#/profile-settings' }
    ]
  },
  {
    type: 'section',
    text: 'Work in progress',
    items: [
      { type: 'link', text: 'Content Audit Tool (CAT)', href: '#/content-audit-tool' },
      { type: 'link', text: 'Social Meetup Tool', href: '' },
      { type: 'link', text: 'Knowledge Management System', href: '' },
      { type: 'link', text: 'Internal Enablement Sessions', href: '' },
      { type: 'link', text: 'Customer shadows', href: '#/customer-shadow-tool' },
    ],
    defaultExpanded: false
  }
];

export default withRouter(ServiceNavigation);
