import React, { useState } from 'react';
import { API, graphqlOperation } from "aws-amplify";
import {
  Form,
  Input,
  SpaceBetween,
  Button,
  Box,
  Container,
  Header,
  FormField,
  DatePicker,
  Checkbox,
  Modal,
  ColumnLayout,
  ProgressBar
} from '../../aws-ui-components';
import { GetOrCreateUser } from '../../helpers/authHelpers';
import * as queries from '../../graphql/queries';

import { Link } from 'react-router-dom';

export default function AchievementsOverview({ totalAchievements, totalGoals, totalAchievedGoals, loading }) {
  const [exportModalVisible, setExportModalVisible] = useState(false)
  const [accessToken, setAccesstoken] = useState(null)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [highlightedOnly, setHighlightedOnly] = useState(false)
  const [resultQuip, setResultQuip] = useState(null)
  const [exportLoading, setExportLoading] = useState(false)

  const exportAchievements = async () => {
    setExportLoading(true)
    if (!accessToken) {
      alert("Please provide an access token for exporting")
    } else {
      const employee = await GetOrCreateUser()
      const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
        input: {
          requesterId: employee.id,
          dataSubjectId: employee.id,
          operation: 'dataExport',
          params: JSON.stringify({
            accessToken: accessToken,
            exportType: "individual",
            exportData: "achievementlog",
            exportProps: {
              exportHighlightedOnly: highlightedOnly,
              exportStart: startDate !== "" ? startDate : null,
              exportEnd: endDate !== "" ? endDate : null,
              exportGoals: null,
            }
          })
        }
      }))
      const response = result.data.getDataWithAuthorisationFunction
      if (response.message === "SUCCESS") {
        const responseObject = JSON.parse(response.queryReturn)
        setResultQuip(responseObject.thread.link)
      } else {
        setResultQuip(`ERROR: ${response.message}`)
      }
      setExportLoading(false)
    }
  }

  const ExportModal = (
    <Modal
      onDismiss={() => setExportModalVisible(false)}
      visible={exportModalVisible}
      closeAriaLabel="Close modal"
      size="large"
      footer={
        <div>
          {
            resultQuip && !resultQuip.startsWith("ERROR") && <Box float="left">
              <div style={{ color: 'green' }}>
                Success - find your Quip here:
                <Link to={"#"} onClick={() => window.open(resultQuip, '_blank')}> {resultQuip} </Link>
                (opens in new tab)
              </div>
            </Box>
          }
          {
            resultQuip && resultQuip.startsWith("ERROR") && <Box float="left">
              <div style={{ color: 'red' }}>
                {resultQuip}
              </div>
            </Box>
          }
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => setExportModalVisible(false)} variant="link">Cancel</Button>
              <Button variant="primary" onClick={exportAchievements} loading={exportLoading}>Export</Button>
            </SpaceBetween>
          </Box>
        </div>
      }
      header="Export to Quip"
    >
      <Form>
        <SpaceBetween direction="vertical" size="m">
          <FormField label="Quip Token" description={<div> Required </div>}>
            <Input onChange={(event) => setAccesstoken(event.detail.value)} value={accessToken} />
            To get the Quip token, go to: <Link to={"#"} onClick={() => window.open('https://quip-amazon.com/dev/token', '_blank')}> https://quip-amazon.com/dev/token </Link> (opens in new tab)
          </FormField>
          <FormField label="Export highlighted achievements only?" description="(Optional) select to only include achievements flagged as 'Highlighted'">
            <Checkbox
              checked={highlightedOnly}
              onChange={({ detail }) => setHighlightedOnly(detail.checked)}
            />
          </FormField>
          <FormField label="Achievements Start Date" description="(Optional) only include achievements finished (end date) _after_ or on this date">
            <DatePicker
              onChange={({ detail }) => setStartDate(detail.value)}
              value={startDate}
              openCalendarAriaLabel={selectedDate =>
                "Choose Date" +
                (selectedDate
                  ? `, selected date is ${selectedDate}`
                  : "")
              }
              nextMonthAriaLabel="Next month"
              placeholder="YYYY/MM/DD"
              previousMonthAriaLabel="Previous month"
              todayAriaLabel="Today"
            />
          </FormField>
          <FormField label="Achievements End Date" description="(Optional) only include achievements finished (end date) _before_ or on this date">
            <DatePicker
              onChange={({ detail }) => setEndDate(detail.value)}
              value={endDate}
              openCalendarAriaLabel={selectedDate =>
                "Choose Date" +
                (selectedDate
                  ? `, selected date is ${selectedDate}`
                  : "")
              }
              nextMonthAriaLabel="Next month"
              placeholder="YYYY/MM/DD"
              previousMonthAriaLabel="Previous month"
              todayAriaLabel="Today"
            />
          </FormField>
          <div>
            <h3>Disclaimer: </h3>
            <ul>
              <li>OAuth2 Authorization is currently not supported for Amazon's Quip, hence why we need to use a personal Quip access token to make this functionality work.</li>
              <li>We do not store Quip tokens, so you always need to input this. More info and how you can verify this <Link to={"#"} onClick={() => window.open('https://w.amazon.com/bin/view/Fully_Automated_SA_Tool/Quip_Usage', '_blank')}>on this wiki</Link>.</li>
              <li>Leakage of the personal Quip access token can lead to unauthorized parties making calls to the <a href='https://quip.com/dev/automation/documentation/current'>Quip API</a> acting on your behalf.</li>
              <li><b>⚠️ &nbsp; Important &nbsp; ⚠️ &nbsp;: by using this export functionality you accept the risk and responsibility to rotate your access token immediately after using this functionality</b></li>
              <li>You can refresh your token by refreshing <Link to={"#"} onClick={() => window.open('https://quip-amazon.com/dev/token', '_blank')}> https://quip-amazon.com/dev/token </Link> and requesting a new Personal Access Token. This will invalidate the previous one used for the export. For more info, see <a href="https://quip.com/dev/automation/documentation/current#section/Get-Access-to-Quip's-APIs" target="_blank">here</a></li>
            </ul>
          </div>
        </SpaceBetween>
      </Form>
    </Modal>
  )

  return (
    <Container
      className="custom-dashboard-container"
      header={
        <Header variant="h2" description="">
          Achievements statistics
        </Header>
      }
    >
      <ColumnLayout columns="2" variant="text-grid">
        <div>
          <Box margin={{ bottom: 'xl' }} color="text-label">
            Total number of achievements
          </Box>
          <div>
            <span className='achievement'>{loading === true ? "loading ..." : totalAchievements}</span>
          </div>
        </div>
        <div>
          <Box margin={{ bottom: 'xl' }} color="text-label">
            Total goals completed
          </Box>
          <div>
            <span className='achievement'>{loading === true ? "loading ..." : totalAchievedGoals + " / " + totalGoals} </span>
            <ProgressBar
              status={totalGoals > totalAchievedGoals ? 'in-progress' : 'success'}
              value={totalAchievedGoals / totalGoals * 100}
              variant="standalone"
            />
          </div>
        </div>
      </ColumnLayout>
      <Button onClick={() => setExportModalVisible(true)}>Export Achievements to Quip</Button>
      {ExportModal}
    </Container>
  );
}
