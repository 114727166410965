import * as React from "react";

import { API, graphqlOperation } from "aws-amplify";
import { Link } from 'react-router-dom';

import {
  SpaceBetween,
  Button,
  Modal,
  Box,
  Form,
  Input,
  DatePicker,
  FormField,
  Textarea,
  Autosuggest,
  Multiselect,
  Checkbox
} from '../../../aws-ui-components';

import SimpleTable from '../../../common-components/SimpleTable.jsx';

import { GetOrCreateUser } from '../../../helpers/authHelpers'

import * as queries from '../../../graphql/queries'
import { LEADERSHIPPRINCIPLES } from '../../../helpers/constants'

import LargeTextWrapper from '../../../common-components/LargeTextWrapper.jsx';

const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'name', label: 'Name', editable: false },
      { id: 'description', label: 'Description', editable: true },
      { id: 'amount', label: 'Amount', editable: true },
      { id: 'startDate', label: 'Start date', editable: true },
      { id: 'endDate', label: 'End date', editable: true },
      { id: 'loiGoalId', label: 'LoI Goal', editable: true },
      { id: 'leadershipPrinciples', label: 'LPs', editable: true },
      { id: 'highlighted', label: 'Highlighted', editable: true },
    ]
  }
];


const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Achievements' },
  { value: 30, label: '30 Achievements' },
  { value: 50, label: '50 Achievements' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['name', 'description', 'amount', 'startDate', 'endDate', 'loiGoalId', 'leadershipPrinciples', 'highlighted'],
  wrapLines: true,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: 'Name',
    cell: item => item.name,
    minWidth: '200px',
    sortingField: 'name'
  },
  {
    id: 'description',
    header: 'Description',
    cell: item => <LargeTextWrapper full_text={item.description} length={200} />,
    minWidth: '200px',
    sortingField: 'description'
  },
  {
    id: 'amount',
    header: 'Amount',
    cell: item => item.amount,
    minWidth: '100px',
    sortingField: 'amount'
  },
  {
    id: 'startDate',
    header: 'Start date',
    cell: item => new Date(item.startDate).toLocaleDateString('en-UK'),
    minWidth: '50px',
    maxWidth: '100px',
    sortingField: 'startDate'
  },
  {
    id: 'endDate',
    header: 'End date',
    cell: item => new Date(item.endDate).toLocaleDateString('en-UK'),
    minWidth: '100px',
    sortingField: 'endDate'
  },
  {
    id: 'loiGoalId',
    header: 'LoI Goal',
    cell: item => getGoalName(item),
    minWidth: '100px',
    sortingField: 'loiGoalId'
  },
  {
    id: 'leadershipPrinciples',
    header: 'LPs',
    cell: item => item.leadershipPrinciples?.map(lp => (<p key={lp}>{LEADERSHIPPRINCIPLES[lp]}</p>)),
    minWidth: '50px',
    maxWidth: '100px',
    sortingField: 'leadershipPrinciples'
  },
  {
    id: 'highlighted',
    header: 'Highlighted',
    cell: item => showHighlighted(item.highlighted),
    minWidth: '100px',
    sortingField: 'highlighted'
  },
];

const showHighlighted = (highlighted) => {
  if (highlighted) {
    return <span>&#9733;</span>
  }
}

const getGoalName = (item) => {
  if (item.goal) {
    return item.goal.name
  }
  return null
}

class DirectReportAchievementTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      startDate: "",
      endDate: "",
      error: "",
      modalVisible: false,
      employee: null,
      loadingTable: true,
      lettersOfIntent: [],
      userId: this.props.userId,
      exportLoading: false,
      resultQuip: null,
      exportModalVisible: false,
      accessToken: null,
      exportStartDate: "",
      exportEndDate: ""
    };
  }

  componentDidMount = async () => {
    const employee = await GetOrCreateUser()
    const response = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
        requesterId: employee.id,
        dataSubjectId: this.state.userId,
        operation: 'getAchievementDataAsManager'
      }
    }))
    const responseObj = response.data.getDataWithAuthorisationFunction
    var achievementData = []
    if (responseObj.status === "SUCCESS") {
      achievementData = JSON.parse(responseObj.queryReturn)
    }
    if (achievementData.length > 0) {
      achievementData = achievementData.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    this.setState({ employee: employee, lettersOfIntent: achievementData, loadingTable: false })
    this.setState({ loadingTable: false })
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  exportAchievements = async () => {
    const { highlightedOnly, exportStartDate, exportEndDate, accessToken } = this.state
    this.setState({ exportLoading: true })
    if (!accessToken) {
      alert("Please provide an access token for exporting")
    } else {
      const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
        input: {
          requesterId: this.state.employee.id,
          dataSubjectId: this.state.userId,
          operation: 'dataExport',
          params: JSON.stringify({
            accessToken: accessToken,
            exportType: "direct_report",
            exportData: "achievementlog",
            exportProps: {
              exportHighlightedOnly: highlightedOnly,
              exportStart: exportStartDate !== "" ? exportStartDate : null,
              exportEnd: exportEndDate !== "" ? exportEndDate : null,
              exportGoals: null,
            }
          })
        }
      }))
      const response = result.data.getDataWithAuthorisationFunction
      if (response.message === "SUCCESS") {
        const responseObject = JSON.parse(response.queryReturn)
        this.setState({ resultQuip: responseObject.thread.link })
      } else {
        this.setState({ resultQuip: `ERROR: ${response.message}` })
      }
      this.setState({ exportLoading: false })
    }
  }

  actions = (selectedDistributions) => {
    const isOnlyOneSelected = selectedDistributions.length === 1;
    const { exportModalVisible, resultQuip, exportStartDate, exportEndDate, accessToken, highlightedOnly, exportLoading } = this.state
    const ExportModal = (
      <Modal
        onDismiss={() => this.setState({ exportModalVisible: false })}
        visible={exportModalVisible}
        closeAriaLabel="Close modal"
        size="large"
        footer={
          <div>
            {
              resultQuip && !resultQuip.startsWith("ERROR") && <Box float="left">
                <div style={{ color: 'green' }}>
                  Success - find your Quip here:
                  <Link to={"#"} onClick={() => window.open(resultQuip, '_blank')}> {resultQuip} </Link>
                  (opens in new tab)
                </div>
              </Box>
            }
            {
              resultQuip && resultQuip.startsWith("ERROR") && <Box float="left">
                <div style={{ color: 'red' }}>
                  {resultQuip}
                </div>
              </Box>
            }
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => this.setState({ exportModalVisible: false })} variant="link">Cancel</Button>
                <Button variant="primary" onClick={this.exportAchievements} loading={exportLoading}>Export</Button>
              </SpaceBetween>
            </Box>
          </div>
        }
        header="Export to Quip"
      >
        <Form>
          <SpaceBetween direction="vertical" size="m">
            <FormField label="Quip Token" description={<div> Required </div>}>
              <Input onChange={(event) => this.setState({ accessToken: event.detail.value })} value={accessToken} />
              To get the Quip token, go to: <Link to={"#"} onClick={() => window.open('https://quip-amazon.com/dev/token', '_blank')}> https://quip-amazon.com/dev/token </Link> (opens in new tab)
            </FormField>
            <FormField label="Export highlighted achievements only?" description="(Optional) select to only include achievements flagged as 'Highlighted'">
              <Checkbox
                checked={highlightedOnly}
                onChange={({ detail }) => this.setState({ highlightedOnly: detail.checked })}
              />
            </FormField>
            <FormField label="Achievements Start Date" description="(Optional) only include achievements finished (end date) _after_ or on this date">
              <DatePicker
                onChange={({ detail }) => this.setState({ exportStartDate: detail.value })}
                value={exportStartDate}
                openCalendarAriaLabel={selectedDate =>
                  "Choose Date" +
                  (selectedDate
                    ? `, selected date is ${selectedDate}`
                    : "")
                }
                nextMonthAriaLabel="Next month"
                placeholder="YYYY/MM/DD"
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
              />
            </FormField>
            <FormField label="Achievements End Date" description="(Optional) only include achievements finished (end date) _before_ or on this date">
              <DatePicker
                onChange={({ detail }) => this.setState({ exportEndDate: detail.value })}
                value={exportEndDate}
                openCalendarAriaLabel={selectedDate =>
                  "Choose Date" +
                  (selectedDate
                    ? `, selected date is ${selectedDate}`
                    : "")
                }
                nextMonthAriaLabel="Next month"
                placeholder="YYYY/MM/DD"
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
              />
            </FormField>
            <div>
              <h3>Disclaimer: </h3>
              <ul>
                <li>OAuth2 Authorization is currently not supported for Amazon's Quip, hence why we need to use a personal Quip access token to make this functionality work.</li>
                <li>We do not store Quip tokens, so you always need to input this. More info and how you can verify this <Link to={"#"} onClick={() => window.open('https://w.amazon.com/bin/view/Fully_Automated_SA_Tool/Quip_Usage', '_blank')}>on this wiki</Link>.</li>
                <li>Leakage of the personal Quip access token can lead to unauthorized parties making calls to the <a href='https://quip.com/dev/automation/documentation/current'>Quip API</a> acting on your behalf.</li>
                <li><b>⚠️ &nbsp; Important &nbsp; ⚠️ &nbsp;: by using this export functionality you accept the risk and responsibility to rotate your access token immediately after using this functionality</b></li>
                <li>You can refresh your token by refreshing <Link to={"#"} onClick={() => window.open('https://quip-amazon.com/dev/token', '_blank')}> https://quip-amazon.com/dev/token </Link> and requesting a new Personal Access Token. This will invalidate the previous one used for the export. For more info, see <a href="https://quip.com/dev/automation/documentation/current#section/Get-Access-to-Quip's-APIs" target="_blank">here</a></li>
              </ul>
            </div>
          </SpaceBetween>
        </Form>
      </Modal>
    );

    return (
      <SpaceBetween direction="horizontal" size="s">
        <Button
          onClick={() => this.setState({ exportModalVisible: true })}
        >
          Export achievements
        </Button>
        {ExportModal}
      </SpaceBetween>
    );
  }

  render() {
    const table_params = {
      dataItems: this.state.lettersOfIntent,
      columns: COLUMN_DEFINITIONS,
      defaultPreferences: DEFAULT_PREFERENCES,
      headerActions: this.actions,
      headerTitle: "Direct Report: Achievements",
      enableSearch: false,
      pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
      contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
      customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
      loading: this.state.loadingTable,
      booleanFilterField: "highlighted",
      booleanFilterFieldInitialValue: true,
      booleanFilterFieldLabel: "Show highlighted only"
    }

    return (
      <SimpleTable params={table_params} />
    );
  }
}

export default DirectReportAchievementTable