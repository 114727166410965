export const LEADERSHIPPRINCIPLES = {
    'customer_obsession': 'Customer Obsession',
    'ownership': 'Ownership',
    'invent_and_simplify': 'Invent and Simplify',
    'are_right_a_lot': 'Are right, a lot',
    'learn_and_be_curious': 'Learn and be curious',
    'hire_and_develop_the_best': 'Hire and develop the best',
    'insist_on_highest_standards': 'Insist on the highest standards',
    'think_big': 'Think big',
    'bias_for_action': 'Bias for action',
    'frugality': 'Frugality',
    'earn_trust': 'Earn trust',
    'dive_deep': 'Dive deep',
    'have_backbone_disagree_and_commit': 'Have backbone, disagree and commit',
    'deliver_results': 'Deliver results',
    'strive_to_be_earths_best_employer': 'Strive to be Earth\'s Best Employer',
    'success_and_scale_bring_broad_responsibility': 'Success and Scale Bring Broad Responsibility',
}

export const ROLEGUIDELINES = {
    'customer_or_partner_advisor': {
        'title': 'Customer (or partner) Advisor',
        'description': 'All direct customer engagement examples',
        'audience': 'sa_ic'
    },
    'advancing_organisational_capabilities': {
        'title': 'Advancing Organizational Capabilities',
        'description': 'Examples of all internally focused SA contributions',
        'audience': 'sa_ic'
    },
    'thought_leadership': {
        'title': 'Thought Leadership',
        'description': 'Example of scaling best practices through creation and delivery of technical content',
        'audience': 'sa_ic'
    },
    'be_curious_or_other': {
        'title': 'Be curious (or \'other\')',
        'description': 'Falls outside the Role Guidelines dimensions, but is generally considered to be \'be curious\' or \'other\'',
        'audience': 'sa_ic'
    }
}

export const GOALTYPES = [
    {label: "Group: Collective", description: "Select this type if the group should collectively do X amount of this goal", value: "GROUP_COLLECTIVE"},
    {label: "Group: Individual", description: "Select this type if each individual in this group should do X amount of this goal", value: "GROUP_INDIVIDUAL"},
]

export const GROUPMANAGERPERMISSIONS = {
    VIEW_ONLY: {label: "View Permissions Only", description: "Only allow this person to view the group (useful to allow view access without adding as member to group)"},
    ADD_GOALS: {label: "Add Goals", description: "Allow this member to add goals"},
    ADD_MEMBERS: {label: "Add Members", description: "Allow this user to remove members"},
    ADD_SUBGROUPS: {label: "Add Subgroups", description: "Allow this user to remove Subgroups"},
    REMOVE_GOALS: {label: "Remove Goals", description: "Allow this user to remove goals"},
    REMOVE_MEMBERS: {label: "Remove Members", description: "Allow this user to remove members"},
    REMOVE_SUBGROUPS: {label: "Remove Subgroups", description: "Allow this user to remove subgroups"},
}