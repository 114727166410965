export const customGetEmployee = /* GraphQL */ `
query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      email
      managerId
      createdAt
      updatedAt
      owner
      manager {
        id
        email
        managerId
      }
      lettersOfIntent {
        items {
          id
          employeeId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;


export const customListEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        managerId
        owner
        manager {
          id
          email
          managerId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const customUpdateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      email
      managerId
      createdAt
      updatedAt
      owner
      manager {
        id
        email
        managerId
        createdAt
        updatedAt
        owner
        manager {
          id
          email
          managerId
          createdAt
          updatedAt
          owner
        }
      }
      lettersOfIntent {
        items {
          id
          content
          employeeId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;

export const listLoIByEmployeeId = /* GraphQL */ `
  query ListLoIByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLetterOfIntentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoIByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startDate
        endDate
        goals {
          items {
            id
            goalId
            goal {
                id
                name
                description
                leadershipPrinciples
                roleGuidelines
                amount
                startDate
                endDate
                goalType
                groupId
                group {
                  id
                  name
                  description
                  groupManagerId
                  createdAt
                  updatedAt
                  owner
                }
                employeeId
                mandatory
                createdAt
                updatedAt
                owner
            }
            letterOfIntentId
            amount
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listSubGroupsByGroupId = /* GraphQL */ `
  query ListSubGroupsByGroupId(
    $groupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubGroupsByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        parentGroupId
        createdAt
        updatedAt
        group {
          id
          name
          description
          groupManagerId
          createdAt
          updatedAt
          groupManager {
            id
            email
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          owner
          members {
            nextToken
          }
          subGroups {
            nextToken
          }
          goals {
            items {
                id
                name
                description
                leadershipPrinciples
                roleGuidelines
                amount
                startDate
                endDate
                goalType
                groupId
                employeeId
                mandatory
                createdAt
                updatedAt
                owner
            }
            nextToken
           }
        }
        parentGroup {
          id
          name
          description
          groupManagerId
          createdAt
          updatedAt
          groupManager {
            id
            email
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          owner
          members {
            nextToken
          }
          subGroups {
            nextToken
          }
          goals {
            items {
                id
                name
                description
                leadershipPrinciples
                roleGuidelines
                amount
                startDate
                endDate
                goalType
                groupId
                employeeId
                mandatory
                createdAt
                updatedAt
                owner
            }
            nextToken
           }
        }
        owner
      }
      nextToken
    }
  }
`;

export const customListAdditionalManagersByEmployeeId = /* GraphQL */ `
  query ListAdditionalManagersByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdditionalManagersByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            items {
              employeeId
              actionsAllowed
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        actionsAllowed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAchievementsByEmployeeIdReduced = /* GraphQL */ `
  query ListAchievementsByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievementsByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        amount
        startDate
        endDate
        highlighted
        leadershipPrinciples
        roleGuidelines
        impact
        stakeholders
        employeeId
        goalId
        goal {
          id
          name
        }
        loiGoalId
        loiGoal {
          id
          goalId
          goal {
            id
            name
            amount
          }
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;