/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import ServiceNavigation from './ServiceNavigation.jsx';
import {
  AppLayout,
  ColumnLayout,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link
} from '../aws-ui-components';

import '../styles/servicehomepage.scss';

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default () => {
  return (
    <AppLayout
      disableContentPaddings={true}
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      content={<Content />}
      contentType="default"
      navigationOpen={true}
      toolsHide={true}
    />
  );
};

// The content in the main content area of the App layout
const Content = () => (
  <Box padding="s" margin={{ bottom: 'l' }}>
    <Grid className="custom-home__header" gridDefinition={[{ colspan: { xxs: 12 } }]}>
      <Box padding={{ vertical: 'xxl' }}>
        <Grid
          gridDefinition={[
            { offset: { l: '2', xxs: '1' }, colspan: { l: '8', xxs: '10' } },
            { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
            { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
          ]}
        >
          <Box fontWeight="light" padding={{ top: 'xs' }}>
            <span className="custom-home__category">Specialists @ Scale (S@S)</span>
          </Box>

          <div className="custom-home__header-title">
            <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
              Fully Automated S@S Tool (FAST)
            </Box>
            <Box variant="p" fontWeight="light">
              <span className="custom-home__header-sub-title">
                Fully Automated S@S Tool (FAST) is a central place for tools that people from the S@S team have created to make their life easier.
              </span>
            </Box>
          </div>

          {/* <Container>
            <SpaceBetween size="xl">
              <Box variant="h2" padding="n">
                Create CloudFront distribution
              </Box>
              <FormField stretch={true} label="Delivery method">
                <Select
                  options={[{ id: '1', label: 'Web delivery method' }]}
                  selectedOption={{ id: '1', label: 'Web delivery method' }}
                />
              </FormField>
              <Button href="#" variant="primary">
                Next step
              </Button>
            </SpaceBetween>
          </Container> */}
        </Grid>
      </Box>
    </Grid>

    <Box padding={{ top: 'xxl' }}>
      <Grid
        gridDefinition={[
          { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
          { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
        ]}
      >
        <div>
          <SpaceBetween size="l">
            <div>
              <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                How it works
              </Box>
              <Container>
                <img src="../../images/fast.png" />
              </Container>
            </div>

            <div>
              <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                Benefits and features
              </Box>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Pre-configured frontend
                    </Box>
                    <Box variant="p">
                      With FAST, you don't need to configure polaris, you can just use the front-end project we've already setup.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Basic components
                    </Box>
                    <Box variant="p">
                      We've implemented easy-to-configure components that you can easily reuse. This way, you don't have to invent the wheel when you create common components that you do want to be able to tweak, such as tables, modals, etc.
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Midway authentication
                    </Box>
                    <Box variant="p">
                      We've implemented Midway authentication so that people can login using Amazon's internal idp. 
                    </Box>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Version Control System
                    </Box>
                    <Box variant="p">
                      You can easily commit code to our repository.
                    </Box>
                  </div>
                </ColumnLayout>
              </Container>
            </div>

            {/* <div>
              <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                Use cases
              </Box>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Configure multiple origins
                    </Box>
                    <Box variant="p">
                      Configure multiple origin servers and multiple cache behaviors based on URL path patterns on your
                      website. Use AWS origins such as Amazon S3 or Elastic Load Balancing, and add your own custom
                      origins to the mix.
                    </Box>
                    <Link href="#" external>
                      Learn more
                    </Link>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Deliver streaming video
                    </Box>
                    <Box variant="p">
                      Use CloudFront to deliver on-demand video without the need to set up or operate any media servers.
                      CloudFront supports multiple protocols for media streaming.
                    </Box>
                    <Link href="#" external>
                      Learn more
                    </Link>
                  </div>
                </ColumnLayout>
              </Container>
            </div> */}

            {/* <Container header={<Header variant="h2">Related services</Header>}>
              <ColumnLayout columns={2} variant="text-grid">
                <div>
                  <Box variant="h3" padding={{ top: 'n' }}>
                    <Link fontSize="heading-m" external>
                      Amazon S3
                    </Link>
                  </Box>
                  <Box variant="p">Use Amazon S3 to store the content that CloudFront delivers.</Box>
                </div>
                <div>
                  <Box variant="h3" padding={{ top: 'n' }}>
                    <Link fontSize="heading-m" external>
                      Amazon Route 53
                    </Link>
                  </Box>
                  <Box variant="p">
                    Use Amazon Route 53 to route DNS queries for your domain name to your CloudFront distribution.
                  </Box>
                </div>
              </ColumnLayout>
            </Container> */}
          </SpaceBetween>
        </div>

        <div className="custom-home__sidebar">
          <SpaceBetween size="xxl">
            {/* <Container header={<Header variant="h2">Pricing (US)</Header>}>
              <ul className="custom-list-separator">
                <li>
                  <span>10 TB/month</span>
                  <Box variant="span" color="text-body-secondary">
                    $0.085 per GB
                  </Box>
                </li>
                <li>
                  <span>100 TB/month</span>
                  <Box variant="span" color="text-body-secondary">
                    $0.065 per GB
                  </Box>
                </li>
                <li>
                  <span>524 TB/month</span>
                  <Box variant="span" color="text-body-secondary">
                    $0.035 per GB
                  </Box>
                </li>
                <li>
                  <span>4 PB/month</span>
                  <Box variant="span" color="text-body-secondary">
                    $0.025 per GB
                  </Box>
                </li>
                <li>
                  <Link href="#" external>
                    Cost calculator
                  </Link>
                </li>
              </ul>
            </Container>
            <Container
              header={
                <Header variant="h2">
                  Getting started <Icon name="external" />
                </Header>
              }
            >
              <ul className="custom-list-separator">
                <li>
                  <Link
                    href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html"
                    target="_blank"
                  >
                    What is Amazon CloudFront?
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/GettingStarted.html"
                    target="_blank"
                  >
                    Getting started with CloudFront
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html"
                    target="_blank"
                  >
                    Working with CloudFront distributions
                  </Link>
                </li>
              </ul>
            </Container>
            <Container
              header={
                <Header variant="h2">
                  More resources <Icon name="external" />
                </Header>
              }
            >
              <ul className="custom-list-separator">
                <li>
                  <Link href="https://aws.amazon.com/documentation/cloudfront/" target="_blank">
                    Documentation
                  </Link>
                </li>
                <li>
                  <Link href="#">FAQ</Link>
                </li>
                <li>
                  <Link href="#">CloudFront forum</Link>
                </li>
                <li>
                  <Link href="#">Contact us</Link>
                </li>
              </ul>
            </Container> */}
          </SpaceBetween>
        </div>
      </Grid>
    </Box>
  </Box>
);
