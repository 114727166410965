/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

 https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import { API, graphqlOperation } from "aws-amplify";
import { 
    Form, 
    Input, 
    SpaceBetween, 
    Button, 
    Box, 
    Container, 
    Header, FormField, 
    Textarea, 
    DatePicker, 
    Multiselect,  
    Checkbox,
    Modal
} from '../../../aws-ui-components';

import { Link } from 'react-router-dom';

import { GetOrCreateUser } from '../../../helpers/authHelpers';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';

import LargeTextWrapper from '../../../common-components/LargeTextWrapper';
import { LEADERSHIPPRINCIPLES } from '../../../helpers/constants'

class LetterOfIntentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            letterOfIntent: this.props.letterOfIntent,
            name: "",
            startDate: "",
            endDate: "",
            introduction: "",
            summary: "",
            error: "",
            success: "",
            draftMode: true,
            selectedSuperPowers: [],
            selectedGrowthAreas: [],
            flashBarItems: [],
            editLoiModalVisible: false,
            exportModalVisible: false,
            exportLoading: false,
            resultQuip: null,
            accessToken: ""
        }
        this.initialState = this.state
    }

    componentDidMount = async() => {
        // await this.setLoiFromDB()
        this.setStateFromDB()
    }

    reset = async() => {
        var resetState = this.initialState
        resetState.letterOfIntent = this.state.letterOfIntent
        this.setState(resetState)
        // await this.setLoiFromDB()
        this.setStateFromDB()
    }

    setStateFromDB = async() => {
        // Set any value in state which we can get from the letter object
        const loi = this.state.letterOfIntent
        var stateUpdate = {}
        if (loi) {
            for (const [key, value] of Object.entries(loi)) {
                stateUpdate[key] = value
            }
            const lps = Object.entries(LEADERSHIPPRINCIPLES).map(item => ({label: item[1], value: item[0]}))
            stateUpdate.selectedSuperPowers = lps.filter(obj => loi.superPowers?.find(sp => sp === obj.value)) 
            stateUpdate.selectedGrowthAreas = lps.filter(obj => loi.growthAreas?.find(sp => sp === obj.value)) 
        }
        this.setState(stateUpdate)
    }

    onChange = (field, event) => {
        this.setState({[field]: event.detail.value})
    }

    validateFields = () => {
        const { name, startDate, endDate, summary, introduction, selectedSuperPowers, selectedGrowthAreas } = this.state
        if ([name, startDate, endDate, summary, introduction, selectedSuperPowers, selectedGrowthAreas].includes("")){
            this.setState({error: "Please fill in all values"})
            return false
        }
        else {
            this.setState({error: ""})
            return true
        }
    }
    updateLoi = async() => {
        const valid = this.validateFields()
        if (!valid) { return }
        const { name, startDate, endDate, summary, introduction, selectedSuperPowers, selectedGrowthAreas, draftMode } = this.state
        await API.graphql(graphqlOperation(
            mutations.updateLetterOfIntent, {
                input: {
                    id: this.state.id,
                    name: name,
                    summary: summary,
                    startDate: startDate,
                    endDate: endDate,
                    introduction, introduction,
                    superPowers: selectedSuperPowers.map(a => a.value),
                    growthAreas: selectedGrowthAreas.map(a => a.value),
                    draftMode: draftMode,
                }
            }
        ));
        this.setState({success: "Succesfully updated!", editLoiModalVisible: false})
    }

    exportLoi = async() => {
        this.setState({exportLoading: true})
        const accessToken = this.state.accessToken
        if (!accessToken) {
            alert("Please provide an access token for exporting")
        } else {
            const employee = await GetOrCreateUser()
            const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
                input: {
                    requesterId: employee.id,
                    dataSubjectId: employee.id,
                    operation: 'dataExport',
                    params: JSON.stringify({
                        accessToken: this.state.accessToken,
                        loiId: this.state.id,
                        exportType: "individual",
                        exportData: "loi"
                    })
                }
            }))
            const response = result.data.getDataWithAuthorisationFunction
            if (response.message === "SUCCESS") {
                const responseObject = JSON.parse(response.queryReturn)
                this.setState({resultQuip: responseObject.thread.link})
            } else {
                this.setState({resultQuip: `ERROR: ${response.message}`})
            }
            this.setState({exportLoading: false})
        }
    }
   
    render() {
        const { letterOfIntent, name, startDate, endDate, summary, introduction, selectedSuperPowers, selectedGrowthAreas, draftMode, resultQuip } = this.state
        if (!letterOfIntent) return <div> Loading ... </div>

        const ExportModal = (
            <Modal
                onDismiss={() => this.setState({exportModalVisible: false})}
                visible={this.state.exportModalVisible}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <div>
                        {
                            resultQuip && !resultQuip.startsWith("ERROR") && <Box float="left">
                                <div style={{color: 'green'}}>
                                    Success - find your Quip here: 
                                    <Link to={"#"} onClick={() => window.open(resultQuip, '_blank')}> {resultQuip} </Link>
                                    (opens in new tab)
                                </div>
                            </Box>
                        }
                        {
                            resultQuip && resultQuip.startsWith("ERROR") && <Box float="left">
                                <div style={{color: 'red'}}>
                                    {resultQuip}
                                </div>
                            </Box>
                        }
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => this.setState({exportModalVisible: false})} variant="link">Cancel</Button>
                                <Button variant="primary" onClick={this.exportLoi} loading={this.state.exportLoading}>Export</Button>
                            </SpaceBetween>
                        </Box>
                    </div>
                }
                header="Export to Quip"
            >
                <Form>
                    <SpaceBetween direction="vertical" size="m">
                        <FormField label="Quip Token" description={<div> Required </div>}>
                            <Input onChange={(event) => this.onChange('accessToken', event)} value={this.state.accessToken}/>
                            To get the Quip token, go to: <Link to={"#"} onClick={() => window.open('https://quip-amazon.com/dev/token', '_blank')}> https://quip-amazon.com/dev/token </Link> (opens in new tab)
                        </FormField>
                        <div>
                            <h3>Disclaimer: </h3>
                            <ul>
                                <li>OAuth2 Authorization is currently not supported for Amazon's Quip, hence why we need to use a personal Quip access token to make this functionality work.</li>
                                <li>We do not store Quip tokens, so you always need to input this. More info and how you can verify this <Link to={"#"} onClick={() => window.open('https://w.amazon.com/bin/view/Fully_Automated_SA_Tool/Quip_Usage', '_blank')}>on this wiki</Link>.</li>
                                <li>Leakage of the personal Quip access token can lead to unauthorized parties making calls to the <a href='https://quip.com/dev/automation/documentation/current'>Quip API</a> acting on your behalf.</li>
                                <li><b>⚠️ &nbsp; Important &nbsp; ⚠️ &nbsp;: by using this export functionality you accept the risk and responsibility to rotate your access token immediately after using this functionality</b></li>
                                <li>You can refresh your token by refreshing <Link to={"#"} onClick={() => window.open('https://quip-amazon.com/dev/token', '_blank')}> https://quip-amazon.com/dev/token </Link> and requesting a new Personal Access Token. This will invalidate the previous one used for the export. For more info, see <a href="https://quip.com/dev/automation/documentation/current#section/Get-Access-to-Quip's-APIs" target="_blank">here</a></li>
                            </ul>
                        </div>
                    </SpaceBetween>
                </Form>
            </Modal>
        )

        const UpdateLoiModal = (
            <Modal
                onDismiss={() => this.setState({editLoiModalVisible: false})}
                visible={this.state.editLoiModalVisible}
                closeAriaLabel="Close modal"
                size="large"
                header={
                    <Header variant="h1" description="On this modal you can edit your letter of intent">
                        Edit Letter of Intent
                    </Header>
                }
                footer={
                    <div>
                        <Box float="middle">
                            <div style={{color: 'red'}}>{this.state.error}</div>
                        </Box>
                        <Button variant="link" onClick={() => this.reset()}>Cancel</Button>
                        <Button variant="primary" onClick={() => this.updateLoi()}>Save changes</Button>
                    </div>
                }
            >
                <Form>
                    <SpaceBetween direction="vertical" size="m">
                    <FormField id="id-name" label="Name">
                        <Input 
                                onChange={(event) => this.onChange('name', event)} 
                                value={name} 
                        />
                    </FormField>
                    <FormField label="Start date">
                        <DatePicker
                                onChange={(event) => this.onChange('startDate', event)}
                                value={startDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                        />
                    </FormField>
                    <FormField label="End date">
                        <DatePicker
                                onChange={(event) => this.onChange('endDate', event)}
                                value={endDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                        />
                    </FormField>
                    <FormField label="Introduction">
                        <Textarea onChange={(event) => this.onChange('introduction', event)} value={introduction}/>
                    </FormField>
                    <FormField label="Super powers">
                        <Multiselect
                            selectedOptions={selectedSuperPowers}
                            onChange={({ detail }) =>
                                this.setState({selectedSuperPowers: detail.selectedOptions})
                            }
                            deselectAriaLabel={e => "Remove " + e.label}
                            options={Object.entries(LEADERSHIPPRINCIPLES).map(item => ({label: item[1], value: item[0]}))}
                            placeholder="Choose options"
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                    <FormField label="Growth areas">
                        <Multiselect
                            selectedOptions={selectedGrowthAreas}
                            onChange={({ detail }) =>
                                this.setState({selectedGrowthAreas: detail.selectedOptions})
                            }
                            deselectAriaLabel={e => "Remove " + e.label}
                            options={Object.entries(LEADERSHIPPRINCIPLES).map(item => ({label: item[1], value: item[0]}))}
                            placeholder="Choose options"
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                    <FormField label="Summary">
                        <Textarea onChange={(event) => this.onChange('summary', event)} value={summary}/>
                    </FormField>
                    <FormField label="Draft mode" description="Enable to restrict access to yourself only, disable if you want the Letter of Intent to be visible for your manager">
                        <Checkbox
                            onChange={({ detail }) =>
                                this.setState({draftMode: detail.checked})
                            }
                            checked={this.state.draftMode}
                        />
                    </FormField>
                    </SpaceBetween>
                </Form>
            </Modal>
        )
    
        return (
            <Container
                header={
                <Header variant="h1" description="On this page you can view (and edit) your letter of intent">
                    {draftMode && " ( DRAFT )"} Letter of Intent
                </Header>
                }
                footer={
                    <div style={{display: 'flex'}}>
                        <div>
                            <Button onClick={() => this.setState({editLoiModalVisible: true})} variant="primary">Fill-in / Edit Letter of Intent</Button>
                        </div>
                        <div style={{marginLeft: '10px'}}>
                            <Button onClick={() => this.setState({exportModalVisible: true})}>Export to Quip</Button>
                        </div>
                        <Box float="middle">
                            <div style={{color: 'green', marginLeft: '10px'}}>{this.state.success}</div>
                        </Box>
                    </div>
                }
            >
                <div style={{fontSize: 18}}>
                    <p id="loi-name-id"><b>Name:</b> {name} </p>
                    <p><b>Start date:</b> {startDate} </p>
                    <p><b>End date:</b> {endDate} </p>
                    <p><b>Introduction:</b></p> <LargeTextWrapper full_text={introduction || ""} length={20000} />
                    <p><b>Super powers:</b> {selectedSuperPowers.map(a => a.label).join(", ")} </p>
                    <p><b>Growth areas:</b> {selectedGrowthAreas.map(a => a.label).join(", ")} </p>
                    <p><b>Summary:</b> </p> <LargeTextWrapper full_text={summary || ""} length={20000} />
                    <p><b>Draft mode:</b> {String(draftMode)} </p>
                </div>
                {ExportModal}
                {UpdateLoiModal}
            </Container>
        );
    }
}


export default LetterOfIntentForm
