import React from 'react';
import {
  SpaceBetween,
  Container,
  Header,
  Modal,
  Button,
  Box,
  Form,
  Input,
  FormField
} from '../../../aws-ui-components';

import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../../graphql/queries'

import DirectReportLoIGoals from './DirectReportLoIGoals'

import { Link } from 'react-router-dom';

import { LEADERSHIPPRINCIPLES } from '../../../helpers/constants'

class DirectReportLoIItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goals: this.props.goals,
      employee: this.props.employee,
      directReportId: this.props.directReportId,
      loi: this.props.loi,
      exportModalVisible: false,
      exportLoading: false,
      resultQuip: null,
      accessToken: ""
    }
  }

  onChange = (field, event) => {
    this.setState({ [field]: event.detail.value })
  }

  exportLoi = async () => {
    this.setState({ exportLoading: true })
    const accessToken = this.state.accessToken
    if (!accessToken) {
      alert("Please provide an access token for exporting")
    } else {
      const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
        input: {
          requesterId: this.state.employee.id,
          dataSubjectId: this.state.directReportId,
          operation: 'dataExport',
          params: JSON.stringify({
            accessToken: this.state.accessToken,
            loiId: this.state.loi.id,
            exportType: "direct_report",
            exportData: "loi"
          })
        }
      }))
      const response = result.data.getDataWithAuthorisationFunction
      if (response.message === "SUCCESS") {
        const responseObject = JSON.parse(response.queryReturn)
        this.setState({ resultQuip: responseObject.thread.link })
      } else {
        this.setState({ resultQuip: `ERROR: ${response.message}` })
      }
      this.setState({ exportLoading: false })
    }
  }

  render() {
    const { resultQuip } = this.state

    const ExportModal = (
      <Modal
        onDismiss={() => this.setState({ exportModalVisible: false })}
        visible={this.state.exportModalVisible}
        closeAriaLabel="Close modal"
        size="large"
        footer={
          <div>
            {
              resultQuip && !resultQuip.startsWith("ERROR") && <Box float="left">
                <div style={{ color: 'green' }}>
                  Success - find your Quip here:
                  <Link to={"#"} onClick={() => window.open(resultQuip, '_blank')}> {resultQuip} </Link>
                  (opens in new tab)
                </div>
              </Box>
            }
            {
              resultQuip && resultQuip.startsWith("ERROR") && <Box float="left">
                <div style={{ color: 'red' }}>
                  {resultQuip}
                </div>
              </Box>
            }
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => this.setState({ exportModalVisible: false })} variant="link">Cancel</Button>
                <Button variant="primary" onClick={this.exportLoi} loading={this.state.exportLoading}>Export</Button>
              </SpaceBetween>
            </Box>
          </div>
        }
        header="Export to Quip"
      >
        <Form>
          <SpaceBetween direction="vertical" size="m">
            <FormField label="Quip Token" description={<div> Required </div>}>
              <Input onChange={(event) => this.onChange('accessToken', event)} value={this.state.accessToken} />
              To get the Quip token, go to: <Link to={"#"} onClick={() => window.open('https://quip-amazon.com/dev/token', '_blank')}> https://quip-amazon.com/dev/token </Link> (opens in new tab)
            </FormField>
            <div>
              <h3>Disclaimer: </h3>
              <ul>
                <li>OAuth2 Authorization is currently not supported for Amazon's Quip, hence why we need to use a personal Quip access token to make this functionality work.</li>
                <li>We do not store Quip tokens, so you always need to input this. More info and how you can verify this <Link to={"#"} onClick={() => window.open('https://w.amazon.com/bin/view/Fully_Automated_SA_Tool/Quip_Usage', '_blank')}>on this wiki</Link>.</li>
                <li>Leakage of the personal Quip access token can lead to unauthorized parties making calls to the <a href='https://quip.com/dev/automation/documentation/current'>Quip API</a> acting on your behalf.</li>
                <li><b>⚠️ &nbsp; Important &nbsp; ⚠️ &nbsp;: by using this export functionality you accept the risk and responsibility to rotate your access token immediately after using this functionality</b></li>
                <li>You can refresh your token by refreshing <Link to={"#"} onClick={() => window.open('https://quip-amazon.com/dev/token', '_blank')}> https://quip-amazon.com/dev/token </Link> and requesting a new Personal Access Token. This will invalidate the previous one used for the export. For more info, see <a href="https://quip.com/dev/automation/documentation/current#section/Get-Access-to-Quip's-APIs" target="_blank">here</a></li>
              </ul>
            </div>
          </SpaceBetween>
        </Form>
      </Modal>
    )

    // Main content area (fill it in with components!)

    return (
      <SpaceBetween direction="vertical" size="xl">
        <Container
          header={<Header variant="h2">Letter of Intent: information</Header>}
        >
          <h3>Name: </h3> {this.state.loi.name}
          <h3>Introduction: </h3> {this.state.loi.introduction}
          <h3>Super powers:  </h3> <ul>{this.state.loi.superPowers?.map(item => (<li key={item}>{LEADERSHIPPRINCIPLES[item]}</li>))}</ul>
          <h3>Growth areas: </h3> <ul>{this.state.loi.growthAreas?.map(item => (<li key={item}>{LEADERSHIPPRINCIPLES[item]}</li>))}</ul>
          <h3>Summary: </h3> {this.state.loi.summary}
          <h3>Start date: </h3> {this.state.loi.startDate}
          <h3>End date: </h3> {this.state.loi.endDate}
          <div>
            <Button onClick={() => this.setState({ exportModalVisible: true })} variant="primary">Export to Quip</Button>
          </div>
        </Container>
        <DirectReportLoIGoals goals={this.state.goals} loading={this.state.loading} />
        {ExportModal}
      </SpaceBetween>
    );
  }
}

export default DirectReportLoIItem
