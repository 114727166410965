import * as React from "react";

import { API, graphqlOperation } from "aws-amplify";

import { SpaceBetween, Button, Modal, Box, Form, Input, Multiselect, DatePicker, FormField } from '../../../aws-ui-components';
import SimpleTable from '../../../common-components/SimpleTable.jsx';
import LargeTextWrapper from '../../../common-components/LargeTextWrapper.jsx';

import { GetOrCreateUser } from '../../../helpers/authHelpers'
import { getInheritedGoalsForCurrentUser } from '../../../helpers/goalHelpers'

import * as mutations from '../../../graphql/mutations'
import * as queries from '../../../graphql/queries'

import MDEditor from '@uiw/react-md-editor';

import { LEADERSHIPPRINCIPLES, ROLEGUIDELINES } from '../../../helpers/constants'


const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'description', label: 'Description', editable: true },
        { id: 'leadershipPrinciples',label: 'LPs', editable: true},
        { id: 'roleGuidelines',label: 'Role Guidelines', editable: true},
        { id: 'amount', label: 'Amount', editable: true},
        { id: 'startDate', label: 'Start Date', editable: true},
        { id: 'endDate', label: 'End Date', editable: true},
        { id: 'group', label: 'Group', editable: true},
        { id: 'goalType', label: 'Goal Type', editable: true},
      ]
    }
  ];

const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 goals' },
    { value: 30, label: '30 goals' },
    { value: 50, label: '50 goals' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'leadershipPrinciples', 'roleGuidelines', 'amount', 'startDate', 'endDate', 'goalType', 'group'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'description',
        header: 'Description',
        cell: item => <LargeTextWrapper full_text={item.description} length={200}/>,
        minWidth: '200px',
        sortingField: 'description'
    },
    {
        id: 'leadershipPrinciples',
        header: 'LPs',
        cell: item => item.leadershipPrinciples?.map(lp => (<p key={lp}>{LEADERSHIPPRINCIPLES[lp]}</p>)),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'leadershipPrinciples'
    },
    {
        id: 'roleGuidelines',
        header: 'Role Guidelines',
        cell: item => item.roleGuidelines?.map(rg => (<p key={rg}>{ROLEGUIDELINES[rg]['title']}</p>)),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'roleGuidelines'
    },
    {
        id: 'amount',
        header: 'Amount',
        cell: item => item.amount,
        minWidth: '50px',
        sortingField: 'amount'
    },
    {
        id: 'startDate',
        header: 'Start Date',
        cell: item => item.startDate ? new Date(item.startDate).toLocaleDateString('en-UK') : null,
        minWidth: '100px',
        sortingField: 'startDate'
    },
    {
        id: 'endDate',
        header: 'End Date',
        cell: item => new Date(item.endDate).toLocaleDateString('en-UK'),
        minWidth: '100px',
        sortingField: 'endDate'
    },
    {
        id: 'group',
        header: 'Group',
        cell: item => item.group?.name,
        minWidth: '100px',
        sortingField: 'group'
    },
    {
        id: 'goalType',
        header: 'Type',
        cell: item => item.goalType,
        minWidth: '100px',
        sortingField: 'goalType'
    }
];

class OptionalGoalTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            letterOfIntent: this.props.letterOfIntent,
            optionalLoiGoals: this.props.optionalGoals,
            updateStateFunction: this.props.updateState,
            employee: this.props.employee,
            addToLoiModalVisible: false,
            amount: "",
            editingGoal: null,
            error: ""
        };
    }

    addGoalToLoi = async(loi_goal_id) => {
        const loiGoalItem = this.state.optionalLoiGoals.find(item => item.id === loi_goal_id)
        if (loiGoalItem.goalType === "GROUP_COLLECTIVE") {
            // Person can adjust the number
            this.setState({addToLoiModalVisible: true, editingGoal: loiGoalItem})
        } else {
            await API.graphql(graphqlOperation(
                mutations.createLetterOfIntentGoal, {
                    input: {
                        goalId: loiGoalItem.id,
                        letterOfIntentId: this.state.letterOfIntent.id,
                        amount: loiGoalItem.amount
                    }
                }));
            await this.deleteExemptGoalIfPresent(loiGoalItem.id)
            this.state.updateStateFunction()
        }
    }

    deleteExemptGoalIfPresent = async(goalId) => {
        const exemptGoalData = await API.graphql(graphqlOperation(queries.loiExemptGoalByGoalId,{
            goalId: goalId
        }));
        const personalExemptGoals = exemptGoalData.data.loiExemptGoalByGoalId.items.filter(item => item.employeeId === this.state.employee.id)
        for (const goal of personalExemptGoals) {
            await API.graphql(graphqlOperation(
                mutations.deleteExemptGoal, {
                    input: { id: goal.id }
                }
            ))
        }
    }

    onSubmit = async() => {
        const { amount, editingGoal, letterOfIntent} = this.state
        await API.graphql(graphqlOperation(
            mutations.createLetterOfIntentGoal, {
                input: {
                    goalId: editingGoal.id,
                    letterOfIntentId: letterOfIntent.id,
                    amount: parseInt(amount)
                }
            }));
        await this.deleteExemptGoalIfPresent(editingGoal.id)
        this.state.updateStateFunction()
    }

    actions = (selectedDistributions) => {
        const isOnlyOneSelected = selectedDistributions.length === 1;
        const modalForm = (
            <Form>
                <SpaceBetween direction="vertical" size="m">
                    <FormField label="Amount" description={`Required: the total collective goal amount is ${this.state.editingGoal?.amount}, please define the amount which you want to set as your goal for your Letter of Intent`}>
                        <Input
                            type="number"
                            onChange={({detail}) => this.setState({amount: detail.value})}
                            value={this.state.amount}
                        />
                    </FormField>
                </SpaceBetween>
            </Form>
        )


        return (
            <SpaceBetween direction="horizontal" size="s">
                <Button disabled={!isOnlyOneSelected} onClick={() => this.addGoalToLoi(selectedDistributions[0]?.id)}> Add goal to Letter of Intent</Button>
                <Modal
                    onDismiss={() => this.setState({addToLoiModalVisible: false})}
                    visible={this.state.addToLoiModalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => this.setState({addToLoiModalVisible: false})} variant="link">Cancel</Button>
                                <Button variant="primary" onClick={this.onSubmit}>Add goal to Letter of Intent</Button>
                            </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Add Collective Goal to your Letter of Intent"
                >
                    {modalForm}
                </Modal>
            </SpaceBetween>
        );
    }
    render() {
        const table_params = {
            dataItems: this.state.optionalLoiGoals,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerActions: this.actions,
            headerTitle: "Optional goals (inherited from groups)",
            enableSearch: true,
            filterLabel: "Find goal",
            filterPlaceholder: "Find goal",
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            selection: "single"
        }
        return (
            <SimpleTable key={this.state.updatedKey} params={table_params}/> // Adding key is a hacky solution to re-render the table when a goal has been edited (this caused problems with selectedDistributions)
        );
    }
}

export default OptionalGoalTable
