import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import '@amzn/awsui-global-styles/polaris.css';

import App from './components/App'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.querySelector('#app')
  );
});
