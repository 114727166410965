/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

 https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import { API, graphqlOperation } from "aws-amplify";

import {
  // import the components that you use in the file here
  AppLayout,
  BreadcrumbGroup,
  SpaceBetween,
  ExpandableSection,
  Flashbar
} from '../../../aws-ui-components';

import { GetOrCreateUser } from '../../../helpers/authHelpers';

import LetterOfIntentForm from './LetterOfIntentForm'
import SimpleGoalTable from './GoalsTable'
import OptionalGoalTable from './OptionalGoalTable'

import ServiceNavigation from '../../../common-components/ServiceNavigation';

import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import { getInheritedGoalsForCurrentUser } from '../../../helpers/goalHelpers'



class LetterOfIntentOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      employee: null,
      letterOfIntent: null,
      loiGoals: [],
      formattedGoals: [],
      newMandatoryGoals: [],
      newOptionalGoals: [],
      newGoals: [],
      flashbarItems: []
    }
  }
  _isMounted = false;

  componentDidMount = async () => {
    this.setState({loading: true})
    this._isMounted = true;
    const employee = await GetOrCreateUser()
    await this.takeOwnershipOfGoals(employee)
    await this.updateState()
    this.setFlashBarItems()
  }

  takeOwnershipOfGoals = async(employee) => {
    const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
          requesterId: employee.id,
          dataSubjectId: employee.id,
          operation: 'takeOwnerShipOfGoals',
      }
    }))
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setFlashBarItems() {
    var items = []
    const date = new Date()
    const weekAgo = date.setDate(date.getDate()-7)
    var recentlyLambdaAddedGoals = this.state.loiGoals.filter(
      item => ( item.addedByLambda === true && new Date(item.createdAt) > weekAgo)
    )
    if (this.state.newOptionalGoals.length > 0) {
      items.push({
        id: "optionalGoals",
        type: "info",
        dismissible: true,
        content: (
          <>
            You have optional goal(s) which you can add to your LoI, see the second table on this page
          </>
        ),
        onDismiss: () => {
          const newItems = items.filter(item => item.id !== "optionalGoals")
          items = newItems
          this.setState({flashbarItems: items})
        }
      })
    }
    if (recentlyLambdaAddedGoals.length > 0) {
      items.push({
        id: "addedByLambda",
        type: "info",
        dismissible: true,
        dismissLabel: "Dismiss message",
        content: (
          <>
            We recently (in the last week) automatically added the following Mandatory goals: {recentlyLambdaAddedGoals.map(item => item.goal.name).join(", ")}
          </>
        ),
        onDismiss: () => {
          const newItems = items.filter(item => item.id !== "addedByLambda")
          items = newItems
          this.setState({flashbarItems: items})
        }
      })
    }
    if (this.state.newGoals.length > 0) {
      items.push({
        id: "addedByReact",
        type: "success",
        dismissible: true,
        dismissLabel: "Dismiss message",
        content: (
          <>
            We have added the following inherited goal(s) to your Letter of Intent: {this.state.newMandatoryGoals.map(item => item.name).join(", ")}
          </>
        ),
        onDismiss: () => {
          const newItems = items.filter(item => item.id !== "addedByReact")
          items = newItems
          this.setState({flashbarItems: items})
        }
      })
    }
    if(this.state.letterOfIntent.draftMode === true) {
      items.push({
        id: "draftWarning",
        type: "warning",
        color: "orange",
        dismissible: false,
        content: (
          <>
            This Letter of Intent is marked as Draft. If you want your manager to be able to view it, Edit the Letter of Intent and un-tick the Draft Mode option.
          </>
        ),
      })
    }
    this.setState({flashbarItems: items})
  }

  updateState = async () => {
    if (!this.state.loading === true) { this.setState({loading: true}) }
    const employee = await GetOrCreateUser()
    const letterOfIntentData = await API.graphql(graphqlOperation(queries.getLetterOfIntent, { id: this.state.id }));
    const letterOfIntent = letterOfIntentData.data.getLetterOfIntent
    const loiGoalsData = await API.graphql(graphqlOperation(queries.loiGoalByLoiId, { letterOfIntentId: this.state.id }));
    var loiGoals = loiGoalsData.data.loiGoalByLoiId.items
    const exemptGoals = await this.getExemptGoals(employee.id, letterOfIntent)
    const { newMandatoryGoals, newOptionalGoals } = await this.checkNewGoals(letterOfIntent, loiGoals, exemptGoals)
    const mergedArray = newOptionalGoals.concat(exemptGoals)
    var newGoals = []
    if (newMandatoryGoals.length > 0) {
      newGoals = await this.addMandatoryGoals(newMandatoryGoals, letterOfIntent)
      // Re-fetch the updated goals
      loiGoals = loiGoals.concat(newGoals)
    }
    const formattedGoals = this.formatLoiGoalsNicely(loiGoals)
    if (this._isMounted) {
      this.setState({ 
        letterOfIntent: letterOfIntent, 
        loiGoals: loiGoals, 
        formattedGoals: formattedGoals, 
        employee: employee,
        newMandatoryGoals: newMandatoryGoals,
        newOptionalGoals: mergedArray,
        newGoals: newGoals,
        loading: false
      })
    }
  }

  getExemptGoals = async(employeeId, letterOfIntent) => {
    const exemptGoalData = await API.graphql(graphqlOperation(
      queries.listExemptGoalsByEmployeeId, {
        employeeId: employeeId
      }
    ))
    const exemptGoals = exemptGoalData.data.listExemptGoalsByEmployeeId.items
    var activeExemptGoals = []
    for (const exemptGoal of exemptGoals) {
      if (this.checkDateIsInRange(exemptGoal.goal, letterOfIntent)) {
        activeExemptGoals.push(exemptGoal.goal)
      }
    }
    return activeExemptGoals
  }


  addMandatoryGoals = async(mandatoryGoals, letterOfIntent) => {
    var addedGoals = []
    for (const newGoal of mandatoryGoals){
      const newGoalData = await API.graphql(graphqlOperation(
        mutations.createLetterOfIntentGoal, {
            input: {
                goalId: newGoal.id,
                letterOfIntentId: letterOfIntent.id,
                amount: newGoal.amount
            }
        }));
      addedGoals.push(newGoalData.data.createLetterOfIntentGoal)
    }
    return addedGoals
  }

  formatLoiGoalsNicely = (loiGoals) => {
    var formattedLoiGoals = []
    for (const loiGoal of loiGoals) {
      const loiAmount = loiGoal.amount
      const goalAmount = loiGoal.goal.amount
      var amount = loiGoal.amount
      if (loiAmount !== goalAmount) {
        amount = `${loiAmount} out of ${goalAmount} `
      }
      formattedLoiGoals.push(
        {
          id: loiGoal.id,
          name: loiGoal.goal.name,
          description: loiGoal.goal.description,
          leadershipPrinciples: loiGoal.goal.leadershipPrinciples,
          roleGuidelines: loiGoal.goal.roleGuidelines,
          startDate: loiGoal.goal.startDate,
          endDate: loiGoal.goal.endDate,
          goalType: loiGoal.goal.goalType,
          group: loiGoal.goal.group,
          amount: amount,
        }
      )
    }
    return formattedLoiGoals
  }

  flushState = async () => {

  }

  checkNewGoals = async (letterOfIntent, loiGoals, exemptGoals) => {
    console.log("Exempt:", exemptGoals)
    var loiGoalObjects = []
    for (const loiGoal of loiGoals) {
      loiGoalObjects.push(loiGoal.goal)
    }
    const newGoals = await getInheritedGoalsForCurrentUser()
    var difference = []
    const loi = letterOfIntent
    for (const newGoal of newGoals) {
      if (
        !loiGoalObjects.some(e => e.id === newGoal.id)
        && this.checkDateIsInRange(newGoal, loi)
        && !newGoal.deactivated === true
        && !exemptGoals.some(e => e.id === newGoal.id)
        && !newGoal.draft === true
      ){
        difference.push(newGoal)
      }
    }
    const mandatory_goals = difference.filter(item => item.mandatory === true)
    const optional_goals = difference.filter(item => item.mandatory === false)
    return { newMandatoryGoals: mandatory_goals, newOptionalGoals: optional_goals}
  }

  checkDateIsInRange(goal, letterOfIntent) {
    if (!goal || !letterOfIntent) {
      return false
    }
    var startDateInScope = true
    if (goal.startDate) {
      startDateInScope = new Date(goal.startDate) <= new Date(letterOfIntent.endDate)
    }
    return startDateInScope && new Date(goal.endDate) > new Date(letterOfIntent.startDate)
  }

  render() {
    const Breadcrumbs = () => (
      <BreadcrumbGroup
        items={[
          {
            text: 'FAST',
            href: '#/'
          },
          {
            text: 'Letter of Intent tool',
            href: '#/letter-of-intent-tool'
          },
          {
            text: 'edit item',
            href: `#/letter-of-intent/${this.state.id}`
          }
        ]}
      />
    );

    // Main content area (fill it in with components!)
    // TODO: replace ID with object to save queries
    const Content = () => (
      <SpaceBetween direction="vertical" size="xl">
        <Flashbar 
          items={this.state.flashbarItems}
        />
        <ExpandableSection defaultExpanded header="Letter of Intent: Core">
          <LetterOfIntentForm letterOfIntent={this.state.letterOfIntent} />
        </ExpandableSection>
        <ExpandableSection defaultExpanded header="Letter of Intent: My Goals">
          <SimpleGoalTable
            letterOfIntent={this.state.letterOfIntent}
            loiGoals={this.state.loiGoals}
            formattedGoals={this.state.formattedGoals}
            employee={this.state.employee}
            updateState={this.updateState}
            loading={this.state.loading}
          />
        </ExpandableSection> 
        {
          this.state.newOptionalGoals.length > 0 && 
          <ExpandableSection defaultExpanded header="Letter of Intent: Optional Goals">
            <p><b>This section contains goals, inherited from Groups you're part of, which you can choose to optionally add to your Letter of Intent. </b></p>
            <OptionalGoalTable 
              letterOfIntent={this.state.letterOfIntent} 
              optionalGoals={this.state.newOptionalGoals} 
              updateState={this.updateState}
              employee={this.state.employee}
            />
          </ExpandableSection>
        }
      </SpaceBetween>
    )
    return (
      <AppLayout
        navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
        breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
        content={<Content />} // Main content on the page, defined below
        contentType="default" // Sets default app layout settings for widths
        navigationOpen={false}
        toolsHide={true}
      />
    );
  }
}

// Breadcrumb content


export default LetterOfIntentOverview
