import * as React from "react";
import { 
    Link, 
    StatusIndicator, 
    SpaceBetween, 
    Button, 
    Modal, 
    Box, 
    Select, 
    Input, 
    AttributeEditor
} from '../../aws-ui-components';

import { API, graphqlOperation, Auth } from "aws-amplify";
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

import { customGetEmployee, customUpdateEmployee, customListEmployees } from '../../graphql/customQueries'

import SimpleTable from '../../common-components/SimpleTable.jsx';
import { DATA } from './some-test-data.js';

const SimpleBlogTable = () => {
    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const getTagsString = (tagsArray) => {
        var tagString = ""
        for (let tag of tagsArray) {
            var tag_id = String(tag.id)
            if (tag_id.startsWith("blog-posts#post-tags#")){
                if (isJson(tag.description)){
                    tagString = tagString.concat(JSON.parse(tag.description).name)
                    tagString = tagString.concat(", ")
                }
                else{
                    tagString = tagString.concat(tag.description)
                }
            }
        }
        if (tagString.length > 0){
            tagString = tagString.substring(0, tagString.length - 2); // Trim last comma
        }
        return tagString
    }

    const blogData = () => {
        var items = []
        for (let item of DATA.items){
            var object = item.item.additionalFields
            object.tagString = getTagsString(item.tags)
            items.push(object)
        }
        return items
    }
    
    const CONTENT_SELECTOR_OPTIONS = [
        {
          label: 'Main distribution properties',
          options: [
            { id: 'title', label: 'Title', editable: false },
            { id: 'createdDate', label: 'Created', editable: true },
            { id: 'postExcerpt',label: 'Excerpt', editable: true},
            { id: 'tags', label: 'tags', editable: true},
            { id: 'audited', label: 'Audited', editable: true}
          ]
        }
      ];
      
    const PAGE_SELECTOR_OPTIONS = [
        { value: 10, label: '10 Blogs' },
        { value: 30, label: '30 Blogs' },
        { value: 50, label: '50 Blogs' }
    ];

    const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

    const DEFAULT_PREFERENCES = {
        pageSize: 30,
        visibleContent: ['title', 'createdDate', 'postExcerpt', 'tags', 'audited'],
        wrapLines: true,
        custom: CUSTOM_PREFERENCE_OPTIONS[0].value
    };

    const COLUMN_DEFINITIONS = [
        {
          id: 'title',
          header: 'Title',
          cell: item => <Link target="_blank" href={item.link}>{item.title}</Link>,
          minWidth: '180px',
          sortingField: 'id'
        },
        {
          id: 'createdDate',
          header: 'Created',
          cell: item => new Date(item.createdDate).toLocaleDateString('en-UK'),
          minWidth: '50px',
          maxWidth: '100px',
          sortingField: 'createdDate'
        },
        {
          id: 'postExcerpt',
          header: 'Excerpt',
          cell: item => item.postExcerpt,
          minWidth: '100px',
          sortingField: 'postExcerpt'
        },
        {
            id: 'tags',
            header: 'Tags',
            cell: item => item.tagString,
            minWidth: '100px',
            sortingField: 'tags'
        },
        {
          id: 'audited',
          header: 'Audited',
          cell: item => (
            <StatusIndicator type={item.state === 'Disabled' ? 'error' : 'success'}> {item.state}</StatusIndicator>
          ),
          width: '25px',
          minWidth: '100px',
          maxWidth: '100px',
          sortingField: 'Audited'
        }
    ];

    const createUser = async () => {
        const cognitoUser = await Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).catch(err => console.log(err));
        if (cognitoUser) {
            const username = cognitoUser.attributes.email.split('@')[0];
            const response = await API.graphql(graphqlOperation(
                customGetEmployee, {
                    id: username
                }
            ));
            const db_user = response.data.getEmployee
            if (!db_user) {
                const response = await API.graphql(graphqlOperation(
                    mutations.createEmployee, {
                        input: {
                            email: cognitoUser.attributes.email,
                            id: username,
                        }
                    }
                ));
            }
            listContentManager()
            // createContent(username)
            currentUser(username)
            // currentUser("lbrinker")
            //setManager("lbrinker+1", "lbrinker")
            testFunction()
        }
        
        

        // const response = await API.graphql(graphqlOperation(queries.listEmployees))
        // const userObject = await API.graphql(graphqlOperation(queries.listEmployees, {filter: {email: {eq: user.attributes.email}}}));

        // createContent(user.id)
        // listContentManager()
    }

    const testFunction = async () => {
        const response = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
            input: {
                requesterId: 'ramgopa',
                dataSubjectId: 'lbrinker',
                operation: 'getLettersOfIntentAsManager'
            }
        }))
        console.log(response)
        console.log("finished function test")
    }

    const setManager = async (user1id, user2id) => {
        await API.graphql(graphqlOperation(customUpdateEmployee, {input: {id: user1id, managerId: user2id}}))
    }

    const currentUser = async (id) => {
        const response = await API.graphql(graphqlOperation(customGetEmployee, { id: id}));
        console.log(response)
    }
    const createContent = async (employee_id) => {
        const response = await API.graphql(graphqlOperation(mutations.createLetterOfIntent, {input: {employeeId: employee_id}}));
        console.log(response.data.createLetterOfIntent);
    }

    const listContentManager = async () => {
        const response = await API.graphql(graphqlOperation(customListEmployees, { input: {}}));
        console.log(response);
    }

    const actions = (selectedDistributions) => {
        const [visible, setVisible] = React.useState(false);
        const [betterAlternatives, setBetterAlternatives] = React.useState(false);
        const [selectedOption, setSelectedOption] = React.useState();
        const [value, setValue] = React.useState("");
        const isOnlyOneSelected = selectedDistributions.length === 1;
        return (
            <SpaceBetween direction="horizontal" size="s">
            {/* <Button disabled={!isOnlyOneSelected}> View details </Button>
            <Button disabled={!isOnlyOneSelected}> Edit</Button>
            <Button disabled={selectedDistributions.length === 0}> Delete</Button> */}
            <Button onClick={() => setVisible(true)} variant="primary" disabled={selectedDistributions.length === 0} >
                Create audit report
            </Button>
            <Button onClick={() => createUser()} variant="primary" disabled={selectedDistributions.length === 0} >
                Create User Object
            </Button>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                        <Button variant="primary">Submit</Button>
                    </SpaceBetween>
                    </Box>
                }
                header="Create a content audit report"
            >
                <div style={{marginBottom: '20px'}}>
                    This content is relevant
                    <Select
                        options={[
                            { label: "Strongly agree", value: "1" },
                            { label: "Agree", value: "2" },
                            { label: "Neutral", value: "3" },
                            { label: "Disagree", value: "4" },
                            { label: "Strongly disagree", value: "5" },
                        ]}
                        selectedAriaLabel="Selected"
                    />
                </div>
                <div style={{marginBottom: '20px'}}>
                    This content is up-to-date
                    <Select
                        options={[
                            { label: "Strongly agree", value: "1" },
                            { label: "Agree", value: "2" },
                            { label: "Neutral", value: "3" },
                            { label: "Disagree", value: "4" },
                            { label: "Strongly disagree", value: "5" },
                        ]}
                        selectedAriaLabel="Selected"
                    />
                </div>
                <div style={{marginBottom: '20px'}}>
                    This content has better alternatives
                    <Select
                        onChange={({ detail }) => {
                            if(detail.selectedOption.value == "1"){
                                setBetterAlternatives(true)
                            }
                            else {
                                setBetterAlternatives(false)
                            }
                            setSelectedOption(detail.selectedOption)
                        }}
                        selectedOption={selectedOption}
                        options={[
                            { label: "Agree", value: "1" },
                            { label: "Neutral", value: "3" },
                            { label: "Disagree", value: "4" },
                        ]}
                        selectedAriaLabel="Selected"
                    />
                </div>
                { betterAlternatives && 
                    <div style={{marginBottom: '20px'}}>
                        <AttributeEditor
                            addButtonText="Add url"
                            removeButtonText="Remove url"
                            items={[
                                {
                                name: '',
                                value: ''
                                }
                            ]}
                            definition={[
                                {
                                    label: "Give alternatives:",
                                    control: item => <Input value={item.name} placeholder="Content url" />
                                }
                            ]}
                        />
                    </div>
                }
                <div>
                Upload Content Audit Report:
                <Input
                    onChange={({ detail }) => setValue(detail.value)}
                    value={value}
                    type="file"
                />
                </div>

            </Modal>
            </SpaceBetween>
        );
    }

    const table_params = {
        dataItems: blogData(),
        columns: COLUMN_DEFINITIONS,
        defaultPreferences: DEFAULT_PREFERENCES,
        headerActions: actions,
        headerTitle: "Blogs",
        filterLabel: "Find blog",
        filterPlaceholder: "Find blog",
        pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
        contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
        customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
        selection: "single",
        enableSearch: true,
    }

    return (
        <SimpleTable 
            params = {table_params}
        />
    );
}

export default SimpleBlogTable