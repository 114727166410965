import * as React from "react";
import { Link, SpaceBetween, Button, Modal, Box, Textarea, Input} from '../../aws-ui-components';
import SimpleTable from '../../common-components/SimpleTable.jsx';
import { DATA } from './some-test-data.js';

const SimpleShadowTable = () => {
    const CONTENT_SELECTOR_OPTIONS = [
        {
          label: 'Main distribution properties',
          options: [
            { id: 'customer', label: 'Customer', editable: false },
            { id: 'createdDate', label: 'Created', editable: true },
            { id: 'shadowAliases',label: 'Shadow Aliases', editable: true},
            { id: 'summary', label: 'Summary', editable: true},
            { id: 'notes', label: 'Notes', editable: true}
          ]
        }
      ];
      
    const PAGE_SELECTOR_OPTIONS = [
        { value: 10, label: '10 Shadows' },
        { value: 30, label: '30 Shadows' },
        { value: 50, label: '50 Shadows' }
    ];

    const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

    const DEFAULT_PREFERENCES = {
        pageSize: 30,
        visibleContent: ['customer', 'createdDate', 'shadowAliases', 'summary', 'notes'],
        wrapLines: true,
        custom: CUSTOM_PREFERENCE_OPTIONS[0].value
    };

    const COLUMN_DEFINITIONS = [
        {
          id: 'customer',
          header: 'Customer',
          cell: item => <Link target="_blank" href={item.customer}>{item.customer}</Link>,
          minWidth: '180px',
          sortingField: 'customer'
        },
        {
          id: 'createdDate',
          header: 'Created',
          cell: item => new Date(item.createdDate).toLocaleDateString('en-UK'),
          minWidth: '50px',
          maxWidth: '100px',
          sortingField: 'createdDate'
        },
        {
          id: 'shadowAliases',
          header: 'Shadowed (aliases)',
          cell: item => item.shadowAliases,
          minWidth: '100px',
          sortingField: 'shadowAliases'
        },
        {
            id: 'summary',
            header: 'Summary',
            cell: item => item.summary,
            minWidth: '100px',
            sortingField: 'summary'
        },
        {
            id: 'notes',
            header: 'Notes',
            cell: item => item.notes,
            minWidth: '100px',
            sortingField: 'notes'
        }
    ];
    const actions = (selectedDistributions) => {
        const [visible, setVisible] = React.useState(false);
        const [value, setValue] = React.useState("");
        const isOnlyOneSelected = selectedDistributions.length === 1;
        return (
            <SpaceBetween direction="horizontal" size="s">
            <Button disabled={!isOnlyOneSelected}> View details </Button>
            <Button disabled={!isOnlyOneSelected}> Edit</Button>
            <Button disabled={selectedDistributions.length === 0}> Delete</Button>
            <Button onClick={() => setVisible(true)} variant="primary" >
                Add Shadow Report
            </Button>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                        <Button variant="primary">Submit</Button>
                    </SpaceBetween>
                    </Box>
                }
                header="Create a Customer Shadow Report"
            >
                <div style={{marginBottom: '20px'}}>
                    Customer
                    <Input/>
                </div>
                <div style={{marginBottom: '20px'}}>
                    Shadowed (aliases)
                    <Input/>
                </div>
                <div style={{marginBottom: '20px'}}>
                    Summary
                    <Textarea
                        value={value}
                        placeholder="Type your summary here"
                    />
                </div>
                <div style={{marginBottom: '20px'}}>
                    Notes
                    <Textarea
                        value={value}
                        placeholder="Type your notes here"
                    />
                </div>
            </Modal>
            </SpaceBetween>
        );
    }

    const table_params = {
        dataItems: DATA.items,
        columns: COLUMN_DEFINITIONS,
        defaultPreferences: DEFAULT_PREFERENCES,
        headerActions: actions,
        headerTitle: "Customer Engagement shadows",
        filterLabel: "Find shadow",
        filterPlaceholder: "Find shadow",
        pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
        contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
        customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
        selection: "single",
        enableSearch: true,
    }

    return (
        <SimpleTable params={table_params}/>
    );
}

export default SimpleShadowTable