import React from 'react';
import { Link } from 'react-router-dom';

import { API, graphqlOperation } from "aws-amplify";

import {
    // import the components that you use in the file here
    Container,
    Header,
    Box,
    Button,
    Input,
    Textarea,
    Multiselect,
    FormField,
    SpaceBetween,
    Form,
    Modal,
  } from '../../../aws-ui-components';

import SimpleTable from '../../../common-components/SimpleTable.jsx';
import * as mutations from '../../../graphql/mutations';

import GroupSubGroupsSearchModal from './GroupSubGroupsSearchModal'

const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'id', label: 'id', editable: false },
        { id: 'description', label: 'description', editable: false },
        { id: 'manager', label: 'Manager', editable: false }
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Groups' },
    { value: 30, label: '30 Groups' },
    { value: 50, label: '50 Groups' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'manager', 'actions'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => <Link to={{pathname: `/group-details/${item.group.id}`}}>{item.group.name}</Link>,
        minWidth: '200px',
        sortingField: 'name',
    },    
    {
        id: 'description',
        header: 'Description',
        cell: item => item.group.description,
        minWidth: '200px',
        sortingField: 'description'
    },    
    {
        id: 'manager',
        header: 'Group Manager',
        cell: item => item.group.groupManagerId,
        minWidth: '200px',
        sortingField: 'manager'
    },
    // {
    //     id: 'actions',
    //     header: 'Actions',
    //     cell: item => <div><Link to={{pathname: `/edit-group/${item.id}`}}>view</Link> / <Link to={{pathname: `/group-details/${item.id}`}}>detach</Link></div>,
    //     minWidth: '200px',
    //     sortingField: 'id'
    // }
];


class GroupSubGroups extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            group: this.props.group,
            name: this.props.group?.name,
            subGroups: this.props.group?.subGroups?.items || [],
            // subgroups: this.props.group?.subgroups.items,
            success: ""
        }
        this.initialState = this.state
    }

    actions = (selectedDistributions) => {
        return (
            <SpaceBetween direction="horizontal" size="s">
                <Button 
                    disabled={selectedDistributions.length === 0}
                    onClick={() => this.detachSubgroups(selectedDistributions)}
                > 
                    Detach selected
                </Button>
                <GroupSubGroupsSearchModal group={this.state.group} setGroupData={this.props.setGroupData}/>
            </SpaceBetween>

        );
    }

    detachSubgroups = async(subgroups) => {
        const confirmed = confirm("Are you sure you want to detach the selected groups?")
        if (!confirmed) { return }
        
        for (const subGroup of subgroups) {
            await API.graphql(graphqlOperation(mutations.deleteSubGroup, { input: {id: subGroup.id}}))
        }
        await this.props.setGroupData()
    }

    updateGroup = async() => {
        const valid = this.validateFields()
        if (!valid) { return }
        const { group, name, description } = this.state
        await API.graphql(graphqlOperation(
            mutations.updateGroup, {
                input: {
                    id: group.id,
                    name: name,
                    description: description,
                }
            }
        ));
        this.setState({success: "Succesfully updated!"})
    }

    reset = async() => {
        this.setState(this.initialState)
    }

    onChange = (field, event) => {
        this.setState({[field]: event.detail.value})
    }

    validateFields = () => {
        const { name, description} = this.state
        if ([name, description].includes("")){
            this.setState({error: "Please fill in all values"})
            return false
        }
        else {
            this.setState({error: ""})
            return true
        }
    }

    render(){
        const table_params = {
            dataItems: this.state.subGroups,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerTitle: "Subgroups",
            headerActions: this.actions,
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            selection: 'multi',
            empty: "No Subgroups attached to this group",
            loading: this.props.loading,
        }
    
        return (
            <SimpleTable params={table_params}/>
        );
    }
}

export default GroupSubGroups