import React, {useEffect, useState} from 'react';
import ServiceNavigation from '../../common-components/ServiceNavigation';
import {AppLayout, BreadcrumbGroup, Grid, SpaceBetween, Header} from '../../aws-ui-components';
import '@amzn/awsui-global-styles/dark-mode-utils.css';
import AchievementsOverview from './AchievementLogOverview';
import AchievementLogTable from "./AchievementLogTable";
import GoalProgressTable from './GoalProgressTable';
import {API, graphqlOperation} from "aws-amplify";
import * as queries from '../../graphql/queries'
import { listAchievementsByEmployeeIdReduced } from '../../graphql/customQueries'
import {GetOrCreateUser} from "../../helpers/authHelpers";
import {currentActiveGoals, achievedActiveGoals, getGoalsWithProgress} from "../../helpers/goalHelpers"


export default function AchievementLogTool() {
    return (
        <AppLayout
            navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
            breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
            content={<Content />} // Main content on the page, defined below
            contentType="default" // Sets default app layout settings for widths
            navigationOpen={true}
            toolsHide={true}
        />
    );
}

// Breadcrumb content
const Breadcrumbs = () => (
    <BreadcrumbGroup
        items={[
            {
                text: 'FAST',
                href: '#/'
            },
            {
                text: 'Achievement Log',
                href: '#/achievement-log'
            }
        ]}
    />
);

// Main content area (fill it in with components!)
// const Content = () => <div> <AchievementLogTable/> </div>;

function Content(props) {
    const [achievements, setAchievements] = useState([]);
    const [totalAchievements, setTotalAchievements] = useState(0);
    const [totalGoals, setTotalGoals] = useState(0);
    const [activeGoals, setActiveGoals] = useState([]);
    const [allGoals, setAllGoals] = useState([]);
    const [totalAchievedGoals, setTotalAchievedGoals] = useState(0);
    const [employee, setEmployee] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const employee = await GetOrCreateUser();
            await takeOwnershipOfGoals(employee)
            setEmployee(employee)
            await updateData(employee)
            setLoading(false)
        }
        fetchData()
    }, []);

    const takeOwnershipOfGoals = async(employee) => {
        const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
          input: {
              requesterId: employee.id,
              dataSubjectId: employee.id,
              operation: 'takeOwnerShipOfGoals',
          }
        }))
    }

    const updateData = async(employee) => {
        if (employee) {
            await getTotalAchievements(employee);
            await getTotalGoals(employee);
        }
    }

    const getTotalGoals = async (employee) => {
        const all_goals = await getGoalsWithProgress(employee.id)
        const goals = await currentActiveGoals(employee.id)
        const goalsAchieved = await achievedActiveGoals(employee.id)
        setActiveGoals(goals)
        setAllGoals(all_goals)
        setTotalGoals(goals.length)
        setTotalAchievedGoals(goalsAchieved.length)
    };

    const getTotalAchievements = async (employee) => {
        const achievements = await API.graphql(graphqlOperation(
            listAchievementsByEmployeeIdReduced, {employeeId: employee.id, limit: 1000}
        ));
        var achievementData = achievements.data.listAchievementsByEmployeeId.items
        if (achievementData.length > 0) {
            achievementData = achievementData.sort(function(a,b){
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
        }
        setAchievements(achievementData)
        setTotalAchievements(achievementData.length)
    }
    return (
        <SpaceBetween size="m">
            <Header variant="h1">
                Achievement Log
            </Header>
            {/* <DashboardHeader updateTools={props.updateTools} /> */}
            <Grid
                gridDefinition={[
                    { colspan: { l: '12', m: '12', default: '12' } },
                    { colspan: { l: '12', m: '12', default: '12' } },
                    { colspan: { l: '12', m: '12', default: '12' } },
                ]}
            >
                <AchievementsOverview totalAchievements={totalAchievements} totalGoals={totalGoals} totalAchievedGoals={totalAchievedGoals} loading={loading}/>
                <AchievementLogTable achievements={achievements} updateData={updateData} employee={employee} activeGoals={activeGoals} loading={loading}/>
                <GoalProgressTable goals={allGoals} loading={loading} />
            </Grid>
        </SpaceBetween>
    );
}
