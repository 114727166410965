import * as React from "react";

import { API, graphqlOperation } from "aws-amplify";

import { SpaceBetween, Button, Modal, Box, Form, Input, Multiselect, DatePicker, FormField } from '../../../aws-ui-components';
import SimpleTable from '../../../common-components/SimpleTable.jsx';
import LargeTextWrapper from '../../../common-components/LargeTextWrapper.jsx';

import * as mutations from '../../../graphql/mutations'

import MDEditor from '@uiw/react-md-editor';

import { LEADERSHIPPRINCIPLES, ROLEGUIDELINES } from '../../../helpers/constants'


const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'description', label: 'Description', editable: true },
        { id: 'leadershipPrinciples',label: 'LPs', editable: true},
        { id: 'roleGuidelines',label: 'Role Guidelines', editable: true},
        { id: 'amount', label: 'Amount', editable: true},
        { id: 'startDate', label: 'Start Date', editable: true},
        { id: 'endDate', label: 'End Date', editable: true},
        { id: 'group', label: 'Group', editable: true},
        { id: 'goalType', label: 'Goal Type', editable: true},
      ]
    }
  ];

const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 goals' },
    { value: 30, label: '30 goals' },
    { value: 50, label: '50 goals' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'leadershipPrinciples', 'roleGuidelines', 'amount', 'startDate', 'endDate', 'group', 'goalType'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'description',
        header: 'Description',
        cell: item => <LargeTextWrapper full_text={item.description} length={200}/>,
        minWidth: '200px',
        sortingField: 'description'
    },
    {
        id: 'leadershipPrinciples',
        header: 'LPs',
        cell: item => item.leadershipPrinciples?.map(lp => (<p key={lp}>{LEADERSHIPPRINCIPLES[lp]}</p>)),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'leadershipPrinciples'
    },
    {
        id: 'roleGuidelines',
        header: 'Role Guidelines',
        cell: item => item.roleGuidelines?.map(rg => (<p key={rg}>{ROLEGUIDELINES[rg]['title']}</p>)),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'roleGuidelines'
    },
    {
        id: 'amount',
        header: 'Amount',
        cell: item => item.amount,
        minWidth: '50px',
        sortingField: 'amount'
    },
    {
        id: 'startDate',
        header: 'Start Date',
        cell: item => item.startDate ? new Date(item.startDate).toLocaleDateString('en-UK') : null,
        minWidth: '100px',
        sortingField: 'startDate'
    },
    {
        id: 'endDate',
        header: 'End Date',
        cell: item => new Date(item.endDate).toLocaleDateString('en-UK'),
        minWidth: '100px',
        sortingField: 'endDate'
    },
    {
        id: 'group',
        header: 'Group',
        cell: item => item.group?.name,
        minWidth: '100px',
        sortingField: 'group'
    },
    {
        id: 'goalType',
        header: 'Type',
        cell: item => item.goalType,
        minWidth: '100px',
        sortingField: 'goalType'
    }
];

class SimpleGoalTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            letterOfIntent: this.props.letterOfIntent,
            loiGoals: this.props.loiGoals,
            formattedGoals: this.props.formattedGoals,
            employee: this.props.employee,
            updateStateFunction: this.props.updateState,
            modalVisible: false,
            editModalVisible: false,
            editingLoiGoal: null,
            editingCollectiveGroupGoal: false,
            editingGroupGoal: false,
            updatedKey: Math.random(),

            // Form values:
            name: "",
            description: "",
            leadershipPrinciples: [],
            roleGuidelines: [],
            amount: null,
            startDate: "",
            endDate: "",
        };
        this.initial_state = this.state
    }

    flushState = async () => {
        this.setState(this.initial_state)
        this.setState({updatedKey: Math.random()})

    }

    deleteLoiGoal = async(id_to_delete) => {
        await API.graphql(graphqlOperation(
            mutations.deleteLetterOfIntentGoal, {
                input: {
                    id: id_to_delete,
                }
            }
        ));
    }

    deleteGoal = async (loi_goal_id_to_delete) => {
        const confirmed = confirm("Are you sure you want to delete this goal?")
        if (!confirmed) { return }
        const loi_goal = this.state.loiGoals.find(item => item.id === loi_goal_id_to_delete)
        if (loi_goal.goalType === "PERSONAL"){
            // We need to delete the Goal Object as well
            await API.graphql(graphqlOperation(
                mutations.deleteGoal, {
                    input: {
                        id: loi_goal.goal.id,
                    }
                }
            ));
        } else if(loi_goal.goal.deactivated === true) {
            await this.deleteLoiGoal(loi_goal_id_to_delete)
        } else if(loi_goal.goal.mandatory) {
            const reason = prompt('You are deleting a goal which is marked as Mandatory, it will be added to the optional goals below if you need to add it again. \n\n Please provide a reason why you are deleting this goal')
            await API.graphql(graphqlOperation(
                mutations.createExemptGoal, {
                    input: {
                        goalId: loi_goal.goalId,
                        employeeId: this.state.employee.id,
                        reason: reason
                    }
                }
            ))
            await this.deleteLoiGoal(loi_goal_id_to_delete)
        } else {
            await this.deleteLoiGoal(loi_goal_id_to_delete)
        }
        this.state.updateStateFunction()
    }

    onChange = (field, event) => {
        this.setState({[field]: event.detail.value})
    }

    validateFields = () => {
        const { name, description, amount, endDate, leadershipPrinciples, roleGuidelines } = this.state
        if ([name, description, amount, endDate, leadershipPrinciples, roleGuidelines].includes("")){
            this.setState({error: "Please fill in all values"})
            return false
        }
        else {
            this.setState({error: ""})
            return true
        }
    }

    onSubmit = async () => {
        const valid = this.validateFields()
        if (!valid) { return }
        const { name, description, amount, startDate, endDate, leadershipPrinciples, roleGuidelines } = this.state
        const cleanedStartDate = startDate !== "" ? startDate : null
        const createGoal = await API.graphql(graphqlOperation(
            mutations.createGoal, {
                input: {
                    name: name,
                    description: description,
                    amount: parseInt(amount),
                    startDate: cleanedStartDate,
                    endDate: endDate,
                    goalType: "PERSONAL",
                    employeeId: this.state.employee.id,
                    mandatory: false,
                    leadershipPrinciples: leadershipPrinciples.map(a => a.value),
                    roleGuidelines: roleGuidelines.map(a => a.value)
                }
            }
        ));
        const createLoIGoal = await API.graphql(graphqlOperation(
            mutations.createLetterOfIntentGoal, {
                input: {
                    goalId: createGoal.data.createGoal.id,
                    letterOfIntentId: this.state.letterOfIntent.id,
                    amount: createGoal.data.createGoal.amount
                }
            }
        ));
        if (createLoIGoal.data){
            this.setState({modalVisible: false})
            this.state.updateStateFunction()
        }
    }

    setStateFromDB = async(goalItem) => {
        // Set any value in state which we can get from the letter object
        var stateUpdate = {}
        if (goalItem) {
            for (const [key, value] of Object.entries(goalItem)) {
                stateUpdate[key] = value
            }
            const leadershipPrinciples = Object.entries(LEADERSHIPPRINCIPLES).map(item => ({label: item[1], value: item[0]}))
            stateUpdate.leadershipPrinciples = leadershipPrinciples.filter(obj => goalItem.leadershipPrinciples?.find(sp => sp === obj.value))
            stateUpdate.roleGuidelines = this.formatRoleGuidelines(goalItem.roleGuidelines)
        }
        this.setState(stateUpdate)
    }
    
    formatRoleGuidelines = (roleGuidelines) => {
        const roleGuidelineDict = Object.entries(ROLEGUIDELINES).map(item => ({
            label: item[1]['title'],
            description: item[1]['description'],
            value: item[0]
        }))
        return roleGuidelineDict.filter(obj => roleGuidelines?.find(sp => sp === obj.value))
    }

    updateGoal = async(goal_id) => {
        const { name, description, amount, startDate, endDate, leadershipPrinciples, roleGuidelines, goalType, editingLoiGoal } = this.state
        const cleanedStartDate = startDate !== "" ? startDate : null
        if (goalType === "GROUP_COLLECTIVE" && editingLoiGoal) {
            await API.graphql(graphqlOperation(
                mutations.updateLetterOfIntentGoal, {
                    input: {
                        id: editingLoiGoal,
                        amount: parseInt(amount)
                    }
                }
            ));
            this.state.updateStateFunction()
        }
        else if (goalType === "PERSONAL" && editingLoiGoal){
            await API.graphql(graphqlOperation(
                mutations.updateGoal, {
                    input: {
                        id: goal_id,
                        name: name,
                        description: description,
                        amount: parseInt(amount), // Goal Amount === LoI Amount
                        startDate: cleanedStartDate,
                        endDate: endDate,
                        goalType: goalType,
                        employeeId: this.state.employee.id,
                        mandatory: false,
                        leadershipPrinciples: leadershipPrinciples.map(a => a.value),
                        roleGuidelines: roleGuidelines.map(a => a.value)
                    }
                }
            ));
            await API.graphql(graphqlOperation(
                mutations.updateLetterOfIntentGoal, {
                    input: {
                        id: editingLoiGoal,
                        amount: parseInt(amount)
                    }
                }
            ));
            this.state.updateStateFunction()
        }
        else {
            alert("Can only edit personal goals")
        }
        this.setState({editModalVisible: false})
    }

    editGoal = async(loi_goal_id) => {
        const loiGoalItem = this.state.loiGoals.find(item => item.id === loi_goal_id)
        await this.setStateFromDB(loiGoalItem.goal)
        const editingCollectiveGroupGoal = loiGoalItem.goal.goalType === "GROUP_COLLECTIVE"
        const editingGroupGoal = loiGoalItem.goal.goalType && loiGoalItem.goal.goalType !== "PERSONAL"
        this.setState({
            editModalVisible: true,
            editingLoiGoal: loi_goal_id,
            editingCollectiveGroupGoal: editingCollectiveGroupGoal,
            editingGroupGoal: editingGroupGoal,
            amount: loiGoalItem.amount
        })
    }

    actions = (selectedDistributions) => {
        const isOnlyOneSelected = selectedDistributions.length === 1;
        const modalForm = (
            <Form>
                <SpaceBetween direction="vertical" size="m">
                    <FormField label="Name">
                        <Input
                            onChange={(event) => this.onChange('name', event)}
                            value={this.state.name}
                            disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                    <FormField label="Description" description="Define a SMART description for your Goal">
                        <MDEditor
                            preview={this.state.editingGroupGoal ? 'preview' : 'live'}
                            hideToolbar={this.state.editingGroupGoal}
                            value={this.state.description}
                            onChange={(event) => this.setState({description: event})}
                        />
                    </FormField>
                    <FormField label="Amount" description="Required: define amount of this goal, used to calculate goal progression. For a single big goal, select '1'">
                        <Input
                            type="number"
                            onChange={(event) => this.onChange('amount', event)}
                            value={this.state.amount}
                            disabled={(this.state.editingGroupGoal && !this.state.editingCollectiveGroupGoal)} // I can edit amount if personal and if it's a collective group goal
                        />
                    </FormField>
                    <FormField label="Start date" description="(Optional) Indicate when you will start working on this goal">
                        <DatePicker
                                onChange={(event) => this.onChange('startDate', event)}
                                value={this.state.startDate || ""}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                                disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                    <FormField label="End date" description="Required: indicate when you expect this goal to be completed">
                        <DatePicker
                                onChange={(event) => this.onChange('endDate', event)}
                                value={this.state.endDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                                disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                    <FormField label="Leadership Principles" description="(Optional) Link this goal to one or more LPs">
                        <Multiselect
                            selectedOptions={this.state.leadershipPrinciples}
                            onChange={({ detail }) =>
                                this.setState({leadershipPrinciples: detail.selectedOptions})
                            }
                            deselectAriaLabel={e => "Remove " + e.label}
                            options={Object.entries(LEADERSHIPPRINCIPLES).map(item => ({label: item[1], value: item[0]}))}
                            placeholder="Choose options"
                            selectedAriaLabel="Selected"
                            disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                    <FormField label="Role Guidelines dimensions" description="(Optional) Link this goal to one or more Role Guidelines dimensions"> 
                        <Multiselect 
                            selectedOptions={this.state.roleGuidelines}
                            onChange={({detail}) =>
                                this.setState({roleGuidelines: detail.selectedOptions})
                            }
                            deselectAriaLabel={e => "Remove " + e.label}
                            options={Object.entries(ROLEGUIDELINES).map(item => ({
                                label: item[1]['title'],
                                description: item[1]['description'],
                                value: item[0]
                            }))}
                            placeholder="Choose options"
                            selectedAriaLabel="Selected"
                            disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                </SpaceBetween>
            </Form>
        )


        return (
            <SpaceBetween direction="horizontal" size="s">
                <Button disabled={!isOnlyOneSelected} onClick={() => this.editGoal(selectedDistributions[0]?.id)}> Edit</Button>
                <Button
                    disabled={selectedDistributions.length !== 1 }
                    onClick={() => this.deleteGoal(selectedDistributions[0]?.id)}
                >
                    Delete
                </Button>
                <Button onClick={() => {this.flushState(); this.setState({modalVisible: true})}} variant="primary" >
                    Create new goal
                </Button>
                <Modal
                    onDismiss={() => this.setState({modalVisible: false})}
                    visible={this.state.modalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => this.setState({modalVisible: false})} variant="link">Cancel</Button>
                                <Button variant="primary" onClick={this.onSubmit}>Submit</Button>
                            </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Create a new personal goal"
                >
                    {modalForm}
                </Modal>
                <Modal
                    onDismiss={() => this.setState({editModalVisible: false})}
                    visible={this.state.editModalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => this.setState({editModalVisible: false})} variant="link">Cancel</Button>
                                <Button variant="primary" onClick={() => this.updateGoal(this.state.id)}>Submit</Button>
                            </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Edit goal"
                >
                    {modalForm}
                </Modal>
            </SpaceBetween>
        );
    }
    render() {
        const table_params = {
            dataItems: this.state.formattedGoals,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerActions: this.actions,
            headerTitle: "My goals",
            enableSearch: true,
            filterLabel: "Find goal",
            filterPlaceholder: "Find goal",
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            selection: "single",
            loading: this.props.loading,
            empty: "No goals created yet"
        }
        return (
            <SimpleTable key={this.state.updatedKey} params={table_params}/> // Adding key is a hacky solution to re-render the table when a goal has been edited (this caused problems with selectedDistributions)
        );
    }
}

export default SimpleGoalTable
