import { API, graphqlOperation } from "aws-amplify";

import * as queries from '../../../graphql/queries'

export const getListLoiData = async(requester, dataSubject) => {
    const response = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
        input: {
            requesterId: requester,
            dataSubjectId: dataSubject,
            operation: 'getLettersOfIntentAsManager'
        }
    }))
    const responseObj = response.data.getDataWithAuthorisationFunction
    var loiData = []
    if (responseObj.status === "SUCCESS") {
        loiData = JSON.parse(responseObj.queryReturn)
    }
    return loiData
}