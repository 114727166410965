/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React, { useState, useEffect } from 'react';
import {
  Header,
  Box,
  Button,
  Pagination,
  TextFilter,
  Table,
  Checkbox
} from '../aws-ui-components';

import { useCollection } from '@amzn/awsui-collection-hooks';
import * as tableHelper from '../helpers/tableHelpers.jsx';
import { Preferences } from '../helpers/tableHelpers.jsx';

/*
  AVAILABLE PARAMETERS:
  - "dataItems" -> data, in JSON format, to be displayed in table
  - "columns" -> definition of columns
  - "defaultPreferences" -> defines the default preferences of this table
  - "headerTitle" -> Header of title
  - "headerActions" -> Specify the header actions, e.g. edit, delete, create object
  - "filterLabel" -> label for filter
  - "filterPlaceholder" -> text displayed in filter as placeholder
  - "selection" -> "single" or "multi" to define the items that can be selected in the table
  - "contentSelectorOptions" -> Options for content that can be turned on or off in the table settings
  - "pageSelectorOptions" -> Options for the page settings (e.g. "10 per page")
  - "customPreferenceOptions" -> Options for custom preferences
  - "enableSearch" -> Allow for searching in the table
  - "selectedDistributions" -> allow to set selectedDistributions
  - "callbackDistributions" -> allow us to callback a function with the selected items
  - "itemsDisabledLogic -> set function to determine if item should be disabled"
  - "loading" -> Allow the table to display that it's loading
  - "resetDistributions" -> Allow us to reset selected items
  - "booleanFilterField" -> Allow us to specify a boolean filter field (tickbox)
  - "booleanFilterFieldInitialValue" -> Allows us to set the filter field value
  - "booleanFilterFieldLabel" -> Allows us to set the label for the field
  - "booleanFilterInvert" -> Allows you to invert the boolean value to hide/show additional items
  - "emptyDisplay" -> Allows you to display something if the table is empty
*/

const SimpleTable = ({ params }) => {
    const [columnDefinitions, saveWidths] = tableHelper.useColumnWidths('React-Table-Widths', params.columns);
    const [preferences, setPreferences] = useState(params.defaultPreferences);
    const [distributions, setDistributions] = useState([]);
    const [selectedDistributions, setSelectedDistributions] = useState([]);
    const getFilterCounterText = count => `${count} ${count === 1 ? 'match' : 'matches'}`;
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState(params.booleanFilterFieldInitialValue || false);

    var { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
      distributions,
      {
        filtering: {
            noMatch: (
              <Box textAlign="center" color="inherit">
                <b>No matches</b>
                <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
                  No results match your query
                </Box>
                <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
              </Box>
            )
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {}
      }
    );

    if (params.resetDistributions === true && selectedDistributions.length !== 0) {
      setSelectedDistributions([]);
    }

    /**
     * Normal boolean filter: If tickbox is unticked, show all values. If tickbox is ticked, filter by value === true
     * Inverted boolean filter: If tickbox is unticked, show true values only. If tickbox is ticked, show all values.
     */
    var setBooleanFilter = typeof params.booleanFilterField === 'string'
    var invertedFilter = typeof params.booleanFilterInvert === 'boolean' && params.booleanFilterInvert === true
    var trueItemsLengthString = ""
    if (setBooleanFilter) {
      var items_to_show = []
      // Inverted: show filtered items, if tickbox ticked, show all items
      var items_after_filtering = 0
      if (invertedFilter) {
        items_to_show = checked === true ? items : items.filter(item => item[params.booleanFilterField] === true)
        items_after_filtering = items.length
      } else {
        items_to_show = checked === true ? items.filter(item => item[params.booleanFilterField] === true) : items
        items_after_filtering = items.filter(item => item[params.booleanFilterField] === true).length
      }
      trueItemsLengthString = `(${items_after_filtering})`
      items = items_to_show
    }

    const selectionTypeProp = params.selection !== null ? {selectionType: params.selection} : {}

    // fetch distributions after render of the component
    useEffect(() => {
        setDistributions(params.dataItems);
        if (params.callbackDistributions) {
          params.callbackDistributions(selectedDistributions)
        }
        setLoading(false);
    });
    return (
      <Table
        isItemDisabled={params.itemsDisabledLogic}
        {...collectionProps}
        {...selectionTypeProp}
        columnDefinitions={columnDefinitions}
        visibleColumns={preferences.visibleContent}
        items={items}
        loading={loading || params.loading}
        loadingText="Loading data"
        ariaLabels={tableHelper.distributionSelectionLabels}
        stickyHeader
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        wrapLines={preferences.wrapLines}
        selectedItems={selectedDistributions}
        empty={params.empty}
        onSelectionChange={({ detail }) => setSelectedDistributions(detail.selectedItems)}
        header={
            <TableHeader
              selectedDistributions={selectedDistributions}
              counter={tableHelper.headerCounter(selectedDistributions, distributions)}
              headerActions={params.headerActions(selectedDistributions)}
              headerTitle={params.headerTitle}
            />
          }
        filter={
          <div>
            { params.enableSearch &&
              <TextFilter
                {...filterProps}
                filteringAriaLabel={params.filterLabel}
                filteringPlaceholder={params.filterPlaceholder}
                countText={getFilterCounterText(filteredItemsCount)}
              />
            }
            { setBooleanFilter &&
              <Checkbox
                  onChange={({ detail }) =>
                    setChecked(detail.checked)
                  }
                  checked={checked}
                >
                  {params.booleanFilterFieldLabel + " " + trueItemsLengthString}
              </Checkbox>
            }
          </div>


        }
        pagination={<Pagination {...paginationProps} ariaLabels={tableHelper.paginationLabels} />}
        preferences={
            <Preferences
                preferences={preferences}
                setPreferences={setPreferences}
                disabled={false}
                pageSizeOptions={params.pageSelectorOptions}
                visibleContentOptions={params.contentSelectorOptions}
                customPreferenceOptions={params.customPreferenceOptions}
            />
        }
      />
    );
};



// Table header content, shows how many distributions are selected and contains the action stripe
const TableHeader = ({ counter, headerActions, headerTitle }) => {
    return (
        <Header
            variant="h2"
            counter={counter}
            actions={headerActions}
        >
          {headerTitle}
        </Header>
    );
};

export default SimpleTable
