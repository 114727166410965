import React from 'react';
import { API, graphqlOperation } from "aws-amplify";

import {
    // import the components that you use in the file here
    Container,
    Header,
    Box,
    Button,
    Input,
    Textarea,
    Modal,
    FormField,
    SpaceBetween,
    Form,
} from '../../../aws-ui-components';

import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';

import SimpleTable from '../../../common-components/SimpleTable.jsx';
import { Multiselect } from '@amzn/awsui-components-react';

import { GROUPMANAGERPERMISSIONS } from '../../../helpers/constants'

const CONTENT_SELECTOR_OPTIONS = [
    {
        label: 'Main distribution properties',
        options: [
            { id: 'name', label: 'Name', editable: false },
            { id: 'alias', label: 'Alias', editable: false },
            { id: 'manager', label: 'Manager', editable: false },
            { id: 'permissions', label: 'Permissions', editable: false }
        ]
    }
];

const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Groups' },
    { value: 30, label: '30 Groups' },
    { value: 50, label: '50 Groups' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'alias', 'manager', 'permissions'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => `${item.employee.firstName} ${item.employee.lastName}`,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'alias',
        header: 'Alias',
        cell: item => item.employee.id,
        minWidth: '200px',
        sortingField: 'description'
    },
    {
        id: 'manager',
        header: 'Manager',
        cell: item => item.employee.managerId,
        minWidth: '200px',
        sortingField: 'manager'
    },
    {
        id: 'permissions',
        header: 'Permissions',
        cell: item => item.actionsAllowed.join(", "),
        minWidth: '200px',
        sortingField: 'permissions'
    },
];

class GroupDescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: this.props.employee,
            group: this.props.group,
            additionalGroupManagers: this.props.group?.additionalGroupManagers.items || [],
            name: this.props.group?.name,
            description: this.props.group?.description,
            success: "",
            error: "",
            modalError: "",
            managerAlias: "",
            selectedPermissions: [],
            buttonLoading: false
        }
        this.initialState = this.state
    }

    updateGroup = async () => {
        const valid = this.validateFields()
        if (!valid) { return }
        const { group, name, description } = this.state
        await API.graphql(graphqlOperation(
            mutations.updateGroup, {
            input: {
                id: group.id,
                name: name,
                description: description,
            }
        }
        ));
        this.setState({ success: "Succesfully updated!" })
        this.props.setGroupData()
    }

    reset = async () => {
        this.setState(this.initialState)
    }

    onChange = (field, event) => {
        this.setState({ [field]: event.detail.value })
    }

    validateFields = () => {
        const { name, description } = this.state
        if ([name, description].includes("")) {
            this.setState({ error: "Please fill in all values" })
            return false
        }
        else {
            this.setState({ error: "" })
            return true
        }
    }

    addManagerToGroup = async(alias) => {
        const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
            input: {
                requesterId: this.state.employee.id,
                dataSubjectId: alias,
                operation: 'addManagerToGroup',
                params: JSON.stringify({ permissions: this.state.selectedPermissions.map(item => item.value), groupId: this.state.group.id})
            }
        }))
        const resultData = result.data.getDataWithAuthorisationFunction
        if (resultData.status === "ERROR") {
            this.setState({modalError: resultData.message})
        }
        else {
            this.props.setGroupData()
        }
    }

    removeManagerFromGroup = async(member) => {
        this.setState({buttonLoading: true})
        await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
            input: {
                requesterId: this.state.employee.id,
                dataSubjectId: member.employeeId,
                operation: 'removeManagerFromGroup',
                params: JSON.stringify({ groupId: this.state.group.id})
            }
        }))
        this.setState({buttonLoading: false})
        this.props.setGroupData()
    }

    actions = (selectedDistributions) => {
        if (this.state.group?.groupManager.id !== this.state.employee?.id) { return <div></div>}
        return (
            <SpaceBetween direction="horizontal" size="s">
                <Button
                    disabled={selectedDistributions.length === 0}
                    loading={this.state.buttonLoading}
                    onClick={() => this.removeManagerFromGroup(selectedDistributions[0])}
                >
                    Remove Manager
                </Button>
                <Button onClick={() => this.setState({modalVisible: true})} variant="primary" >
                    Add Manager
                </Button>
            </SpaceBetween>

        );
    }

    handleKeypress = async(e) => {
        //it triggers by pressing the enter key
        if (e.detail.key === 'Enter') {
            await this.onSubmit();
        }
    };

    onSubmit = async() => {
        this.setState({modalError: "", buttonLoading: true})
        const { managerAlias, selectedPermissions } = this.state
        if (selectedPermissions.length === 0) {
            this.setState({modalError: "Please select permissions", buttonLoading: false})
            return
        }

        if (selectedPermissions.length > 1 && selectedPermissions.find(item => item.value === "VIEW_ONLY")){
            this.setState({modalError: "If View Only is selected, no other permissions should be checked", buttonLoading: false})
            return
        }

        const employeeObject = await API.graphql(graphqlOperation(queries.getEmployee, {id: managerAlias}))
        if (employeeObject.data.getEmployee === null) {
            this.setState({modalError: "Could not find this alias", buttonLoading: false})
        }
        else {
            await this.addManagerToGroup(managerAlias)
        }
        this.setState({buttonLoading: false})
        this.props.setGroupData()
    }


    render() {
        const table_params = {
            dataItems: this.state.additionalGroupManagers,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerTitle: "Additional Managers",
            headerActions: this.actions,
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            selection: (this.state.group?.groupManager !== this.state.employee?.id) ? null : 'single',
            // itemsDisabledLogic: ITEM_DISABLED,
            empty: "No additional managers in group",
            loading: this.props.loading,
        }

        return (
            <Container
                header={
                    <Header variant="h1">
                        Edit Group
                    </Header>
                }
                footer={
                    <div>
                        <Box float="middle">
                            <div style={{ color: 'red' }}>{this.state.error}</div>
                        </Box>
                        <Box float="middle">
                            <div style={{ color: 'green' }}>{this.state.success}</div>
                        </Box>
                        <Button variant="link" onClick={() => this.reset()}>Cancel</Button>
                        <Button variant="primary" onClick={() => this.updateGroup()}>Save changes</Button>
                    </div>
                }
            >
                <Modal
                    onDismiss={() => this.setState({modalVisible: false})}
                    visible={this.state.modalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.modalError}</div>
                            </Box>
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button onClick={() => this.setState({modalVisible: false})} variant="link">Cancel</Button>
                                    <Button disabled={this.state.managerAlias.length === 0}
                                            variant="primary"
                                            loading={this.state.buttonLoading}
                                            onClick={this.onSubmit}>Add Manager</Button>
                                </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Add manager to group"
                >
                    <FormField label="Add a manager by providing their alias" description="Note: if this alias is unknown, make sure that the person you're trying to add has logged into FAST before so we have a record of their alias">
                        <Input
                            onChange={(event) => this.onChange('managerAlias', event)}
                            onKeyDown={this.handleKeypress}
                            value={this.state.managerAlias}
                        />
                    </FormField>
                    <div style={{marginTop: '20px'}}>
                        <FormField label="Define permissions for manager" description="Note: to change permissions, remove manager, and add again with new permissions">
                            <Multiselect
                                selectedOptions={this.state.selectedPermissions}
                                onChange={({detail}) =>
                                    this.setState({selectedPermissions: detail.selectedOptions})
                                }
                                deselectAriaLabel={e => "Remove " + e.label}
                                options={Object.entries(GROUPMANAGERPERMISSIONS).map(item => ({
                                    label: item[1].label,
                                    description: item[1].description,
                                    value: item[0],
                                }))}
                                placeholder="Choose options"
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                    </div>
                </Modal>

                <Form>
                    <SpaceBetween direction="vertical" size="m">
                        <FormField label="Name">
                            <Input
                                onChange={(event) => this.onChange('name', event)}
                                value={this.props.loading ? "Loading..." : this.state.name}
                            />
                        </FormField>
                        <FormField label="Description">
                            <Textarea onChange={(event) => this.onChange('description', event)} value={this.props.loading ? "Loading..." : this.state.description} />
                        </FormField>
                        <FormField label="Additional Group Managers" description="Additional Group Managers can view the group and add/remove goals">
                            <SimpleTable params={table_params}/> 
                        </FormField>
                    </SpaceBetween>
                </Form>
            </Container>
        )
    }
}

export default GroupDescription