/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

 https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import {
  // import the components that you use in the file here
  AppLayout,
  BreadcrumbGroup,
  SpaceBetween,
  Container,
  Header
} from '../../aws-ui-components';

import { API, graphqlOperation } from "aws-amplify";

import ServiceNavigation from '../../common-components/ServiceNavigation';

import * as queries from '../../graphql/queries'

import DirectReportLoITable from './direct-report-loi/DirectReportLoITable'
import DirectReportAchievementTable from './direct-report-achievement/DirectReportAchievementTable'
import { displayPhoneToolLink } from "../../helpers/utils";


class DirectReportOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            user: null
        }
    }

    async componentDidMount() {
      const employee = await API.graphql(graphqlOperation(queries.getEmployee, { id: this.state.id}))
      this.setState({user: employee.data.getEmployee})
    }

    render() {
        const Breadcrumbs = () => (
            <BreadcrumbGroup
              items={[
                {
                  text: 'FAST',
                  href: '#/'
                },
                {
                  text: 'Manager overview',
                  href: '#/manager-overview'
                },
                {
                  text: 'Direct Report Overview',
                  href: `#/direct-report-overview/${this.state.id}`
                }
              ]}
            />
          );
        // Main content area (fill it in with components!)
        const Content = () => (
          <div>
            { this.state.user && 
              <SpaceBetween direction="vertical" size="xl">
                <Container
                  header={<Header variant="h2">Employee information: {this.state.user.name}</Header>}
                >
                  <ul>
                    <li>Name: {this.state.user.firstName + " " + this.state.user.lastName}</li>
                    <li>Alias: {displayPhoneToolLink(this.state.user.id)}</li>
                    <li>Level: {this.state.user.level}</li>
                    <li>Role: {this.state.user.role}</li>
                  </ul>
                </Container>
                <DirectReportLoITable userId={this.state.id}/>
                <DirectReportAchievementTable userId={this.state.id}/>
              </SpaceBetween>
            }
          </div>
        )
        return (
          <AppLayout
            navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
            breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
            content={<Content />} // Main content on the page, defined below
            contentType="default" // Sets default app layout settings for widths
            navigationOpen={false}
            toolsHide={true}
          />
        );
    }
}

// Breadcrumb content


export default DirectReportOverview
