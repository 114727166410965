/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

 https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import ServiceNavigation from '../../common-components/ServiceNavigation';
import {
  // import the components that you use in the file here
  AppLayout,
  BreadcrumbGroup,
} from '../../aws-ui-components';

import SimpleLoITable from './SimpleLoITable';

// Component Basic is a skeleton of the basic App layout using AWS-UI React components.
export default function LetterOfIntentTool() {
  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
      content={<Content />} // Main content on the page, defined below
      contentType="default" // Sets default app layout settings for widths
      navigationOpen={true}
      toolsHide={true}
    />
  );
}

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'FAST',
        href: '#/'
      },
      {
        text: 'Letter of Intent tool',
        href: '#/letter-of-intent-tool'
      }
    ]}
  />
);

// Main content area (fill it in with components!)
const Content = () => <div> <SimpleLoITable/> </div>;