import React from 'react';
import { Link } from 'react-router-dom';

import { API, graphqlOperation } from "aws-amplify";

import {
    // import the components that you use in the file here
    Container,
    Header,
    Box,
    Button,
    Input,
    Textarea,
    Multiselect,
    FormField,
    SpaceBetween,
    Form,
    Modal,
} from '../../../aws-ui-components';

import SimpleTable from '../../../common-components/SimpleTable.jsx';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';

import { GetOrCreateUser } from "../../../helpers/authHelpers";

import { getRecursiveMembers } from '../../../helpers/goalHelpers'

const CONTENT_SELECTOR_OPTIONS = [
    {
        label: 'Main distribution properties',
        options: [
            { id: 'name', label: 'Name', editable: false },
            { id: 'alias', label: 'Alias', editable: false },
            { id: 'manager', label: 'Manager', editable: false },
            { id: 'membership', label: 'Membership', editable: false }
        ]
    }
];

const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Groups' },
    { value: 30, label: '30 Groups' },
    { value: 50, label: '50 Groups' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'alias', 'manager', 'membership'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => `${item.employee.firstName} ${item.employee.lastName}`,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'alias',
        header: 'Alias',
        cell: item => item.employee.id,
        minWidth: '200px',
        sortingField: 'description'
    },
    {
        id: 'manager',
        header: 'Manager',
        cell: item => item.employee.managerId,
        minWidth: '200px',
        sortingField: 'manager'
    },
    {
        id: 'membership',
        header: 'Membership',
        cell: item => item.membership,
        minWidth: '200px',
        sortingField: 'membership'
    },
];

const ITEM_DISABLED = (item) => {
    if (!(item.membership === 'direct' || item.membership === 'invited' )){
        return true
    }
};


class GroupMembers extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            group: this.props.group || null,
            members: [],
            employee: null,
            member_string: "",
            buttonEnabled: true,
            loading: false,
        }
        this.initialState = this.state
    }

    componentDidMount = async() => {
        if (this.state.group) {
            await this.prepareMemberList()
        }
        const employee = await GetOrCreateUser()
        this.setState({employee: employee})
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    prepareMemberList = async() => {
        const members = await getRecursiveMembers(this.props.group)
        //deduplicate the same member coming from a different group
        const filteredMembers = members.map(members => ({
                "employee": members.employee,
                "employeeId": members.employeeId,
                "membership": members.membership,
            })
        );
        const filteredMembersSet = new Set(filteredMembers.map(JSON.stringify));

        this.setState({
            members: Array.from(filteredMembersSet).map(JSON.parse)
        });
    };

    actions = (selectedDistributions) => {
        return (
            <SpaceBetween direction="horizontal" size="s">
                <Button
                    disabled={selectedDistributions.length === 0}
                    onClick={() => this.removeMembers(selectedDistributions)}
                >
                    Remove members
                </Button>
                <Button onClick={() => this.setState({modalVisible: true})} variant="primary" >
                    Add members
                </Button>
            </SpaceBetween>

        );
    }

    removeMembers = async(members) => {
        const confirmed = confirm("Are you sure you want to remove the selected members?")
        if (!confirmed) { return }
        for (const member of members) {
            await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
                input: {
                    requesterId: this.state.employee.id,
                    dataSubjectId: member.employeeId,
                    operation: 'removeMemberFromGroup',
                    params: JSON.stringify({ groupId: this.state.group.id})
                }
            }))
        }
        this.props.setGroupData()
    }

    onSubmit = async() => {
        // V1: Add members by comma delimited string
        this.setState({buttonEnabled: false, loading: true});

        const member_string = this.state.member_string
        if (member_string === "") { return }
        const string_without_spaces = member_string.replace(/\s/g, '');
        const alias_array = string_without_spaces.split(",")
        var has_errors = false
        var error_string = "Couldn't add: "
        for (const alias of alias_array) {
            const data = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
                input: {
                    requesterId: this.state.employee.id,
                    dataSubjectId: alias,
                    operation: 'addMemberToGroup',
                    params: JSON.stringify({ groupId: this.state.group.id})
                }
            }))
            const dataItem = data.data.getDataWithAuthorisationFunction
            if (dataItem.status === "ERROR") {
                has_errors = true
                error_string = error_string.concat(alias + ", ")
            }
        }

        if (has_errors) {
            alert(error_string)
            await this.props.setGroupData()
        } else {
            await this.props.setGroupData()
        }
        this.setState({buttonEnabled: true, loading: false});
    }

    updateGroup = async() => {
        const valid = this.validateFields()
        if (!valid) { return }
        const { group, name, description } = this.state
        await API.graphql(graphqlOperation(
            mutations.updateGroup, {
                input: {
                    id: group.id,
                    name: name,
                    description: description,
                }
            }
        ));
        this.setState({success: "Succesfully updated!"})
    }

    reset = async() => {
        this.setState(this.initialState)
    }

    onChange = (field, event) => {
        this.setState({[field]: event.detail.value})
    }

    validateFields = () => {
        const { name, description} = this.state
        if ([name, description].includes("")){
            this.setState({error: "Please fill in all values"})
            return false
        }
        else {
            this.setState({error: ""})
            return true
        }
    }

    handleKeypress = async(e) => {
        //it triggers by pressing the enter key
        if (e.detail.key === 'Enter') {
            await this.onSubmit();
        }
    };

    render(){
        const table_params = {
            dataItems: this.state.members,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerTitle: "Members",
            headerActions: this.actions,
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            selection: 'multi',
            itemsDisabledLogic: ITEM_DISABLED,
            empty: "No members in group",
            loading: this.props.loading,
        }

        return (
            <div>
                <SimpleTable params={table_params}/>
                <Modal
                    onDismiss={() => this.setState({modalVisible: false})}
                    visible={this.state.modalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button onClick={() => this.setState({modalVisible: false})} variant="link">Cancel</Button>
                                    <Button disabled={!this.state.buttonEnabled}
                                            variant="primary"
                                            loading={this.state.loading}
                                            onClick={this.onSubmit}>Add members</Button>
                                </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Add members to group"
                >
                    <FormField label="Add members by writing down their alias, you can add multiple by separating them by commas (',')">
                        <Input
                            onChange={(event) => this.onChange('member_string', event)}
                            onKeyDown={this.handleKeypress}
                            value={this.state.member_string}
                        />
                    </FormField>
                </Modal>
            </div>

        );
    }
}

export default GroupMembers
