import { useMemo } from 'react';
import * as localStorage from './localStorage.js';
import { CollectionPreferences, FormField, RadioGroup } from '@amzn/awsui-components-react';

export const distributionSelectionLabels = {
    itemSelectionLabel: (data, row) => `select ${row.id}`,
    allItemsSelectionLabel: () => 'select all',
    selectionGroupLabel: 'Blog selection'
};

export const addToColumnDefinitions = (columnDefinitions, propertyName, columns) =>
    columnDefinitions.map(colDef => {
    const column = (columns || []).find(col => col.id === colDef.id);
    return {
        ...colDef,
        [propertyName]: column && column[propertyName]
    };
});

export const mapWithColumnDefinitionIds = (columnDefinitions, propertyName, items) =>
columnDefinitions.map(({ id }, i) => ({
  id,
  [propertyName]: items[i]
}));

export const paginationLabels = {
    nextPageLabel: 'Next page',
    previousPageLabel: 'Previous page',
    pageLabel: pageNumber => `Page ${pageNumber} of all pages`
};

export function useColumnWidths(storageKey, columnDefinitions) {
  function handleWidthChange(event) {
    const savedWidths = mapWithColumnDefinitionIds(columnDefinitions, 'width', event.detail.widths);
    localStorage.save(storageKey, savedWidths);
  }
  const memoDefinitions = useMemo(() => {
    const savedWidths = localStorage.load(storageKey);
    return addToColumnDefinitions(columnDefinitions, 'width', savedWidths);
  }, [columnDefinitions, storageKey]);

  return [memoDefinitions, handleWidthChange];
}

export function headerCounter(selectedDistributions, distributions) {
  return selectedDistributions.length
    ? `(${selectedDistributions.length} of ${distributions.length})`
    : `(${distributions.length})`;
}

const headerLabel = (title, sorted, descending) => {
    return `${title}, ${sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted'}.`;
};
  
const generateColumnLabel = ({ id, header }) => sortState => {
  const title = typeof header === 'function' ? header() : header;
  const columnIsSorted = sortState.sortingColumn === id;
  return headerLabel(title, columnIsSorted, sortState.sortingDescending);
};
  
export const addColumnSortLabelsV3 = columns =>
columns.map(col => ({
    ariaLabel: col.sortingField
    ? sortState => headerLabel(col.header, sortState.sorted, sortState.descending)
    : undefined,
    ...col
}));

export const addColumnSortLabels = (columns, sortColumns) =>
  columns.map(col => ({
      label: sortColumns.find(sortCol => sortCol.id === col.id) ? generateColumnLabel(col) : undefined,
      ...col
  })
);

export const Preferences = ({ preferences, setPreferences, disabled, pageSizeOptions, visibleContentOptions, customPreferenceOptions }) => (
    <CollectionPreferences
      title="Preferences"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      disabled={disabled}
      preferences={preferences}
      onConfirm={({ detail }) => setPreferences(detail)}
      pageSizePreference={{
        title: 'Page size',
        options: pageSizeOptions
      }}
      wrapLinesPreference={{
        label: 'Wrap lines',
        description: 'Check to see all the text and wrap the lines'
      }}
      visibleContentPreference={{
        title: 'Select visible columns',
        options: visibleContentOptions
      }}
      customPreference={(value, setValue) => (
        <FormField stretch={true} label="View as">
          <RadioGroup value={value} onChange={({ detail }) => setValue(detail.value)} items={customPreferenceOptions} />
        </FormField>
      )}
    />
  );