import * as React from "react";
import { Link } from 'react-router-dom';

import { API, graphqlOperation } from "aws-amplify";

import SimpleTable from '../../common-components/SimpleTable.jsx';
import { SpaceBetween, Button, Modal, Box, Form, Input, DatePicker, Textarea} from '../../aws-ui-components';

import * as mutations from '../../graphql/mutations'


const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'id', label: 'id', editable: false },
        { id: 'description', label: 'description', editable: false },
        { id: 'manager', label: 'Manager', editable: false },
        { id: 'additionalManagers', label: 'Additional Managers', editable: true}
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Groups' },
    { value: 30, label: '30 Groups' },
    { value: 50, label: '50 Groups' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'manager', 'additionalManagers', 'actions'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
        minWidth: '200px',
        sortingField: 'name'
    },    
    {
        id: 'description',
        header: 'Description',
        cell: item => item.description,
        minWidth: '200px',
        sortingField: 'description'
    },    
    {
        id: 'manager',
        header: 'Group Manager',
        cell: item => item.groupManagerId,
        minWidth: '200px',
        sortingField: 'manager'
    },    
    {
        id: 'additionalManagers',
        header: 'Additional Group Managers',
        cell: item => item.additionalGroupManagers.items.map(item => item.employeeId).join(", "),
        minWidth: '200px',
        sortingField: 'additionalManagers'
    },  
    {
        id: 'actions',
        header: 'Actions',
        cell: item => display_actions(item),
        minWidth: '200px',
        sortingField: 'id'
    }
];

const display_actions = (item) => {
    if (item.actionsAllowed && item.actionsAllowed[0] === 'VIEW_ONLY') {
        return <Link to={{pathname: `/group-details/${item.id}`}}><Button>View</Button></Link>
    }
    return <div><Link to={{pathname: `/edit-group/${item.id}`}}><Button>Edit</Button></Link> <Link to={{pathname: `/group-details/${item.id}`}}><Button>View</Button></Link></div>
}

class ManagingGroupsTable extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            name: "",
            description: ""
        }
    }

    validateFields = () => {
        const { name, description } = this.state
        if ([name, description].includes("")){
            this.setState({error: "Please fill in all values"})
            return false
        }
        else {
            this.setState({error: ""})
            return true
        }
    }

    onChange = (field, event) => {
        this.setState({[field]: event.detail.value})
    }

    onSubmit = async () => {
        const valid = this.validateFields()
        if (!valid) { return }
        const { name, description } = this.state
        const createGroup = await API.graphql(graphqlOperation(
            mutations.createGroup, {
                input: {
                    name: name,
                    description: description,
                    groupManagerId: this.props.employee.id
                }
            }
        ));
        if (createGroup.data){
            this.setState({modalVisible: false})
            this.props.updateState()
        }
    }

    actions = (selectedDistributions) => {
        const isOnlyOneSelected = selectedDistributions.length === 1;
        return (
            <SpaceBetween direction="horizontal" size="s">
            <Button onClick={() => this.setState({modalVisible: true})} variant="primary" >
                Create new group
            </Button>
            <Modal
                onDismiss={() => this.setState({modalVisible: false})}
                visible={this.state.modalVisible}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <div>
                        <Box float="left">
                            <div style={{color: 'red'}}>{this.state.error}</div>
                        </Box>
                        <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => this.setState({modalVisible: false})} variant="link">Cancel</Button>
                            <Button variant="primary" onClick={this.onSubmit}>Submit</Button>
                        </SpaceBetween>
                        </Box>
                    </div>
                }
                header="Create a new group"
            >
                    <Form>
                        <div style={{marginBottom: '20px'}}>
                            Name
                            <Input 
                                onChange={(event) => this.onChange('name', event)} 
                                value={this.state.name} 
                            />
                        </div>
                        <div>
                            Description
                            <Textarea
                                onChange={(event) => this.onChange('description', event)}
                                value={this.state.description}
                            />
                        </div>
                    </Form>
            </Modal>
            </SpaceBetween>
        );
    }
    render() {
        const empty = (
            <Box textAlign="center" color="inherit">
                <b>No groups</b>
                <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
                >
                    No groups to display.
                </Box>
            </Box>
        )

        const table_params = {
            dataItems: this.props.groups,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerTitle: "Groups I Manage",
            headerActions: this.actions,
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            empty: empty,
        }
    
        return (
            <SimpleTable params={table_params}/>
        );
    }
}

export default ManagingGroupsTable