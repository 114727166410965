import React from 'react';

import {
    // import the components that you use in the file here
    Box,
    Button,
    Input,
    FormField,
    SpaceBetween,
    Modal,
  } from '../../../../aws-ui-components';

import SimpleTable from '../../../../common-components/SimpleTable.jsx';

import { GetOrCreateUser } from "../../../../helpers/authHelpers";

import { getRecursiveMembers } from '../../../../helpers/goalHelpers'

import { displayPhoneToolLink } from "../../../../helpers/utils";


const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'alias', label: 'Alias', editable: false },
        { id: 'manager', label: 'Manager', editable: false },
        { id: 'membership', label: 'Membership', editable: false }
      ]
    }
  ];

const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Groups' },
    { value: 30, label: '30 Groups' },
    { value: 50, label: '50 Groups' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'alias', 'manager', 'membership'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => `${item.employee.firstName} ${item.employee.lastName}`,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'alias',
        header: 'Alias',
        cell: item => displayPhoneToolLink(item.employee.id),
        minWidth: '200px',
        sortingField: 'description'
    },
    {
        id: 'manager',
        header: 'Manager',
        cell: item => displayPhoneToolLink(item.employee.managerId),
        minWidth: '200px',
        sortingField: 'manager'
    },
    {
        id: 'membership',
        header: 'Membership',
        cell: item => item.membership,
        minWidth: '200px',
        sortingField: 'membership'
    },
];

const ITEM_DISABLED = (item) => { return item.membership !== 'direct' };


class ViewGroupMembers extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            group: this.props.group || null,
            // name: this.props.group?.name,
            subGroups: this.props.group?.subGroups?.items || [],
            members: this.props.group?.members?.items || [],
            employee: null,
            member_string: "",
            // // subgroups: this.props.group?.subgroups.items,
            // success: ""
        }
        this.initialState = this.state
    }

    componentDidMount = async() => {
        if (this.state.group) {
            await this.prepareMemberList()
        }
        const employee = await GetOrCreateUser()
        this.setState({employee: employee})
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    prepareMemberList = async() => {
        const members = await getRecursiveMembers(this.props.group)
        //deduplicate the same member coming from a different group
        const filteredMembers = members.map(members => ({
                "employee": members.employee,
                "employeeId": members.employeeId,
                "membership": members.membership
            })
        );
        const filteredMembersSet = new Set(filteredMembers.map(JSON.stringify));
        this.setState({members: Array.from(filteredMembersSet).map(JSON.parse)});
    };

    actions = (selectedDistributions) => {
        return (
            <div></div>
        );
    }


    render(){
        const table_params = {
            dataItems: this.state.members,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerTitle: "Members",
            headerActions: this.actions,
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            itemsDisabledLogic: ITEM_DISABLED,
            loading: this.props.loading,
            empty: "No members in group"
        }

        return (
            <div>
                <SimpleTable params={table_params}/>
                <Modal
                    onDismiss={() => this.setState({modalVisible: false})}
                    visible={this.state.modalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => this.setState({modalVisible: false})} variant="link">Cancel</Button>
                                <Button variant="primary" onClick={this.onSubmit}>Add members</Button>
                            </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Add members to group"
                >
                    <FormField label="Add members by writing down their alias, you can add multiple by separating them by commas (',')">
                        <Input
                            onChange={(event) => this.onChange('member_string', event)}
                            value={this.state.member_string}
                        />
                    </FormField>
                </Modal>
            </div>

        );
    }
}

export default ViewGroupMembers
