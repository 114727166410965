import {AppLayout, BreadcrumbGroup, Grid, Box, Header, SpaceBetween, Container} from "../../aws-ui-components";
import ServiceNavigation from "../../common-components/ServiceNavigation";
import React, {useEffect, useState} from "react";
import {GetOrCreateUser} from "../../helpers/authHelpers";
import { displayPhoneToolLink } from "../../helpers/utils";


export default function Settings() {
    return (
        <AppLayout
            navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
            breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
            content={<Content />} // Main content on the page, defined below
            contentType="default" // Sets default app layout settings for widths
            navigationOpen={false}
            toolsHide={true}
        />
    );
}


const Breadcrumbs = () => (
    <BreadcrumbGroup
        items={[
            {
                text: 'FAST',
                href: '#/'
            },
            {
                text: 'Profile Settings',
                href: '#/profile-settings'
            }
        ]}
    />
);


const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box margin={{ bottom: 'xxxs' }} color="text-label">
            {label}
        </Box>
        <div>{children}</div>
    </div>
);



function Content(props) {
    const [employee, setEmployee] = useState(null)

    useEffect(() => {
        async function fetchData() {
            const employee = await GetOrCreateUser();
            setEmployee(employee)
        }
        if (!employee) {
            fetchData();
        }
    }, );

    if (!employee) {
        return <div>Loading ...</div>
    }

    return (
        <Container header={<Header variant="h2">User Information</Header>}>
            <SpaceBetween size="l">
                <ValueWithLabel label="First Name">{employee.firstName}</ValueWithLabel>
                <ValueWithLabel label="Last Name">{employee.lastName}</ValueWithLabel>
                <ValueWithLabel label="Email">{employee.email}</ValueWithLabel>
                <ValueWithLabel label="Phonetool">{displayPhoneToolLink(employee.email.split("@")[0])}</ValueWithLabel>
                <ValueWithLabel label="Level">{employee.level}</ValueWithLabel>
                <ValueWithLabel label="Role">{employee.role}</ValueWithLabel>
                <ValueWithLabel label="Manager">
                    {employee.managerId}
                </ValueWithLabel>
            </SpaceBetween>
        </Container>
    );
}
