import * as React from "react";
import { Link, withRouter } from 'react-router-dom';


import { API, graphqlOperation } from "aws-amplify";

import { SpaceBetween, Button, Modal, Box, Form, Input, DatePicker} from '../../aws-ui-components';
import SimpleTable from '../../common-components/SimpleTable.jsx';

import { GetOrCreateUser } from '../../helpers/authHelpers'

import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import { LEADERSHIPPRINCIPLES } from '../../helpers/constants'
import { DATA } from './some-test-data'


const CONTENT_SELECTOR_OPTIONS = [
    {
      label: 'Main distribution properties',
      options: [
        { id: 'name', label: 'Name', editable: false },
        { id: 'startDate', label: 'Start date', editable: true },
        { id: 'endDate',label: 'End date', editable: true},
        { id: 'superPowers', label: 'Super powers', editable: true},
        { id: 'growthAreas', label: 'Growth areas', editable: true}
      ]
    }
  ];
  
const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Letter of Intents' },
    { value: 30, label: '30 Letter of Intents' },
    { value: 50, label: '50 Letter of Intents' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'startDate', 'endDate', 'superPowers', 'growthAreas'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => <Link to={{pathname: `/letter-of-intent/${item.id}`}}>{item.name}</Link>,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'startDate',
        header: 'Start date',
        cell: item => new Date(item.startDate).toLocaleDateString('en-UK'),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'startDate'
    },
    {
        id: 'endDate',
        header: 'End date',
        cell: item => new Date(item.endDate).toLocaleDateString('en-UK'),
        minWidth: '100px',
        sortingField: 'endDate'
    },
    {
        id: 'superPowers',
        header: 'Super powers',
        cell: item => item.superPowers?.map(item => (<p key={item}>{LEADERSHIPPRINCIPLES[item]}</p>)),
        minWidth: '100px',
        sortingField: 'superPowers'
    },
    {
        id: 'growthAreas',
        header: 'Growth areas',
        cell: item => item.growthAreas?.map(item => (<p key={item}>{LEADERSHIPPRINCIPLES[item]}</p>)),
        minWidth: '100px',
        sortingField: 'growthAreas'
    }
];

class SimpleLoITable extends React.Component {
    state = {
        name: "",
        startDate: "",
        endDate: "",
        error: "",
        modalVisible: false,
        employee: null,
        lettersOfIntent: []
    };

    initial_state = this.state 

    componentDidMount = () => {
        this.updateState()
    }

    updateState = async() => {
        this.setState(this.initial_state)
        const employee = await GetOrCreateUser()
        const lettersOfIntent = await API.graphql(graphqlOperation(
            queries.listLetterOfIntents, {
                filter: {
                    employeeId: {eq: employee.id}
                }
            }
        ))
        this.setState({employee: employee, lettersOfIntent: lettersOfIntent.data.listLetterOfIntents.items})
    }

    validateFields = () => {
        const { name, startDate, endDate } = this.state
        if ([name, startDate, endDate].includes("")){
            this.setState({error: "Please fill in all values"})
            return false
        }
        else {
            this.setState({error: ""})
            return true
        }
    }
    onSubmit = async () => {
        const valid = this.validateFields()
        if (!valid) { return }
        const { name, startDate, endDate } = this.state
        const createLetterOfIntent = await API.graphql(graphqlOperation(
            mutations.createLetterOfIntent, {
                input: {
                    name: name,
                    startDate: startDate,
                    endDate: endDate,
                    employeeId: this.state.employee.id,
                    draftMode: true,
                }
            }
        ));
        if (createLetterOfIntent.data.createLetterOfIntent){
            this.setState({modalVisible: false})
            this.props.history.push(`/letter-of-intent/${createLetterOfIntent.data.createLetterOfIntent.id}`)
        }
    }

    onChange = (field, event) => {
        this.setState({[field]: event.detail.value})
    }

    deleteLetterOfIntent = async (loi_id_to_delete) => {
        const confirmed = confirm("Are you sure you want to delete this LoI?")
        if (!confirmed) { return }
        const loi_goals = await API.graphql(graphqlOperation(
            queries.loiGoalByLoiId, { letterOfIntentId: loi_id_to_delete}
        ))
        const loi_goals_to_delete = loi_goals.data.loiGoalByLoiId.items
        for (const goalToDelete of loi_goals_to_delete){
            await API.graphql(graphqlOperation(
                mutations.deleteLetterOfIntentGoal, {
                    input: {
                        id: goalToDelete.id,
                    }
                }
            ));
        }
        await API.graphql(graphqlOperation(
            mutations.deleteLetterOfIntent, {
                input: {
                    id: loi_id_to_delete,
                }
            }
        ));
        this.updateState()
    }

    actions = (selectedDistributions) => {
        const isOnlyOneSelected = selectedDistributions.length === 1;
        return (
            <SpaceBetween direction="horizontal" size="s">
                <Link to={{pathname: `/letter-of-intent/${selectedDistributions[0]?.id}`}} >
                    <Button disabled={!isOnlyOneSelected}> Update</Button>
                </Link>
                <Button 
                    id="delete-loi-id"
                    disabled={selectedDistributions.length !== 1 }
                    onClick={() => this.deleteLetterOfIntent(selectedDistributions[0]?.id)}
                > 
                    Delete
                </Button>
                <Button id="create-new-loi" onClick={() => this.setState({modalVisible: true})} variant="primary" >
                    Create new Letter of Intent
                </Button>
                <Modal
                    onDismiss={() => this.setState({modalVisible: false})}
                    visible={this.state.modalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => this.setState({modalVisible: false})} variant="link">Cancel</Button>
                                <Button id="submit-loi" variant="primary" onClick={this.onSubmit}>Submit</Button>
                            </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Create a new Letter of Intent"
                >
                    <Form>
                        <div style={{marginBottom: '20px'}}>
                            Name
                            <Input
                                id="loi-name" 
                                onChange={(event) => this.onChange('name', event)} 
                                value={this.state.name} 
                            />
                        </div>
                        <div style={{marginBottom: '20px'}}>
                            Start date
                            <DatePicker
                                id="start-date-loi"
                                onChange={(event) => this.onChange('startDate', event)}
                                value={this.state.startDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                            />
                        </div>
                        <div style={{marginBottom: '20px'}}>
                            End date
                            <DatePicker
                                id="end-date-loi"
                                onChange={(event) => this.onChange('endDate', event)}
                                value={this.state.endDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                            />
                        </div>
                    </Form>
                </Modal>
            </SpaceBetween>
        );
    }

    render() {
        const table_params = {
            dataItems: this.state.lettersOfIntent,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerActions: this.actions,
            headerTitle: "My Letters of Intent",
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            selection: "single",
            empty: "No letters of intent created yet"
        }
    
        return (
            <SimpleTable params={table_params}/>
        );
    }
}

export default withRouter(SimpleLoITable)