/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

 https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import {
  // import the components that you use in the file here
  AppLayout,
  BreadcrumbGroup,
  SpaceBetween,
} from '../../aws-ui-components';

import ServiceNavigation from '../../common-components/ServiceNavigation';

import DirectReportsTable from './DirectReportsTable'

class ManagerOverview extends React.Component {
    render() {
        const Breadcrumbs = () => (
            <BreadcrumbGroup
              items={[
                {
                  text: 'FAST',
                  href: '#/'
                },
                {
                  text: 'Manager overview',
                  href: '#/manager-overview'
                }
              ]}
            />
          );
          
        // Main content area (fill it in with components!)
        const Content = () => (
            <SpaceBetween direction="vertical" size="xl">
                <DirectReportsTable/>
            </SpaceBetween>
        )

        return (
            <AppLayout
              navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
              breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
              content={<Content />} // Main content on the page, defined below
              contentType="default" // Sets default app layout settings for widths
              navigationHide={false}
              toolsHide={true}
            />
        );
    }
}

// Breadcrumb content


export default ManagerOverview
